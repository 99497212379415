import { IconButton, TableCell } from "@mui/material"
import { ProgramForm } from "../../types"
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const CollapseButtonCell = (props: {
  form: ProgramForm
  isCollapsedCell: boolean,
  tableCellStyles: any,
  colIndex: number,
  colWidths: number[],
  setOpen: (open: boolean) => void,
  open: boolean 
}) => {

  const {
    isCollapsedCell,
    tableCellStyles,
    colIndex,
    colWidths,
    form,
    setOpen,
    open
  } = props

  const sx = isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads ) ? {...tableCellStyles, width: colWidths[colIndex]} : tableCellStyles
  
  return (
    <TableCell
      sx={sx}
      align="center"
    >
      {!isCollapsedCell && !form.is_practice_only ? 
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </IconButton>
      : null}
    </TableCell>
  )
}

export default CollapseButtonCell