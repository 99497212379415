import { useEffect, useState } from 'react';
import { match, P } from 'ts-pattern';
import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TextField,
  Stack,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  FormHelperText
} from '@mui/material';
import { isFulfilled } from '@reduxjs/toolkit';
import { Form, Field } from 'react-final-form';

import { SRAElectronicMediaInventory } from '../types';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { createInventoryItem, selectUsers, selectUsersMap, updateInventoryItem } from '../slice';
import { composeValidators, maxLength, required } from '../../../../../helpers/formValidation';

import UserMultiSelect from './UserMultiSelect';
import { User } from '../../../../../redux/user';
import CloseModalButton from '../../../../../components/CloseModalButton';
import theme from '../../../../../themes/theme';

interface EMIFormState {
  location?: string;
  hasPhi?: string;
  deviceType?: string;
  serialNumber?: string;
}

const MAX_LOCATION_LENGTH = 100;
const MAX_SERIAL_NUMBER_LENGTH = 60;
const MAX_DEVICE_TYPE_LENGTH = 150;

const EMIToFormState = (item?: SRAElectronicMediaInventory): EMIFormState => ({
  location: item?.location ?? '',
  // We can't have 'true' or 'false' here for new items, as that'd set the default yes/no
  // which is against the requirement of no default 'has phi'.
  hasPhi: match(item?.has_phi)
    .with(P.select(P.boolean), (hasPhi) => hasPhi + '')
    .otherwise(() => undefined),
  deviceType: item?.manufacturer_or_device_type ?? '',
  serialNumber: item?.serial_number ?? ''
});

const AddEditEMIModal = ({ item, onClose, isOpen }: { item?: SRAElectronicMediaInventory; onClose: () => void; isOpen: boolean }) => {
  const dispatch = useAppDispatch();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const usersMap = useAppSelector(selectUsersMap);
  const users = useAppSelector(selectUsers);

  useEffect(() => {
    setSelectedUserIds(item?.users.map((u) => u.user_id + '') ?? []);
  }, [item]);

  const resetForm = () => {
    setSelectedUserIds([]);
  };

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const onSubmit = async (values: EMIFormState) => {
    const inventoryDTO: Partial<SRAElectronicMediaInventory> = {
      location: values.location,
      manufacturer_or_device_type: values.deviceType,
      serial_number: values.serialNumber,
      has_phi: values.hasPhi === 'true',
      users: selectedUserIds.map((id) => usersMap[id])
    };

    if (item?.id) {
      inventoryDTO.id = item.id;
    }

    const action = item?.id ? updateInventoryItem : createInventoryItem;

    const res = await dispatch(action(inventoryDTO));

    if (isFulfilled(res)) {
      handleClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <CloseModalButton handleClose={handleClose} />
      <DialogTitle style={{ color: theme.palette.color50555b.main }}>Electronic Media</DialogTitle>
      <Form onSubmit={onSubmit} initialValues={EMIToFormState(item)}>
        {({ handleSubmit, submitting, invalid }) => (
          <>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Stack spacing={'1em'} sx={{ marginTop: 1 }}>
                  <Field
                    name={'location' satisfies keyof EMIFormState}
                    validate={composeValidators('Location', required, maxLength(MAX_LOCATION_LENGTH))}
                  >
                    {({ input, meta }) => (
                      <TextField {...input} label="Location" required error={meta.error && meta.touched} helperText={meta.touched && meta.error} />
                    )}
                  </Field>
                  <Field
                    name={'serialNumber' satisfies keyof EMIFormState}
                    validate={composeValidators('Serial Number', required, maxLength(MAX_SERIAL_NUMBER_LENGTH))}
                  >
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        label="Serial Number"
                        required
                        error={meta.error && meta.touched}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                  <Field
                    name={'deviceType' satisfies keyof EMIFormState}
                    validate={composeValidators('Manufacturer/Type of Device', required, maxLength(MAX_DEVICE_TYPE_LENGTH))}
                  >
                    {({ input, meta }) => (
                      <TextField
                        {...input}
                        label="Manufacturer/Type of Device"
                        required
                        error={meta.error && meta.touched}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  </Field>
                  <Field name={'hasPhi' satisfies keyof EMIFormState} validate={required('Stores Electronic ePHI')}>
                    {({ input, meta }) => (
                      <FormControl error={meta.error && meta.touched}>
                        <FormLabel id="stores-phi-label">Stores Electronic ePHI *</FormLabel>
                        <RadioGroup {...input} aria-labelledby="stores-phi-label">
                          <FormControlLabel value="true" control={<Radio />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                        <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                  <UserMultiSelect label={'Assigned Team Member(s)'} users={users} value={selectedUserIds} onChange={setSelectedUserIds} />
                </Stack>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" disabled={submitting || invalid} onClick={handleSubmit}>
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default AddEditEMIModal;
