import { useCallback, useEffect, useMemo, useState } from 'react';
import { match, P } from 'ts-pattern';
import * as R from 'remeda';
import { Button } from '@mui/material';
import { pdf } from '@react-pdf/renderer';

import { SRAElectronicMediaInventory } from './types';
import { getFullNameOrEmailOrDefault } from '../utils';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { deleteInventoryItem, fetchInventory, selectInventoryMapWithUsers } from './slice';
import { isFulfilled } from '@reduxjs/toolkit';

import styles from './ElectronicMediaInventory.module.css';
import { mapSuccess, unwrapOr } from '../../../../helpers/RemoteData';
import AddEditEMIModal from './components/AddEditEMIModal';
import DataTable, { DataColumn } from '../../../../components/DataTable';
import GenericDeleteModal from './components/GenericDeleteModal';
import theme from '../../../../themes/theme';
import { useParams } from 'react-router-dom';
import { downloadFile } from '../../../../helpers/downloadFile';
import { selectPracticeInfo } from '../slice';
import ElectronicMediaInventoryPDF from '../components/ElectronicMediaInventoryPDF';

type EMIColumnKeys = 'location' | 'serialNumber' | 'deviceType' | 'hasPhi' | 'users' | 'id';

const EMITableColumns: DataColumn<EMIColumnKeys>[] = [
  {
    key: 'location',
    isSortable: true,
    label: 'Location of Device'
  },
  {
    key: 'serialNumber',
    isSortable: true,
    label: 'Serial Number of Device'
  },
  {
    key: 'deviceType',
    isSortable: true,
    label: 'Manufacturer/Type of Device'
  },
  {
    key: 'hasPhi',
    isSortable: true,
    label: 'Stores ePHI?'
  },
  {
    key: 'users',
    isSortable: false,
    label: 'Assigned User(s)'
  }
];

const EMITable = () => {
  const dispatch = useAppDispatch();
  const { componentTypeName, year } = useParams();
  const inventoryState = useAppSelector(selectInventoryMapWithUsers);
  const practiceInfo = useAppSelector(selectPracticeInfo);

  useEffect(() => {
    if (inventoryState.type == 'not-asked') {
      dispatch(fetchInventory());
    }
  }, [inventoryState.type]);

  const addInventoryDisabled = useMemo(
    () =>
      match(inventoryState)
        .with({ type: P.union('not-asked', 'loading') }, () => true)
        .with({ type: 'failure' }, () => true)
        .otherwise(() => false),
    [inventoryState]
  );

  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const [idToDelete, setIdToDelete] = useState<string>();
  const [itemBeingEdited, setItemBeingEdited] = useState<SRAElectronicMediaInventory>();

  const [exportButtonState, setExportButtonState] = useState<'loading' | 'done' | 'not-started'>('not-started');

  const onEditModalClose = useCallback(() => {
    setEditModalIsOpen(false);
    setItemBeingEdited(undefined);
  }, [editModalIsOpen]);

  const openEditModal = (id?: string) => {
    setEditModalIsOpen(true);

    const realItem: typeof itemBeingEdited = id
      ? R.pipe(
          inventoryState,
          mapSuccess((invMap) => invMap[id]),
          unwrapOr(undefined as typeof itemBeingEdited)
        )
      : undefined;

    setItemBeingEdited(realItem);
  };

  const openDeleteModal = (id: string) => {
    setDeleteModalIsOpen(true);
    setIdToDelete(id);
  };

  const onDeleteModalClose = () => {
    setDeleteModalIsOpen(false);
    setIdToDelete(undefined);
  };

  const onDelete = async () => {
    if (!idToDelete) {
      onDeleteModalClose();
      return;
    }
    const res = await dispatch(deleteInventoryItem(idToDelete));

    if (isFulfilled(res)) {
      onDeleteModalClose();
    }
  };

  const tableItems: Record<EMIColumnKeys, string>[] = useMemo(
    () =>
      R.pipe(
        inventoryState,
        mapSuccess(R.values),
        mapSuccess(
          R.map((item) => ({
            id: item.id + '',
            location: item.location,
            serialNumber: item.serial_number,
            deviceType: item.manufacturer_or_device_type,
            hasPhi: item.has_phi ? 'Yes' : 'No',
            users: item.users.map((user) => getFullNameOrEmailOrDefault(user)).join(', ')
          }))
        ),
        unwrapOr([] as Record<EMIColumnKeys, string>[])
      ),
    [inventoryState]
  );

  const downloadPDF = useCallback(async () => {
    const title = `${componentTypeName} Risk Assessment Report`;

    setExportButtonState('loading');

    const renderedPdf = await pdf(
      <ElectronicMediaInventoryPDF
        electronicMediaInventory={tableItems}
        practiceInfo={practiceInfo}
        title={title}
        year={year}
      />
    ).toBlob();

    const url = URL.createObjectURL(renderedPdf);

    await downloadFile({
      url,
      isBlobURL: true,
      fileName: `${year} ${practiceInfo?.name} ${title}.pdf`
    });

    setExportButtonState('done');
  }, [tableItems, practiceInfo, year]);

  return (
    <>
      <div className={styles['table-toolbar']}>
        <span className={styles['empty-table-message']}>
          {match(inventoryState)
            .with({ type: 'success', data: P.when(R.isEmpty) }, () => (
              <i>Electronic media has not been added. Please use the "Add Inventory" button to add your electronic media.</i>
            ))
            .with({ type: 'failure' }, () => <i>An error has occured. Please refresh the page.</i>)
            .otherwise(() => null)}
        </span>
        <Button variant="contained" disabled={addInventoryDisabled} onClick={() => openEditModal()}>
          Add Inventory
        </Button>
        <Button variant="contained" className="m-l-16" onClick={() => downloadPDF()} disabled={exportButtonState == 'loading'}>
            {exportButtonState === 'loading' ? 'Exporting...' : 'Export'}
        </Button>
      </div>
      <DataTable
        columns={EMITableColumns}
        items={tableItems}
        isLoading={inventoryState.type === 'loading' || inventoryState.type === 'not-asked'}
        onEdit={(id) => openEditModal(id)}
        onDelete={(id) => openDeleteModal(id)}
      />
      <AddEditEMIModal isOpen={editModalIsOpen} onClose={onEditModalClose} item={itemBeingEdited} />
      <GenericDeleteModal onClose={onDeleteModalClose} onDelete={onDelete} open={deleteModalIsOpen} />
    </>
  );
};

const ElectronicMediaInventory = () => (
  <div style={{ color: theme.palette.color50555b.main }}>
    <h2>Electronic Media Inventory</h2>
    <p>
      This report requires that you list by{' '}
      <b>
        <u>Location, Serial Number, and Manufacturer</u>
      </b>{' '}
      all electronic device that “accesses or stores” electronic Protected Health Information (ePHI). (i.e.: computers, laptops, tablets, copiers, fax
      machine, etc)
    </p>
    <ol>
      <li>
        Enter the <b>“Location”</b> for each device that “accesses or stores” ePHI within your practice into column “A”. This can be described as:
        “Reception Desk PC #1” or any other name that identifies where the specific device is located.
      </li>
      <li>
        Enter the <b>“Serial Number”</b> for the associated device in column “B”. This can be provided by finding it on each device or your IT Support
        may have a list of this on file.
      </li>
      <li>
        Enter the <b>“Manufacturer”</b> of the device along with the type in column “C”. An example would be “Dell desktop PC” or “Lenovo laptop”.
      </li>
      <li>
        If the device maintains ePHI Select “Yes” in column “D”, <b>(“Does this Device store ePHI?”)</b>. If it only accesses health information but
        does not store it, then select “No”.
      </li>
      <li>
        Enter the <b>employee(s)</b> that use the listed device in column “E” (“Assigned User(s)”). This does not have to be a specific person, if the
        device is used by anyone at the front desk you can enter “Reception Team”. Or if it is a device used by everyone in the practice you can use
        “All Employees”. <i>issues</i>)
      </li>
    </ol>
    <EMITable />
  </div>
);

export default ElectronicMediaInventory;
