import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import { selectCurrentYear, selectCurrentYearDirections } from './slice';

const VIEWED_DIRECTIONS_KEY = 'ontraq/practice-de/viewed-directions';

const ProgramDirectionsModal = ({ disabled = false }: { disabled?: boolean }) => {
  const [hasViewedDirections, setHasViewedDirections] = useState(true);
  const [open, setOpen] = useState(false);
  const [directionsIndex, setDirectionsIndex] = useState(0);

  const currentYear = useSelector(selectCurrentYear);
  const currentYearDirections = useSelector(selectCurrentYearDirections);

  const hasDirections = currentYearDirections.length > 0;

  useEffect(() => {
    // TODO: In the future, the viewed state will be determined by the compliance value, and the compliance
    // value will be updated upon viewing the directions.
    if (localStorage) {
      const storedViewedDirections = JSON.parse(localStorage.getItem(VIEWED_DIRECTIONS_KEY) ?? 'false');

      if (typeof storedViewedDirections === 'boolean') {
        setHasViewedDirections(storedViewedDirections);
      }
    }
  }, []);

  useEffect(() => {
    if (!hasViewedDirections && hasDirections) {
      setOpen(true);
    } else {
      // Mainly needed so that we can close the modal with changing the year to a year directions don't exist
      // Same with 'currentYear' in dependency array
      setOpen(false);
    }
  }, [hasViewedDirections, hasDirections, currentYear]);

  const setDirectionsAsViewed = () => {
    if (localStorage) {
      localStorage.setItem(VIEWED_DIRECTIONS_KEY, 'true');
    }
    setHasViewedDirections(true);
  };

  const onClose = () => {
    setDirectionsAsViewed();
    setOpen(false);
    setDirectionsIndex(0);
  };

  const currentDirections = currentYearDirections[directionsIndex];

  return (
    <>
      <Button onClick={() => setOpen(true)} disabled={!hasDirections || disabled}>
        Directions
      </Button>
      <Dialog open={open} maxWidth="xl" fullWidth onClose={onClose}>
        {currentDirections && (
          <>
            <DialogTitle>Program Directions | Year {currentYear}</DialogTitle>
            <DialogContent>
              <Tabs value={directionsIndex}>
                {currentYearDirections.map((d, index) => (
                  <Tab onClick={() => setDirectionsIndex(index)} key={d.program_id} value={index} label={d.name} />
                ))}
              </Tabs>
              <iframe style={{ width: '100%', minHeight: '500px' }} src={currentDirections.directions_url} />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ProgramDirectionsModal;
