import { TableCell } from "@mui/material"
import UploadButton from "./UploadButton"
import { ProgramForm, TrainingProgramForm } from "../../types"
import { useMemo } from "react"

const UploadButtonCell = (props: {
  trainingProgramForm: TrainingProgramForm,
  isCollapsedCell: boolean,
  tableCellStyles: any,
  colIndex: number,
  colWidths: number[],
  form: ProgramForm,
  setFileUploadModalIsOpen: (open: boolean) => void,
  setSelectedForm: (id: number) => void,
  setSelectedTrainingForm: (id: number) => void,
  sectionId: number;
}) => {

  const {
    isCollapsedCell,
    tableCellStyles,
    colIndex,
    colWidths,
    form,
    trainingProgramForm,
    sectionId
  } = props

  const sx = isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads ) ? {...tableCellStyles, width: colWidths[colIndex]} : tableCellStyles
  const uploaded = useMemo(() => {
   return  trainingProgramForm?.section_id === sectionId && trainingProgramForm?.file_id ? true : false
  }, [trainingProgramForm])

  return (
    <TableCell
      sx={sx}
      align='center'
    >
      {isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads) ?
        <UploadButton
          {...props}
          trainingForm={trainingProgramForm}
          uploaded={uploaded}
        />
      : null}
    </TableCell>
  )
}

export default UploadButtonCell