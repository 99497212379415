
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { PracticeInfo } from "../types";
import { Header, Footer } from "./PDFcomponents";

import openSans from '../../../../fonts/OpenSans-Regular.ttf';
import openSansBold from '../../../../fonts/OpenSans-Bold.ttf';
import openSansItalic from '../../../../fonts/OpenSans-Italic.ttf';

Font.register({ family: 'Open Sans', fonts: [{ src: openSans }, { src: openSansBold, fontWeight: 'bold' }, { src: openSansItalic, fontStyle: 'italic' }] });

const COMPLIANCE_BLUE = '#0084b7';
const COMPLIANCE_CRANBERRY = '#9f000f';
const COLORED_ROW = '#e9ecef';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Open Sans',
    fontSize: '10pt'
  },
  checklistSectionHeading: {
    backgroundColor: COMPLIANCE_BLUE,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '20pt',
    padding: '5pt'
  },
  content: {
    margin: '0 0.25in',
    flex: 1
  },
  errorMessage: {
    color: COMPLIANCE_CRANBERRY,
    fontStyle: 'italic'
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  tableCol: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: 5
  },
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '12pt'
  },
  statusCol: {
    flex: 1
  },
});

const handleRowColor = (index: number): object => {
  if (index % 2 === 1) {
    return {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: COLORED_ROW
    }
  }
  return {
    display: 'flex',
    flexDirection: 'row'
  }
}

type EPHIColumnKeys = 'id' | 'teamMemberName' | 'hasPhi';

interface EphiTeamMemberPDFProps {
  ephiTeamMembers: Record<EPHIColumnKeys, string>[],
  practiceInfo?: PracticeInfo;
  title: string;
  year?: string;
}

const EphiTeamMembersPDF = ({ ephiTeamMembers, practiceInfo, title, year }: EphiTeamMemberPDFProps) => {
  if (!title || !year || !practiceInfo) {
    return;
  }
  return (
    <Document>
      <Page size="A4" orientation="landscape" wrap style={styles.page}>
        <Header practiceInfo={practiceInfo} title={title} year={year}></Header>
        <View style={styles.content}>
          <View style={styles.checklistSectionHeading}>
            <Text>Team Members Who Use ePHI</Text>
          </View>
          {ephiTeamMembers.length > 0 ? (
            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={[styles.tableCol, styles.tableHeader, styles.statusCol]}>
                  <Text style={styles.tableCell}>Team Member Name</Text>
                </View>
                <View style={[styles.tableCol, styles.tableHeader]}>
                  <Text style={styles.tableCell}>Handles ePHI</Text>
                </View>
              </View>
              {ephiTeamMembers.sort((a, b) => a.teamMemberName.toUpperCase() < b.teamMemberName.toUpperCase() ? -1 : a.teamMemberName.toUpperCase() > b.teamMemberName.toUpperCase() ? 1 : 0).map(({ id, teamMemberName, hasPhi }, i) => {
                return (
                  <View key={id} style={handleRowColor(i)}>
                    <View style={[styles.tableCol, styles.statusCol]}>
                      <View style={styles.tableCell}>
                        <Text>{teamMemberName}</Text>
                      </View>
                    </View>
                    <View style={[styles.tableCol, styles.statusCol]}>
                      <View style={styles.tableCell}>
                        <Text>{hasPhi}</Text>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          ) :
            (
              <Text style={styles.errorMessage}>No data is available for this section</Text>
            )}
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

export default EphiTeamMembersPDF;