import { Info } from "@mui/icons-material"
import { IconButton, styled, SvgIconTypeMap, Tooltip, tooltipClasses, TooltipProps } from "@mui/material"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import shadows from "@mui/material/styles/shadows"

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Icons: {
  [Identifier: string]: OverridableComponent<SvgIconTypeMap<{}, "svg">>
} = {
  "info": Info}
export const ToolTip = (props: { helpText: string, icon: string }) => {
  const { helpText, icon } = props;
  const Icon = Icons[icon];
  return (
    <LightTooltip
      title={helpText}
      placement="bottom-start"
      arrow
    >
      <IconButton sx={{ padding: "0px" }}>
        <Icon sx={{ width: "15px" }}/>
      </IconButton>
    </LightTooltip>
  )
}