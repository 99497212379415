import { Page, Text, View, Document, StyleSheet, Font, Svg, Circle } from '@react-pdf/renderer';
import { format } from 'date-fns';
import { match } from 'ts-pattern';
import * as R from 'remeda';

import {
  ChecklistCertification,
  PracticeInfo,
  TrainingChecklist,
  TrainingChecklistSection,
  TrainingChecklistTasksMetadata,
  TrainingChecklistType
} from '../types';
import { getChecklistTypeDisplayName, getComplianceStatusText, getFullNameOrEmailOrDefault } from '../utils';

import openSans from '../../../../fonts/OpenSans-Regular.ttf';
import openSansBold from '../../../../fonts/OpenSans-Bold.ttf';
import { ComplianceStatus } from '../../../../types';
import LogoMarkPDFSVG from './LogoMarkPDFSVG';
import LogoPDFSVG from './LogoPDFSVG';
import { Footer } from './PDFcomponents';

Font.register({ family: 'Open Sans', fonts: [{ src: openSans }, { src: openSansBold, fontWeight: 'bold' }] });

const COMPLIANCE_BLUE = '#0084b7';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Open Sans',
    fontSize: '10pt'
  },
  heading: {
    fontSize: '14pt',
    fontWeight: 'bold'
  },
  section: {
    margin: 10
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0.25in'
  },
  flexItem: {
    flex: 1
  },
  practiceInfo: {
    fontWeight: 'bold'
  },
  pageHeading: {
    fontWeight: 'bold',
    fontSize: '20pt'
  },
  centeredMiddle: {
    flex: 2,
    textAlign: 'center'
  },
  pageNumber: {
    fontWeight: 'bold'
  },
  healthFirstContact: {
    fontWeight: 'bold',
    fontSize: '14pt',
    color: COMPLIANCE_BLUE
  },
  checklistSectionHeading: {
    backgroundColor: COMPLIANCE_BLUE,
    color: 'white',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '20pt',
    padding: '5pt'
  },
  footer: {},
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  tableCol: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: 5
  },
  tableHeader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: '14pt'
  },
  statusCol: {
    flex: 0.5
  },
  content: {
    margin: '0 0.25in',
    flex: 1
  }
});

const HEALTHFIRST_PHONE_NUMBER = '941-587-2864';

const stringToParagraphs = (input?: string) => {
  if (!input) {
    return null;
  }
  const sanitizedInput = input.trim();

  if (sanitizedInput.length == 0) {
    return null;
  }

  return (
    <Text>
      {sanitizedInput}
      {/* FIXME: There's a ongoing known issue with yoga, the layout engine react-pdf uses, where the size of text on the page is not being propertly calculated. To prevent most cases of text overflowing outside of their table cells, these line breaks are needed. Whenever/if react-pdf changes layout engine this shouldn't be needed anymore */}
      {'\n\n\n\n\n'}
    </Text>
  );
};

const DATE_FORMAT = 'MM/dd/yyyy';

const getCertificationMessage = (certification: ChecklistCertification, componentTypeName: string, checklistType: TrainingChecklistType) =>
  `This ${componentTypeName} ${getChecklistTypeDisplayName(checklistType)} has been certified complete by ${getFullNameOrEmailOrDefault(
    certification.user
  )} on ${format(new Date(certification.certified_at), DATE_FORMAT)}`;

interface HeaderProps {
  componentTypeName: string;
  year: string;
  practiceInfo: PracticeInfo;
  checklistType: TrainingChecklistType;
}

const Header = ({ practiceInfo, year, componentTypeName, checklistType }: HeaderProps) => (
  <View fixed style={styles.flexRow}>
    <View style={[styles.flexItem, styles.practiceInfo]}>
      <Text>{practiceInfo.name?.trim()}</Text>
      <Text>{practiceInfo.doctor_name?.trim()}</Text>
      <Text>{practiceInfo.street?.trim()}</Text>
      <Text>{practiceInfo.street2?.trim()}</Text>
      <Text>
        {practiceInfo.city?.trim()}, {practiceInfo.state?.trim()} {practiceInfo.zip?.trim()}
      </Text>
    </View>
    <View style={styles.centeredMiddle}>
      <Text style={styles.pageHeading}>
        {year} {componentTypeName} {getChecklistTypeDisplayName(checklistType)}
      </Text>
      <Text>Report generated: {format(new Date(), DATE_FORMAT)}</Text>
    </View>
    <View style={styles.flexItem}>
      <LogoPDFSVG />
    </View>
  </View>
);

interface ChecklistSectionProps {
  section: TrainingChecklistSection;
  taskMetaMap: Record<number, TrainingChecklistTasksMetadata>;
  certification?: ChecklistCertification;
  componentTypeName: string;
  checklistType: TrainingChecklistType;
  practiceInfo: PracticeInfo;
  year: string;
}

const ChecklistSection = ({ section, taskMetaMap, certification, componentTypeName, checklistType, practiceInfo, year }: ChecklistSectionProps) => (
  <Page size="A4" orientation="landscape" wrap style={styles.page}>
    <Header practiceInfo={practiceInfo} checklistType={checklistType} year={year} componentTypeName={componentTypeName} />
    <View style={styles.content}>
      {/* Facility report shows its certification on a different page */}
      {certification && checklistType !== 'facility report' && (
        <View style={{ marginBottom: 10 }}>
          <Text>{getCertificationMessage(certification, componentTypeName, checklistType)}</Text>
        </View>
      )}
      <View style={styles.checklistSectionHeading}>
        <Text>{section.name}</Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={[styles.tableCol, styles.tableHeader, styles.statusCol]}>
            <Text style={styles.tableCell}>status</Text>
          </View>
          <View style={[styles.tableCol, styles.tableHeader]}>
            <Text style={styles.tableCell}>question{checklistType === 'facility report' && '/details'}</Text>
          </View>
          <View style={[styles.tableCol, styles.tableHeader]}>
            <Text style={styles.tableCell}>references</Text>
          </View>
          <View style={[styles.tableCol, styles.tableHeader]}>
            <Text style={styles.tableCell}>products</Text>
          </View>
        </View>
        {R.sortBy(section.tasks, (t) => t.list_index).map((task) => {
          const taskMeta = taskMetaMap[task.id];
          const status = taskMeta.compliance_status ?? ComplianceStatus.NotStarted;
          return (
            <View key={task.id} style={styles.tableRow}>
              <View style={[styles.tableCol, styles.statusCol]}>
                <View style={styles.tableCell}>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Svg height="14pt" width="14pt">
                      <Circle
                        r="5pt"
                        cx="7pt"
                        cy="7pt"
                        stroke="black"
                        fill={match(status)
                          .with(ComplianceStatus.Complete, () => 'green')
                          .with(ComplianceStatus.InProgress, () => 'yellow')
                          .with(ComplianceStatus.NotStarted, () => 'red')
                          .otherwise(() => 'grey')}
                      />
                    </Svg>
                    <Text> {getComplianceStatusText(status)}</Text>
                  </View>
                  {status === ComplianceStatus.Complete && (
                    <>
                      <Text> </Text>
                      <Text>
                        Completed by: {getFullNameOrEmailOrDefault(taskMeta.user)} {format(new Date(taskMeta.completed_at ?? ''), DATE_FORMAT)}
                      </Text>
                    </>
                  )}
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  <Text style={{ fontWeight: 'bold' }}>
                    {task.name}
                    {'\n\n'}
                  </Text>
                  {stringToParagraphs(task.description)}
                </View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>{stringToParagraphs(task.references)}</View>
              </View>
              <View style={styles.tableCol}>
                <View style={styles.tableCell}>
                  {task.products.map((product) => (
                    <Text key={product.id}>{product.name}</Text>
                  ))}
                </View>
              </View>
            </View>
          );
        })}
      </View>
    </View>
    <Footer />
  </Page>
);

const FacilityReportCoverPage = ({
  certification,
  year,
  componentTypeName,
  practiceInfo
}: { certification?: ChecklistCertification } & Omit<HeaderProps, 'checklistType'>) => {
  return (
    <Page size="A4" orientation="landscape" wrap style={styles.page}>
      <Header practiceInfo={practiceInfo} checklistType={'facility report'} year={year} componentTypeName={componentTypeName} />
      <View style={styles.content}>
        {/* I don't know why this was in the mockup */}
        <View style={styles.checklistSectionHeading}>
          <Text>&nbsp;</Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>About this report: </Text>
          <Text>
            This is your required OSHA Safety Facility Inspection Report. Included in the report is a checklist that provides potential solutions to
            help rectify the requirements that were presented in the years Annual OSHA Employee Training Video / Webinar Session from HealthFirst
            Compliance Solutions. This report contains the most current OSHA, Infection Control, COVID-19 Updates, Chemical Safety Standards (GHS) &
            HIPAA Safety & Security Protocols. All these listed items are protocols that you are required to have set-up and functioning within your
            dental office.
          </Text>
        </View>
        <View style={styles.section}>
          <Text style={styles.heading}>How to use this report: </Text>
          <Text>
            The OSHA Safety Facility Report is designed to provide you with easy-to-follow directions, guidance, and clarity, on current OSHA, COVID &
            HIPAA requirements. It is important that you read and implement each checklist item to avoid potential fines for non-compliance from OSHA,
            Health Department & HIPAA Officers. This report will offer you all the correct directives to turn any OSHA / HIPAA liability into an
            asset. We suggest that you carve out 2-10 hours to get these protocols implemented and recommend that you delegate specific sections of
            this report to team members. Make sure to thoroughly review the directives to assure that your office is functioning to current compliance
            standards.
          </Text>
        </View>
        {certification && (
          <View style={styles.section}>
            <Text style={styles.heading}>Certification: </Text>
            <Text>{getCertificationMessage(certification, componentTypeName, 'facility report')}</Text>
          </View>
        )}
      </View>
      <Footer />
    </Page>
  );
};

interface ChecklistPDFProps {
  checklist?: TrainingChecklist;
  componentTypeName?: string;
  year?: string;
  practiceInfo?: PracticeInfo;
  taskMetaMap: Record<number, TrainingChecklistTasksMetadata>;
  certification?: ChecklistCertification;
}

const ChecklistPDF = ({ checklist, componentTypeName, year, practiceInfo, taskMetaMap, certification }: ChecklistPDFProps) => {
  if (!checklist || !year || !practiceInfo || !componentTypeName) {
    return;
  }
  return (
    <Document>
      {checklist.type === 'facility report' && (
        <FacilityReportCoverPage certification={certification} practiceInfo={practiceInfo} year={year} componentTypeName={componentTypeName} />
      )}
      {checklist.sections.map((section, index) => (
        <ChecklistSection
          key={section.id}
          section={section}
          certification={index === 0 ? certification : undefined}
          componentTypeName={componentTypeName}
          year={year}
          practiceInfo={practiceInfo}
          taskMetaMap={taskMetaMap}
          checklistType={checklist.type}
        />
      ))}
    </Document>
  );
};

export default ChecklistPDF;
