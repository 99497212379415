import React, { useMemo, useState } from "react";
import { useAppSelector } from "../../../../../redux/store";
import { TeamMember } from "../../../../../types";
import { ProgramForm, ProgramFormsFilters, SectionFormRelation, TrainingProgramForm } from "../../types";
import { getFullNameOrEmail, selectIsAdminOrOwner } from "../../../../../redux/user";
import { Collapse, Table, TableBody, TableCell, TableRow } from "@mui/material";
import TrainingProgramFormRow from "./TrainingProgramFormRow";
import { getFilterTrainingProgramForms, getSearchTrainingProgramForms, getSectionFormRelationMatchesFilter } from '../../../../../helpers/osha-hipaa-forms/helpers'

const ProgramFormRow = (props: {
  setFileUploadModalIsOpen: (open: boolean) => void;
  setSelectedForm: (id: number) => void;
  setSelectedTrainingForm: (id: number) => void;
  teamMemberIndex: {[Identfier: number]: TeamMember};
  handleDeleteClick: (id: number) => void;
  handleAddForm: (id: number) => void;
  handleEditTeamMember: (form: ProgramForm, formTeamMembers: TeamMember[]) => void;
  colWidths: number[];
  filters: ProgramFormsFilters | null;
  searchValue: string;
  sectionFormRelation: SectionFormRelation;
  filterValuesSet: boolean;
  programId?: number;
  sectionId: number;
}) => { 
  const {
    teamMemberIndex,
    filters,
    searchValue,
    sectionFormRelation,
    filterValuesSet,
    programId,
    sectionId
  } = props;
  const filterTrainingProgramForms = getFilterTrainingProgramForms(filters, filterValuesSet)
  const searchTrainingProgramForms = getSearchTrainingProgramForms(searchValue)
  const sectionFormRelationMatchesFilters = getSectionFormRelationMatchesFilter(searchValue, filters)
  const isAdminOrOwner = useAppSelector(selectIsAdminOrOwner);
  const form = sectionFormRelation.form;
  const [open, setOpen] = useState(true);
  const trainingProgramForms = sectionFormRelation.training_program_forms?.filter(f => f.section_id === sectionId) ?? [];
  const formTeamMembers: TeamMember[] = [];
  let uploadedCount = 0;
  
  trainingProgramForms.forEach(f => {
    if(f.user_id && f.section_id === sectionId) formTeamMembers.push(teamMemberIndex[f.user_id]);
    if(f.file_id && f.section_id === sectionId) uploadedCount++;
  });

  const showParentRow = useMemo(() => {
    if((filters && filterValuesSet) || searchValue !== '') {
      if(trainingProgramForms.length > 0 && form.is_practice_only) {
        const uploadedBy = trainingProgramForms[0]?.uploaded_by ? getFullNameOrEmail(teamMemberIndex[trainingProgramForms[0].uploaded_by]) : '';
        const teamMember = trainingProgramForms[0]?.user_id ? getFullNameOrEmail(teamMemberIndex[trainingProgramForms[0].user_id]) : '';
        const filtersMatched = filterTrainingProgramForms(sectionFormRelation, uploadedBy, teamMember, trainingProgramForms[0]);
        const searchMatched = searchTrainingProgramForms(sectionFormRelation, uploadedBy, teamMember, trainingProgramForms[0]);
        return filtersMatched && searchMatched;
      } else {
        return sectionFormRelationMatchesFilters(sectionFormRelation);
      }
    }
    return true;
  }, [filters, searchValue, trainingProgramForms])

  return (
    <React.Fragment>
      {showParentRow ? <TrainingProgramFormRow
        {...props}
        form={form}
        trainingProgramForm={trainingProgramForms[0]}
        isAdminOrOwner={isAdminOrOwner}
        isCollapsedRow={false}
        formTeamMembers={formTeamMembers}
        uploadedCount={uploadedCount}
        setOpen={setOpen}
        open={open}
        filters={filters}
        searchValue={searchValue}
        isMultipleUploads={form.is_multiple_uploads}
        filterValuesSet={filterValuesSet}
        index={0}
        programId={programId}
        sectionId={sectionId}
      /> : null}
      {(!form.is_practice_only || form.is_multiple_uploads) && open && trainingProgramForms.length > 0 ?
        <TableRow style={{ padding: 0, margin: 0 }}>
          <TableCell style={{ padding: 0 }} colSpan={8}>
            <Collapse style={{ padding: 0 }} in={open} timeout="auto" unmountOnExit>
              <Table size="small">
                <TableBody>
                  {trainingProgramForms.map((trainingProgramForm, index) => (
                    <TrainingProgramFormRow
                      {...props}
                      form={form}
                      key={trainingProgramForm.id}
                      trainingProgramForm={trainingProgramForm}
                      isAdminOrOwner={isAdminOrOwner}
                      isCollapsedRow={true}
                      formTeamMembers={formTeamMembers}
                      uploadedCount={uploadedCount}
                      setOpen={setOpen}
                      open={open}
                      filters={filters}
                      searchValue={searchValue}
                      isMultipleUploads={form.is_multiple_uploads}
                      filterValuesSet={filterValuesSet}
                      index={index}
                      programId={programId}
                      sectionId={sectionId}
                    />
                  ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      : null}
    </React.Fragment>
  );
}

export default ProgramFormRow