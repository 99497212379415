import { ChangeEvent, useEffect, useMemo, useState, MouseEvent } from 'react';
import { Autocomplete, Button, Card, Checkbox, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { match, P } from 'ts-pattern';
import * as R from 'remeda';

import CompliancePlip from '../../../components/CompliancePlip';
import { ComplianceFilterOptions, PracticeWithCompliance } from './types';
import { selectLoadingState, selectPracticesWithCompliance } from './slice';
import { selectGroups } from '../../../redux/customer';
import { ComplianceStatus, Groups, CustomerOshaHipaaMetadata } from '../../../types';

type SortProperty = keyof Omit<PracticeWithCompliance, 'customer_osha_hipaa_metadata'>;
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


type MetaDataFieldOrEmpty = keyof CustomerOshaHipaaMetadata | ''

const PracticesTable = ({ complianceFilter, handleComplianceFilter }: { complianceFilter: ComplianceFilterOptions, handleComplianceFilter: (filter: ComplianceFilterOptions) => void }) => {
  const groups = useSelector(selectGroups);
  const [groupFilter, setGroupFilter] = useState<Groups[]>([]);
  const [groupMembers, setGroupMembers] = useState<Groups[]>([]);
  const [reverseSort, setReverseSort] = useState(false);
  const [sortType, setSortType] = useState<SortProperty>('overall_compliance_status');
  const [pageLimit, setPageLimit] = useState<number>(25);
  const [tablePage, setTablePage] = useState<number>(0);
  const [search, setSearch] = useState('');
  const [categoryFilter, setCategoryFilter] = useState<MetaDataFieldOrEmpty>('');

  const loadingState = useSelector(selectLoadingState);
  const practices = useSelector(selectPracticesWithCompliance);

  const trainingCourseReference =
    'The calculation combines the Completion status within each module of OSHA, HIPAA, and Training Courses for each practice category.';

  const handleCategory = (event: SelectChangeEvent<MetaDataFieldOrEmpty>) => {
    const { value } = event.target;
    // @ts-expect-error
    setCategoryFilter(value);
  }

  const handleGroups = (groups: Groups[]) => {
    setGroupFilter(groups);
  };

  const handlePageLimit = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setPageLimit(parseInt(value));
  };

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearch(value);
  };

  const handleSort = (sortField: SortProperty) => {
    if (sortField === sortType) {
      setReverseSort(!reverseSort);
    } else {
      setSortType(sortField);
    }
  };

  const handleTablePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setTablePage(newPage);
  };

  const defaultColumnSort = (a: PracticeWithCompliance, b: PracticeWithCompliance) => {
    if (reverseSort) {
      return a[sortType] < b[sortType] ? -1 : a[sortType] > b[sortType] ? 1 : 0;
    }
    return b[sortType] < a[sortType] ? -1 : b[sortType] > a[sortType] ? 1 : 0;
  };

  // "custom" sort for compliance status based fields
  const complianceStatusColumnSort = (a: PracticeWithCompliance, b: PracticeWithCompliance) => {
    if (a[sortType] === b[sortType]) return 0; // doesn't matter if they are same

    // not-applicable always goes to the bottom of the list
    if (a[sortType] === ComplianceStatus.NotApplicable) return 1;
    if (b[sortType] === ComplianceStatus.NotApplicable) return -1;

    // sort normally if you reach here
    return defaultColumnSort(a, b);
  };

  const sortPractices = (a: PracticeWithCompliance, b: PracticeWithCompliance) => {
    const sortFn = match(sortType)
      .with(
        P.union('overall_compliance_status', 'osha_compliance_status', 'hipaa_compliance_status', 'training_compliance_status'),
        () => complianceStatusColumnSort
      )
      .otherwise(() => defaultColumnSort);
    return sortFn(a, b);
  };

  useEffect(() => {
    setGroupMembers([...groups]);
  }, [groups]);

  useEffect(() => {
    // When sort or filter changes, return to the first page
    setTablePage(0);
  }, [sortType, reverseSort, complianceFilter]);

  const sortedAndFilteredCustomers = useMemo(() => {
    let filteredCustomers = structuredClone(practices)
      .filter((p) =>
        match([complianceFilter, p.overall_compliance_status])
          .with(['', P._], () => true)
          .with(['complete', ComplianceStatus.Complete], () => true)
          .with(['in-progress', ComplianceStatus.InProgress], () => true)
          .with(['not-applicable', ComplianceStatus.NotApplicable], () => true)
          .with(['not-started', ComplianceStatus.NotStarted], () => true)
          .otherwise(() => false)
      )
      .sort(sortPractices);

    if (search !== '') {
      const cleanSearch = search.toLowerCase().trim();

      filteredCustomers = filteredCustomers.filter((customer) => {
        return customer.name.toLowerCase().includes(cleanSearch) || customer.city.toLowerCase().includes(cleanSearch);
      });
    }

    if (groupFilter.length) {
      const members = groupFilter.flatMap((group) => group?.members.map((member) => member.customer_number));
      if (members) {
        filteredCustomers = filteredCustomers.filter((customer) => members.includes(customer.customer_number));
      }
    }

    if (categoryFilter !== '') {
      filteredCustomers = filteredCustomers.filter((customer) => 
      match(customer?.customer_osha_hipaa_metadata?.[categoryFilter])
        .with(P.union(ComplianceStatus.Complete, ComplianceStatus.InProgress, ComplianceStatus.NotStarted), () => true)
        .otherwise(() => false)
      )
    }

    return filteredCustomers;
  }, [categoryFilter, groupFilter, practices, reverseSort, search, sortType, complianceFilter]);

  const displayedCustomers = useMemo(() => {
    const sliceStart = tablePage * pageLimit;
    const sliceEnd = (tablePage + 1) * pageLimit;
    return sortedAndFilteredCustomers.slice(sliceStart, sliceEnd);
  }, [sortedAndFilteredCustomers, pageLimit, tablePage]);

  const showSortIcon = (sortField: SortProperty) => {
    if (sortField !== sortType) {
      return;
    }

    if (reverseSort) {
      return <FontAwesomeIcon icon="caret-up" size="sm" className="m-l-8" />;
    }

    return <FontAwesomeIcon icon="caret-down" size="sm" className="m-l-8" />;
  };

  return (
    <Card variant="outlined">
      <div className="box-header">
        {match(loadingState)
          .with('done', () => (
            <>
              {sortedAndFilteredCustomers.length} Practice{sortedAndFilteredCustomers.length > 1 ? 's' : ''}
            </>
          ))
          .with(P.union('loading', 'not-asked'), () => <Skeleton variant="text" width={80} />)
          .otherwise(() => (
            <span>&nbsp;</span>
          ))}
      </div>
      <div>
        {practices.length > 0 && (
          <div className="filter-container flex flex-space-between">
            <div className="flex">
              <FormControl sx={{ mr: 2, minWidth: 200 }} size="small">
                <InputLabel>Filter by Compliance</InputLabel>
                <Select value={complianceFilter} label="Filter by Compliance" onChange={(e) => handleComplianceFilter(e.target.value as ComplianceFilterOptions)}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="not-started">Action Required</MenuItem>
                  <MenuItem value="in-progress">In Progress</MenuItem>
                  <MenuItem value="complete">Completed</MenuItem>
                  <MenuItem value="not-applicable">Service Inactive</MenuItem>
                </Select>
              </FormControl>
              {groupMembers.length > 0 && (
                <Autocomplete
                  multiple
                  onChange={(event, newValue) => {
                    handleGroups(newValue);
                  }}
                  options={groupMembers}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                      {option.name}
                    </li>
                  )}
                  size="small"
                  style={{ minWidth: 200, maxWidth: 500, marginRight: '16px' }}
                  renderInput={(params) => <TextField {...params} label="Filter by Group" />}
                />
              )}
              <FormControl sx={{ mr: 2, minWidth: 200 }} size="small">
                <InputLabel>Filter by Category</InputLabel>
                <Select value={categoryFilter} label="Filter by Category" onChange={handleCategory}>
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="osha_compliance_status">OSHA</MenuItem>
                  <MenuItem value="hipaa_compliance_status">HIPAA</MenuItem>
                  <MenuItem value="training_compliance_status">Training Courses</MenuItem>
                </Select>
              </FormControl>
            </div>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Search"
              onChange={handleSearch}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <FontAwesomeIcon icon="magnifying-glass" size="sm" className="m-l-8" />
                  </IconButton>
                )
              }}
            />
          </div>
        )}
        <Table size="small" data-testid="practices-table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ width: '10%' }}
                onClick={() => {
                  handleSort('overall_compliance_status');
                }}
                className="pointer"
              >
                Status {showSortIcon('overall_compliance_status')}
              </TableCell>
              <TableCell
                onClick={() => {
                  handleSort('name');
                }}
                className="pointer"
              >
                Name {showSortIcon('name')}
              </TableCell>
              <TableCell
                onClick={() => {
                  handleSort('city');
                }}
                className="pointer"
              >
                Location {showSortIcon('city')}
              </TableCell>
              <TableCell
                align="center"
                onClick={() => {
                  handleSort('osha_compliance_status');
                }}
                className="pointer"
              >
                OSHA {showSortIcon('osha_compliance_status')}
              </TableCell>
              <TableCell
                align="center"
                onClick={() => {
                  handleSort('hipaa_compliance_status');
                }}
                className="pointer"
              >
                HIPAA {showSortIcon('hipaa_compliance_status')}
              </TableCell>
              <TableCell
                align="center"
                onClick={() => {
                  handleSort('training_compliance_status');
                }}
                className="pointer"
              >
                Training Courses {showSortIcon('training_compliance_status')}
              </TableCell>
              <TableCell
                align="center"
                onClick={() => {
                  handleSort('percentage_complete');
                }}
                className="pointer"
              >
                <span className="m-r-8">% Complete</span>
                <Tooltip title={trainingCourseReference}>
                  <FontAwesomeIcon icon="circle-info" size="lg" />
                </Tooltip>{' '}
                {showSortIcon('percentage_complete')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {match(loadingState)
              .with(P.union('loading', 'not-asked'), () =>
                [...Array(pageLimit)].map((_, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>
                        <Skeleton variant="circular" className="m-l-16" width={10} height={10} />
                      </TableCell>
                      {[...Array(6)].map((_, key2) => {
                        return (
                          <TableCell key={key2}>
                            <Skeleton />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              )
              .with('done', () =>
                displayedCustomers.map((customer) => {
                  return (
                    <TableRow key={customer.customer_number}>
                      <TableCell>
                        <CompliancePlip className="m-l-16" status={customer.overall_compliance_status} />
                      </TableCell>
                      <TableCell>
                        <Button to={`/corp/practice/${customer.customer_number}/osha-hipaa`} component={Link} relative="path">
                          {customer.name.trim()} (#{customer.customer_number.trim()})
                        </Button>
                      </TableCell>
                      <TableCell>
                        {customer.city.trim()}, {customer.state.trim()}
                      </TableCell>
                      <TableCell align="center">
                        <CompliancePlip status={customer.osha_compliance_status} />
                      </TableCell>
                      <TableCell align="center">
                        <CompliancePlip status={customer.hipaa_compliance_status} />
                      </TableCell>
                      <TableCell align="center">
                        <CompliancePlip status={customer.training_compliance_status} />
                      </TableCell>
                      <TableCell align="center">{customer.percentage_complete}</TableCell>
                    </TableRow>
                  );
                })
              )
              .otherwise(() => null)}
          </TableBody>
        </Table>
        {match([loadingState, sortedAndFilteredCustomers])
          .with(['done', P.when(R.isNot(R.isEmpty))], () => (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={sortedAndFilteredCustomers.length}
              rowsPerPage={pageLimit}
              page={tablePage}
              onPageChange={handleTablePage}
              onRowsPerPageChange={handlePageLimit}
              sx={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}
            />
          ))
          .with(['done', P.when(R.isEmpty)], () => <div className="p-16 center">There are no matching practices</div>)
          .with(['error', P._], () => <div className="p-16 center">An error has occured, please try again.</div>)
          .otherwise(() => null)}
      </div>
    </Card>
  );
};

export default PracticesTable;
