import React from 'react'
import { Box, useTheme } from '@mui/material'

const NoDocumentsPurchased = (props: {componentTypeName: string | undefined}) => {
    const theme = useTheme()
    const { componentTypeName } = props
    
    return (
      <Box sx={{ color: theme.palette.color50555b.main }}>
            <div className="">
                <h3>{componentTypeName ?? ''} Forms</h3>
                <div style={{fontWeight: 'bold', textAlign: 'center'}}>
                    Your purchase does not have any documents that need to be uploaded.
                </div>
            </div>
        </Box>
    )
}

export default NoDocumentsPurchased
