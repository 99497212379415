import { redirect } from 'react-router-dom';

import SecurityRiskAssessment from './SecurityRiskAssessment';
import PracticeInformation from './PracticeInformation';
import BusinessAssociatesList from './BusinessAssociatesList';
import ElectronicMediaInventory from './ElectronicMediaInventory';
import PHIHandlers from './PHIHandlers';
import ProgramChecklist from '../ProgramChecklist';

export type SRARoutes = 'practice-information' | 'business-associates' | 'electronic-media-inventory' | 'phi-handlers' | 'risk-assessment';

export default {
  path: 'sra',
  element: <SecurityRiskAssessment />,
  children: [
    {
      index: true,
      loader: () => redirect('practice-information' satisfies SRARoutes)
    },
    {
      path: 'practice-information' satisfies SRARoutes,
      element: <PracticeInformation />
    },
    {
      path: 'business-associates' satisfies SRARoutes,
      element: <BusinessAssociatesList />
    },
    {
      path: 'electronic-media-inventory' satisfies SRARoutes,
      element: <ElectronicMediaInventory />
    },
    {
      path: 'phi-handlers' satisfies SRARoutes,
      element: <PHIHandlers />
    },
    {
      path: 'risk-assessment' satisfies SRARoutes,
      element: <ProgramChecklist checklistType="security risk assessment" />
    }
  ]
};
