import axios from 'axios';
import constants from '../constants';
import { TrainingChecklistTasksMetadata } from '../views/practice/dentalenhancements/types';

export const postChecklistTaskInit = async (customer_number: string, year: string): Promise<TrainingChecklistTasksMetadata[]> => {
  if (!customer_number) return [];
  const response = await axios.post(`${constants.ONTRAQ_API_URL}/api/Customers/${customer_number}/trainingCompliance/checklistTasks/init/${year}`);
  return response.data;
};

