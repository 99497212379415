import { ProgramFormsFilters, SectionFormRelation, TrainingProgramForm } from '../../views/practice/dentalenhancements/types'

export const getFilterTrainingProgramForms = (filters: ProgramFormsFilters | null, filterValuesSet: boolean) => {
  const filterTrainingProgramForms = (
    sectionFormRelation: SectionFormRelation,
    uploadedBy: string,
    teamMember: string,
    form: TrainingProgramForm
  ) => {
    const status = form.file_id ? 'Complete' : 'Missing';
    let key: keyof ProgramFormsFilters;
    let formNameMatchesFilter = !filterValuesSet;
    let uploadedByMatchesFilter = !filterValuesSet;
    let teamMemberMatchesFilter = !filterValuesSet;
    let statusMatchesFilter = !filterValuesSet;
    if(filters && filterValuesSet) {
      for(key in filters) {
        const filter = filters[key];
        switch(key) {
          case 'Form Name':
            formNameMatchesFilter = filter.value === 'None' || sectionFormRelation.form.name === filter.value;
            break;
          case 'Uploaded By':
            uploadedByMatchesFilter = filter.value === 'None' || uploadedBy === filter.value;
            break;
          case 'Team Member':
            teamMemberMatchesFilter = filter.value === 'None' || teamMember === filter.value;
            break;
          case 'Status':
            statusMatchesFilter = filter.value === 'None' || status === filter.value;
            break;
        }
      }
    }
    return formNameMatchesFilter && uploadedByMatchesFilter && teamMemberMatchesFilter && statusMatchesFilter;
  }
  return filterTrainingProgramForms
}

export const getSearchTrainingProgramForms = (searchValue: string) => {
  const searchTrainingProgramForms = (
    sectionFormRelation: SectionFormRelation,
    uploadedBy: string,
    teamMember: string,
    form: TrainingProgramForm,
  ) => {
    const status = form.file_id ? 'complete' : 'missing';
    const lowerCasedSearchValue = searchValue.toLowerCase();
    const uploadedForm = form.file_id ? form.file_api_uploads.display_name.toLowerCase() : '';
    let formNameMatchesSearch = searchValue === '';
    let uploadedByMatchesSearch = searchValue === '';
    let teamMemberMatchesSearch = searchValue === '';
    let statusMatchesSearch = searchValue === '';
    let uploadedFormMatchesSearch = searchValue === '';
  
    if(searchValue !== '') {
      formNameMatchesSearch = sectionFormRelation.form.name.toLowerCase().includes(lowerCasedSearchValue);
      uploadedByMatchesSearch = uploadedBy.toLowerCase().includes(lowerCasedSearchValue);
      teamMemberMatchesSearch = teamMember.toLowerCase().includes(lowerCasedSearchValue);
      statusMatchesSearch = status.includes(lowerCasedSearchValue);
      uploadedFormMatchesSearch = uploadedForm.includes(lowerCasedSearchValue);
    }
  
    return formNameMatchesSearch ||
      uploadedByMatchesSearch ||
      teamMemberMatchesSearch ||
      statusMatchesSearch ||
      uploadedFormMatchesSearch;
  }
  return searchTrainingProgramForms
}

export const getSectionFormRelationMatchesFilter = (searchValue: string, filters: ProgramFormsFilters | null) => {
  const sectionFormRelationMatchesFilters = (sectionFormRelation: SectionFormRelation) => {
    let filtersMatched = true;
    const formNameMatchesSearch = searchValue === '' || sectionFormRelation.form.name.toLowerCase().includes(searchValue.toLowerCase());
    if(filters) {
      const formNameMatchesFilter =  filters['Form Name'].value === 'None' || sectionFormRelation.form.name === filters['Form Name'].value;
      const uploadedByMatchesFilter =  filters['Uploaded By'].value === 'None';
      const teamMemberMatchesFilter =  filters['Team Member'].value === 'None';
      const statusMatchesFilter =  filters['Status'].value === 'None' || filters['Status'].value === 'Missing';
      filtersMatched = formNameMatchesFilter && uploadedByMatchesFilter && teamMemberMatchesFilter && statusMatchesFilter;
    }
    return filtersMatched && formNameMatchesSearch;
  }

  return sectionFormRelationMatchesFilters
}
