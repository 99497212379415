import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import constants from '../constants';
import { Button } from '@mui/material';
import './Footer.css';

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-content-left">
        <a href={`tel:${constants.HEALTHFIRST_PHONE_NUMBER}`}>
          <Button variant="primary">
            <FontAwesomeIcon icon="phone" size="sm" /> <span className="hidden-mobile m-l-8">{constants.HEALTHFIRST_PHONE_NUMBER}</span>
          </Button>
        </a>
        <a href={`mailto:${constants.HEALTHFIRST_EMAIL}`}>
          <Button variant="primary">
            <FontAwesomeIcon icon={['far', 'envelope']} /> <span className="hidden-mobile m-l-8">{constants.HEALTHFIRST_EMAIL}</span>
          </Button>
        </a>
      </div>
      <div className="footer-content-right">
        <span>3.0.0</span>
      </div>
    </div>
  );
}
