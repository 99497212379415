import cx from 'clsx';
import { Tooltip } from '@mui/material';

import { complianceColor } from '../helpers/compliance';
import { ComplianceStatus } from '../types';
import { getComplianceStatusText } from '../views/practice/dentalenhancements/utils';

const CompliancePlip = ({
  status,
  className,
  large,
  medium
}: {
  status: ComplianceStatus;
  className?: string;
  large?: boolean;
  medium?: boolean;
}) => {
  return (
    <Tooltip
      title={getComplianceStatusText(status)}
      placement="bottom"
      disableInteractive
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: { offset: [0, -8] }
            }
          ]
        }
      }}
    >
      <span
        className={cx(
          `compliance-circle`,
          complianceColor(status),
          { ['compliance-circle__large']: large, ['compliance-circle__medium']: medium },
          className
        )}
      />
    </Tooltip>
  );
};

export default CompliancePlip;
