import { Button, IconButton, TableCell } from "@mui/material";
import { useAppDispatch } from "../../../../../redux/store";
import { TeamMember } from "../../../../../types";
import { addTrainingProgramForm, deleteTrainingProgramForm } from "../../slice";
import { ProgramForm, ProgramFormFilter, TrainingProgramForm } from "../../types";
import HighlightedSearchText from "./HighlightedSearchText";
import { useMemo } from "react";
import { Cancel } from "@mui/icons-material";

const FormNameCell = (props: {
	form: ProgramForm,
	isAdminOrOwner: boolean,
	handleEditTeamMember: (form: ProgramForm, teamMembers: TeamMember[]) => void,
	formTeamMembers: TeamMember[],
	isCollapsedCell: boolean,
	colWidths: number[],
	colIndex: number,
	customerNumber: string,
	tableCellStyles: any,
	filter: ProgramFormFilter | null,
	searchValue: string,
	isMultipleUploads: boolean,
	filterValuesSet: boolean;
	trainingProgramForm: TrainingProgramForm;
	index: number;
	programId?: number;
	sectionId: number;
}) => {

	const { form,
		isAdminOrOwner,
		handleEditTeamMember,
		formTeamMembers,
		isCollapsedCell,
		colWidths,
		colIndex,
		customerNumber,
		tableCellStyles,
		filter,
		searchValue,
		filterValuesSet,
		trainingProgramForm,
		index,
		programId,
		sectionId
	} = props
	const dispatch = useAppDispatch();

	const handleAddForm = async (formId: number) => {
		await dispatch(addTrainingProgramForm({ customerNumber, formId, programId, sectionId }));
	}

	const filterValue = useMemo(() => filter && filter.value !== 'None' ? filter.value : '', [filter]);

	const handleDeleteClick = async () => {
		await dispatch(deleteTrainingProgramForm({ customerNumber, formId: trainingProgramForm.id }));
	}

	const sx = isCollapsedCell || form.is_multiple_uploads ? {...tableCellStyles, width: colWidths[colIndex]} : tableCellStyles;

	return (
		<>
			{
				isCollapsedCell && !form.is_multiple_uploads && !filterValuesSet ?
					<TableCell
						sx={sx}
						align="left"
					/>
			: 
				<TableCell sx={sx}>
					<HighlightedSearchText
						searchValue={searchValue}
						filterValue={filterValue}
						text={form.name}
						style={{ display: 'inline', fontSize: '13px' }}
					/>
					{
						isCollapsedCell && form.is_multiple_uploads && isAdminOrOwner ?
							<IconButton
								disabled={trainingProgramForm.file_id !== null}
								sx={{
									color: '#50555b',
									height: '20px',
									width: '20px',
									marginLeft: '5px',
									display: 'inline',
									verticalAlign: 'super'
								}}
								onClick={handleDeleteClick}
							>
								<Cancel
									fontSize="small"
									sx={{
										width: '16px',
										heigith: '16px',
										position: 'absolute',
										top: '3px',
										left: '0px'
									}}
								/>
							</IconButton>
						: null
					}
					{form.is_multiple_uploads && isAdminOrOwner && !isCollapsedCell ?
						<Button
							style={{
								padding: 0,
								textTransform: 'capitalize',
								display: 'block',
								fontSize: '11px'
							}}
							variant="text"
							onClick={() => handleAddForm(form.id)}
							size="small"
						>+Add Form</Button> 
					: null}
					{
						!isCollapsedCell && !form.is_practice_only && isAdminOrOwner ?
							<Button
								style={{
									padding: 0,
									textTransform: 'capitalize',
									display: 'block',
									fontSize: '11px'
								}}
								variant="text"
								onClick={() => handleEditTeamMember(form, formTeamMembers)}
								size="small"
							>+Add/Edit Team Member</Button>
						: null  
					}
				</TableCell>
			}
		</>
	)
}

export default FormNameCell