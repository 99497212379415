import ManualWrapper from './ManualWrapper';
import { getHipaaManual } from './services';
import DownloadFormButton from './DownloadFormButton';
import { useYearAndCustomerNumber } from './hooks';

const Hipaa = () => {
  const { year, customerNumber } = useYearAndCustomerNumber();

  return (
    <ManualWrapper>
      <h1>Required HIPAA Manual</h1>
      <h2>Download and complete all items in your manual.</h2>
      <p>
        Download one copy. If you have purchased a premium package, you will walk through this document with your trainer. Each Section in the manual
        must be completed.
      </p>
      <hr />
      <ul>
        <li>
          Federal Annual HIPAA Manual
          <DownloadFormButton disabled={!customerNumber} getFileHandler={() => getHipaaManual(customerNumber ?? '', year)} />
        </li>
        <hr />
      </ul>
    </ManualWrapper>
  );
};

export default Hipaa;
