import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import deUserReducer from './deUser';
import customerSlice from './customer';
import childCustomerSlice from './childCustomer';
import { reducer as practiceDEReducer, oshaHippaMiddleware, sraReducer } from '../views/practice/dentalenhancements';
import { reducer as corpPracticeDEReducer } from '../views/corp/dentalenhancements';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { setCurrentYear } from '../views/practice/dentalenhancements/slice';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
  customer: customerSlice,
  childCustomer: childCustomerSlice,
  currentUser: userReducer,
  deUser: deUserReducer,
  practiceDE: practiceDEReducer,
  corpPracticeDE: corpPracticeDEReducer,
  sra: sraReducer
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    devTools: {
      actionCreators: { setCurrentYear }
    },
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(oshaHippaMiddleware.middleware)
  });
};

export default setupStore();

export type AppStore = ReturnType<typeof setupStore>;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
