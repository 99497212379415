import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import App from './App';
import AuthService from './services/authorization';
import { Provider } from 'react-redux';
import store from './redux/store';

const Auth = new AuthService();

axios.interceptors.request.use(
  (config) => {
    const token = Auth.getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <Provider store={store}>
    <App />
  </Provider>
);
