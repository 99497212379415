import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const CloseModalButton = ({ handleClose }: { handleClose: () => void }) => (
  <IconButton
    aria-label="close"
    onClick={handleClose}
    sx={{
      position: 'absolute',
      right: 8,
      top: 8,
      color: (theme) => theme.palette.grey[500],
      padding: 0
    }}
  >
    <CloseIcon fontSize="small" />
  </IconButton>
);

export default CloseModalButton;
