import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ProgramForm, ProgramFormsFilters, SectionFormRelation, TrainingProgramForm } from '../../types';
import { TeamMember } from '../../../../../types';
import { ToolTip } from '../../components/ToolTip';
import ProgramFormRow from './ProgramFormRow';
import { getFullNameOrEmail } from '../../../../../redux/user';
import { ArrowDropDown } from '@mui/icons-material';

type Order = 'asc' | 'desc';

const columnStyles = { borderBottom: 'unset', minWidth: '70px', maxWidth: '70px', width: '70px', padding: 0, margin: 0 }

const CollapsibleTable = (props: {
  rows: SectionFormRelation[],
  formsIndex: {[Identfier: number]: TrainingProgramForm[]};
  setFileUploadModalIsOpen: (open: boolean) => void;
  setSelectedForm: (id: number) => void;
  setSelectedTrainingForm: (id: number) => void;
  teamMemberIndex: {[Identfier: number]: TeamMember};
  handleDeleteClick: (id: number) => void;
  handleAddForm: (id: number) => void;
  handleEditTeamMember: (form: ProgramForm, formTeamMembers: TeamMember[]) => void;
  filters: ProgramFormsFilters | null;
  searchValue: string;
  filterValuesSet: boolean;
  programId?: number;
  sectionId: number;
}) => {
  
  const { rows, filters, searchValue, teamMemberIndex, programId, sectionId } = props;
  const [sortDirection, setSortDirection] = useState<Order>('desc');
  const [sortBy, setSortBy] = useState<string>('formName');
  const [colWidths, setColWidths] = useState<Array<number>>([]);
  const ref: React.MutableRefObject<any> = useRef(null);

  const sortTableData = (column: string) => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    setSortBy(column);
  }

  useEffect(() => {
    setColWidths(
      Array.from(ref?.current?.children || []).map((c : any) => {
        return c?.getBoundingClientRect()?.width
      })
    );
  }, [ref]);

  const sortByUser = (field: 'uploaded_by' | 'user_id') => {
    return rows.map(row => {
      return {
        ...row,
        training_program_forms: row.training_program_forms?.sort((a, b) => {
          const userIdA = a[field];
          const userIdB = b[field];
          const userA = userIdA ? (getFullNameOrEmail(teamMemberIndex[userIdA])?.toLowerCase() ?? '') : '';
          const userB = userIdB ? (getFullNameOrEmail(teamMemberIndex[userIdB])?.toLowerCase() ?? '') : '';
          if(sortDirection === 'asc') return userA < userB ? -1 : 1;
          else return userA < userB ? 1 : -1;
        })
      }
    }).sort((a, b) => {
      const userIdA = field === 'uploaded_by' ? a.training_program_forms?.at(0)?.uploaded_by : a.training_program_forms?.at(0)?.user_id;
      const userIdB = field === 'uploaded_by' ? b.training_program_forms?.at(0)?.uploaded_by : b.training_program_forms?.at(0)?.user_id;
      const userA = userIdA ? (getFullNameOrEmail(teamMemberIndex[userIdA])?.toLowerCase() ?? '') : '';
      const userB = userIdB ? (getFullNameOrEmail(teamMemberIndex[userIdB])?.toLowerCase() ?? '') : '';
      if(sortDirection === 'asc') return userA < userB ? -1 : 1;
      else return userA < userB ? 1 : -1;
    });
  };

  const sortByUploadedForm = () => {
    return rows.map(row => {
      return {
        ...row,
        training_program_forms: row.training_program_forms?.sort((a, b) => {
          const uploadedFormA = a.file_api_uploads?.display_name?.toLowerCase() ?? '';
          const uploadedFormB = b.file_api_uploads?.display_name?.toLowerCase() ?? '';
          if(sortDirection === 'asc') return uploadedFormA < uploadedFormB ? -1 : 1;
          else return uploadedFormA < uploadedFormB ? 1 : -1;
        })
      }
    }).sort((a, b) => {
      const uploadedFormA = a.training_program_forms?.at(0)?.file_api_uploads?.display_name?.toLowerCase() ?? '';
      const uploadedFormB = b.training_program_forms?.at(0)?.file_api_uploads?.display_name?.toLowerCase() ?? '';
      if(sortDirection === 'asc') return uploadedFormA < uploadedFormB ? -1 : 1;
      else return uploadedFormA < uploadedFormB ? 1 : -1;
    });
  };

  const sortByFormName = () => {
    let sorted = rows.sort((a, b) => {
      if(sortDirection === 'asc') return a.form.name < b.form.name ? -1 : 1;
      else return a.form.name < b.form.name ? 1 : -1;
    });
    return sorted
  };

  const sortedForms = useMemo(() => {
    switch(sortBy) {
      case 'formName':
        return sortByFormName();
      case 'uploadedBy':
        return sortByUser('uploaded_by');
      case 'teamMember':
        return sortByUser('user_id');
      case 'uploadedForm':
        return sortByUploadedForm();
      default:
        return sortByFormName();
    }
  }, [rows, sortDirection]);

  return (
    <Table size="small" sx={{ tableLayout: 'fixed' }}>
      <TableHead sx={{ display: "table-header-group" }}>
        <TableRow sx={{ borderBottom: 'unset'}} ref={ref}>
          <TableCell
            sx={{ ...columnStyles }}
            sortDirection={sortDirection}
          >
            <TableSortLabel
              active
              direction={sortBy === 'formName' ? sortDirection : 'desc'}
              onClick={() => sortTableData('formName')}
              IconComponent={ArrowDropDown}
            >
              Form Name
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ ...columnStyles, minWidth: '30px', maxWidth: '30px', width: '30px' }} align="center">Upload</TableCell>
          <TableCell sx={columnStyles} align="center">
            <TableSortLabel
              active
              direction={sortBy === 'uploadedBy' ? sortDirection : 'desc'}
              onClick={() => sortTableData('uploadedBy')}
              IconComponent={ArrowDropDown}
            >
              Uploaded By
            </TableSortLabel>
          </TableCell>
          <TableCell sx={columnStyles} align="center">
            <TableSortLabel
              active
              direction={sortBy === 'teamMember' ? sortDirection : 'desc'}
              onClick={() => sortTableData('teamMember')}
              IconComponent={ArrowDropDown}
            >
              Team Member
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ ...columnStyles,  minWidth: '70px', maxWidth: '70px', width: '70px' }} align="center">Status</TableCell>
          <TableCell sx={{ ...columnStyles, width: '150px' }} align="center">
            <TableSortLabel
              direction={sortBy === 'uploadedForm' ? sortDirection : 'desc'}
              active
              onClick={() => sortTableData('uploadedForm')}
              IconComponent={ArrowDropDown}
            >
              Uploaded Form
            </TableSortLabel>
            <ToolTip icon="info" helpText="Only users with Admin and Owner level permissions can delete files."/>
          </TableCell>
          <TableCell sx={{ ...columnStyles, minWidth: '40px', maxWidth: '40px', width: '40px', marginRight: '5px' }} align="center">
            <span
              style={{
                verticalAlign: 'bottom',
                marginRight: '5px'
              }}
            >
              Exempt
            </span>
            <ToolTip
              icon="info"
              helpText="If your practice doesn't require this form, please check the box so that the compliance doesn't get affected. Only users with Admin and Owner level permissions can exempt files."
            />
          </TableCell>
          <TableCell sx={{ ...columnStyles, minWidth: '20px', maxWidth: '20px', width: '20px' }} align="center" />
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedForms
          .map(row => {
            return (
              <ProgramFormRow
                {...props}
                sectionFormRelation={row}
                key={row.id}
                colWidths={colWidths}
                filters={filters}
                searchValue={searchValue}
                programId={programId}
                sectionId={sectionId}
              />
            )
          }
        )}
      </TableBody>
    </Table>
  );
}

export default CollapsibleTable;
