import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { P, match } from 'ts-pattern';

import { downloadFile, SimpleFile } from '../../helpers/downloadFile';

import styles from './DownloadFormButton.module.css';

type State = 'not-started' | 'preparing' | 'done';

const DownloadFormButton = ({ getFileHandler, disabled }: { getFileHandler: () => Promise<SimpleFile>; disabled?: boolean }) => {
  const [state, setState] = useState<State>('not-started');

  const handleDownload = async () => {
    setState('preparing');

    await getFileHandler().then(downloadFile);

    setState('done');
  };

  return (
    <span>
      <button disabled={disabled || state === 'preparing'} onClick={handleDownload} className={styles['download-form-button']}>
        {match(state)
          .with(P.union('not-started', 'done'), () => <FontAwesomeIcon icon={faFileAlt} />)
          .with('preparing', () => <FontAwesomeIcon icon={faSpinner} spin />)
          .exhaustive()}
        <span>Download Form</span>
      </button>
      {state === 'preparing' && (
        <p className={styles['download-status-message']} role="status">
          Please wait. Your copy will download shortly...
        </p>
      )}
    </span>
  );
};

export default DownloadFormButton;
