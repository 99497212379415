import { PayloadAction, createAsyncThunk, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';
import * as R from 'remeda';
import { match, P } from 'ts-pattern';

import user, { User } from '../../../../redux/user';
import { EphiTeamMembers, SRABusinessAssociate, SRAElectronicMediaInventory } from './types';

import {
  getUsers,
  getElectronicMediaInventory,
  updateElectronicMediaInventoryItem,
  createElectronicMediaInventoryItem,
  deleteElectronicMediaInventoryItem,
  getSRABusinessAssociates,
  updateSRABusinessAssociate,
  createSRABusinessAssociate,
  deleteSRABusinessAssociate,
  getEphiTeamMembers,
  deleteEphiTeamMember as deleteEphiTeamMemberRequest,
  createEphiTeamMember as createEphiTeamMemberRequest,
  updateEphiTeamMember as updateEphiTeamMemberRequest
} from './services';
import { RootState } from '../../../../redux/store';
import { RemoteData, failure, loading, mapSuccess, notAsked, success, unwrapOr } from '../../../../helpers/RemoteData';
import customer, { selectCurrentCustomerNumber } from '../../../../redux/customer';
import { selectCurrentYear } from '../slice';

const SLICE_PREFIX = 'securityRiskAssessment';

interface SnackbarMessage {
  message: string;
  type: 'normal' | 'error';
}

interface State {
  users: RemoteData<Record<string, User>, unknown>;
  inventory: RemoteData<Record<string, SRAElectronicMediaInventory>, unknown>;
  businessAssociates: RemoteData<Record<string, SRABusinessAssociate>, unknown>;
  ephiTeamMembers: RemoteData<Record<string, EphiTeamMembers>, unknown>;
  snackBarMessage: SnackbarMessage | null;
}

const initialState: State = {
  users: notAsked(),
  inventory: notAsked(),
  businessAssociates: notAsked(),
  ephiTeamMembers: notAsked(),
  snackBarMessage: null
};

export const fetchUsers = createAsyncThunk(`${SLICE_PREFIX}/getUsers`, (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const customerNumber = selectCurrentCustomerNumber(state);
  const year = selectCurrentYear(state);

  return getUsers(customerNumber, year);
});

export const fetchInventory = createAsyncThunk(`${SLICE_PREFIX}/getInventory`, (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const customerNumber = selectCurrentCustomerNumber(state);
  const year = selectCurrentYear(state);

  return getElectronicMediaInventory(customerNumber, year);
});

export const updateInventoryItem = createAsyncThunk(
  `${SLICE_PREFIX}/updateInventoryItem`,
  async (item: Partial<SRAElectronicMediaInventory>, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const customerNumber = selectCurrentCustomerNumber(state);
    const year = selectCurrentYear(state);

    await updateElectronicMediaInventoryItem(customerNumber, { ...item, year });

    return item;
  }
);

export const createInventoryItem = createAsyncThunk(
  `${SLICE_PREFIX}/updateInventoryItem`,
  async (item: Partial<SRAElectronicMediaInventory>, thunkApi) => {
    const state = thunkApi.getState() as RootState;

    const customerNumber = selectCurrentCustomerNumber(state);
    const year = selectCurrentYear(state);

    return createElectronicMediaInventoryItem(customerNumber, { ...item, year });
  }
);

export const deleteInventoryItem = createAsyncThunk(`${SLICE_PREFIX}/deleteInventoryItem`, async (id: string, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const customerNumber = selectCurrentCustomerNumber(state);

  await deleteElectronicMediaInventoryItem(customerNumber, id);

  return id;
});

export const fetchBusinessAssociates = createAsyncThunk(`${SLICE_PREFIX}/getBusinessAssociates`, (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const customerNumber = selectCurrentCustomerNumber(state);
  const year = selectCurrentYear(state);

  return getSRABusinessAssociates(customerNumber, year);
});

export const createBusinessAssociate = createAsyncThunk(
  `${SLICE_PREFIX}/createBusinessAssociate`,
  (businessAssociate: Partial<SRABusinessAssociate>, thunkApi) => {
    const state = thunkApi.getState() as RootState;
    const customer_number = selectCurrentCustomerNumber(state);
    const year = selectCurrentYear(state);

    return createSRABusinessAssociate({ ...businessAssociate, customer_number, year });
  }
);

export const updateBusinessAssociate = createAsyncThunk(`${SLICE_PREFIX}/updateBusinessAssociate`, updateSRABusinessAssociate);

export const deleteBusinessAssociate = createAsyncThunk(`${SLICE_PREFIX}/deleteBusinessAssociate`, deleteSRABusinessAssociate);

export const fetchEphiTeamMembers = createAsyncThunk(`${SLICE_PREFIX}/getEphiTeamMemers`, (_, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const customerNumber = selectCurrentCustomerNumber(state);
  const year = selectCurrentYear(state);

  return getEphiTeamMembers(customerNumber, year);
});

export const createEphiTeamMember = createAsyncThunk(`${SLICE_PREFIX}/createEphiTeamMember`, async (member: Partial<EphiTeamMembers>, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const customerNumber = selectCurrentCustomerNumber(state);
  const year = selectCurrentYear(state);

  return createEphiTeamMemberRequest(customerNumber, { ...member, year });
});

export const updateEphiTeamMember = createAsyncThunk(`${SLICE_PREFIX}/updateEphiTeamMember`, async (member: Partial<EphiTeamMembers>, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const customerNumber = selectCurrentCustomerNumber(state);

  return updateEphiTeamMemberRequest(customerNumber, member);
});

export const deleteEphiTeamMember = createAsyncThunk(`${SLICE_PREFIX}/deleteEphiTeamMember`, async (id: string, thunkApi) => {
  const state = thunkApi.getState() as RootState;

  const customerNumber = selectCurrentCustomerNumber(state);

  await deleteEphiTeamMemberRequest(customerNumber, id);

  return id;
});

const slice = createSlice({
  initialState,
  name: SLICE_PREFIX,
  reducers: {
    showSnackBar(state, { payload: { type = 'normal', message } }: PayloadAction<{ type?: SnackbarMessage['type']; message: string }>) {
      state.snackBarMessage = {
        type,
        message
      };
    },
    hideSnackBar(state) {
      state.snackBarMessage = null;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = success(R.indexBy(action.payload, R.prop('user_id')));
    });

    builder.addCase(fetchUsers.pending, (state) => {
      state.users = loading();
    });

    builder.addCase(fetchUsers.rejected, (state, action) => {
      state.users = failure(action.error);
    });

    builder.addCase(fetchInventory.fulfilled, (state, action) => {
      state.inventory = success(R.indexBy(action.payload, R.prop('id')));
    });

    builder.addCase(fetchInventory.pending, (state) => {
      state.inventory = loading();
    });

    builder.addCase(fetchInventory.rejected, (state, action) => {
      state.inventory = failure(action.error);
    });

    builder.addCase(deleteInventoryItem.fulfilled, (state, action) => {
      state.inventory = mapSuccess(state.inventory, (inventory) => {
        const newInventory = { ...inventory };
        delete newInventory[action.payload];

        return newInventory;
      });
    });

    builder.addCase(fetchBusinessAssociates.fulfilled, (state, action) => {
      state.businessAssociates = success(R.indexBy(action.payload, R.prop('id')));
    });

    builder.addCase(fetchBusinessAssociates.pending, (state) => {
      state.businessAssociates = loading();
    });

    builder.addCase(fetchBusinessAssociates.rejected, (state, action) => {
      state.businessAssociates = failure(action.error);
    });

    builder.addCase(fetchEphiTeamMembers.fulfilled, (state, action) => {
      state.ephiTeamMembers = success(R.indexBy(action.payload, R.prop('id')));
    });

    builder.addCase(fetchEphiTeamMembers.pending, (state) => {
      state.ephiTeamMembers = loading();
    });

    builder.addCase(fetchEphiTeamMembers.rejected, (state, action) => {
      state.ephiTeamMembers = failure(action.error);
    });

    builder.addCase(deleteEphiTeamMember.fulfilled, (state, action) => {
      state.ephiTeamMembers = mapSuccess(state.ephiTeamMembers, (teamMembers) => {
        const newTeamMembers = { ...teamMembers };
        delete newTeamMembers[action.payload];

        return newTeamMembers;
      });
    });

    builder.addCase(deleteBusinessAssociate.fulfilled, (state, { payload: { id: deletedId } }) => {
      state.businessAssociates = mapSuccess(state.businessAssociates, (businessAssociates) => {
        const newBusinessAssociates = { ...businessAssociates };
        delete newBusinessAssociates[deletedId];
        return newBusinessAssociates;
      });
    });

    builder.addMatcher(isAnyOf(deleteBusinessAssociate.fulfilled, deleteInventoryItem.fulfilled, deleteEphiTeamMember.fulfilled), (state) => {
      state.snackBarMessage = {
        type: 'normal',
        message: 'The data has successfully been deleted.'
      };
    });

    builder.addMatcher(isAnyOf(createInventoryItem.fulfilled, updateInventoryItem.fulfilled), (state, { payload: item }) => {
      const id = item.id;
      if (id) {
        state.inventory = mapSuccess(state.inventory, (inventory) => ({
          ...inventory,
          [id]: item
        }));
      }
    });

    builder.addMatcher(isAnyOf(createEphiTeamMember.fulfilled, updateEphiTeamMember.fulfilled), (state, { payload: member }) => {
      const { id } = member;

      if (id) {
        state.ephiTeamMembers = mapSuccess(state.ephiTeamMembers, (members) => ({
          ...members,
          [id]: member
        }));
      }
    });

    builder.addMatcher(isAnyOf(createBusinessAssociate.fulfilled, updateBusinessAssociate.fulfilled), (state, { payload: newBusinessAssociate }) => {
      const { id } = newBusinessAssociate;

      if (id) {
        state.businessAssociates = mapSuccess(state.businessAssociates, (businessAssociates) => ({
          ...businessAssociates,
          [id]: newBusinessAssociate
        }));
      }
    });

    builder.addMatcher(
      isAnyOf(
        createBusinessAssociate.fulfilled,
        updateBusinessAssociate.fulfilled,
        createInventoryItem.fulfilled,
        updateInventoryItem.fulfilled,
        createEphiTeamMember.fulfilled,
        updateEphiTeamMember.fulfilled
      ),
      (state) => {
        state.snackBarMessage = {
          type: 'normal',
          message: 'Your data has been successfully saved'
        };
      }
    );

    builder.addMatcher(
      isAnyOf(
        createBusinessAssociate.rejected,
        updateBusinessAssociate.rejected,
        deleteBusinessAssociate.rejected,
        createInventoryItem.rejected,
        updateInventoryItem.rejected,
        deleteInventoryItem.rejected,
        createEphiTeamMember.rejected,
        updateEphiTeamMember.rejected,
        deleteEphiTeamMember.rejected
      ),
      (state) => {
        state.snackBarMessage = {
          type: 'error',
          message: 'Something went wrong. Please try again'
        };
      }
    );
  }
});

export default slice.reducer;

export const { showSnackBar, hideSnackBar } = slice.actions;

const selectSRA = (state: RootState) => state.sra;

export const selectUsersMap = createSelector(selectSRA, (sra) =>
  match(sra.users)
    .with({ type: 'success', data: P.select() }, (u) => u)
    .otherwise(() => ({}))
);
const selectInventoryMap = createSelector(selectSRA, (sra) => sra.inventory);

export const selectBusinessAssociates = createSelector(selectSRA, (sra) => sra.businessAssociates);

export const selectEphiTeamMembers = createSelector(selectSRA, (sra) => sra.ephiTeamMembers);

export const selectUsers = createSelector(selectUsersMap, (usersMap) => Object.values(usersMap));

const selectUsersKeyedByLoginId = createSelector(selectUsers, (users) => R.indexBy(users, R.prop('login_user_id')));

export const selectEphiTeamMembersWithUsers = createSelector([selectEphiTeamMembers, selectUsersKeyedByLoginId], (teamMembers, usersMap) => {
  return mapSuccess(teamMembers, (membersMap) =>
    R.mapValues(membersMap, (m) => ({
      ...m,
      user: match(usersMap[m.user_id])
        .with(P.select(P.not(P.nullish)), (user) => user)
        .otherwise(() => m.user)
    }))
  );
});

export const selectSnackbarMessage = createSelector(selectSRA, (sra) => sra.snackBarMessage);

export const selectHasFetchedUsers = createSelector(selectSRA, (sra) => sra.users.type !== 'not-asked');

export const selectInventoryMapWithUsers = createSelector(
  [selectUsersMap, selectInventoryMap],
  (usersMap, inventory): RemoteData<Record<string, SRAElectronicMediaInventory>, unknown> => {
    return mapSuccess(inventory, (invMap) =>
      R.mapValues(invMap, (i) => ({
        ...i,
        users: i.users.map((u) =>
          match(usersMap[u.user_id])
            .with(P.select(P.not(P.nullish)), (user) => user)
            .otherwise(() => u)
        )
      }))
    );
  }
);

export const selectInventory = createSelector(
  [selectInventoryMapWithUsers],
  (inventory): RemoteData<SRAElectronicMediaInventory[], unknown> => mapSuccess(inventory, (invMap) => Object.values(invMap))
);

export const selectUnassignedEphiUsers = createSelector([selectEphiTeamMembers, selectUsers], (ephiTeamMembers, users) => {
  const existingMembers = R.pipe(
    ephiTeamMembers,
    mapSuccess((members) =>
      Object.values(members).reduce(
        (loginIdMap, member) => ({
          ...loginIdMap,
          [member.user_id]: true
        }),
        {} as Record<number, boolean>
      )
    ),
    unwrapOr({} as Record<number, boolean>)
  );

  return users.filter((u) => u.login_user_id && !existingMembers[u.login_user_id]);
});
