import { Link, Outlet, useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { match, P } from 'ts-pattern';
import * as R from 'remeda';
import { SRARoutes } from './routes';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { fetchUsers, hideSnackBar, selectHasFetchedUsers, selectSnackbarMessage } from './slice';
import { ErrorNotify, GenericNotify } from '../../../../components/Notify.styled';
import theme from '../../../../themes/theme';

const routeToLabel = (route: SRARoutes) =>
  match(route)
    .with('practice-information', () => 'Practice Information')
    .with('business-associates', () => 'Business Associates List')
    .with('electronic-media-inventory', () => 'Electronic Media Inventory')
    .with('phi-handlers', () => 'Employees Who Handle PHI')
    .with('risk-assessment', () => 'Risk Assessment')
    .exhaustive();

const routes: SRARoutes[] = ['practice-information', 'business-associates', 'electronic-media-inventory', 'phi-handlers', 'risk-assessment'];

const SecurityRiskAssessment = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const hasFetchedUsers = useAppSelector(selectHasFetchedUsers);
  const snackbarMessage = useAppSelector(selectSnackbarMessage);
  const handleCloseSnackbar = () => {
    dispatch(hideSnackBar());
  };

  const tabsValue = useMemo(() => {
    // This'll continue to work as long as all the routes under 'sra' are static
    return R.last(location.pathname.split('/'));
  }, [location]);

  useEffect(() => {
    if (!hasFetchedUsers) {
      dispatch(fetchUsers());
    }
  }, [hasFetchedUsers]);

  return (
    <div style={{ color: theme.palette.color50555b.main }}>
      <Tabs value={tabsValue} variant="scrollable" scrollButtons="auto">
        {routes.map((route) => (
          <Tab key={route} component={Link} to={route} value={route} label={routeToLabel(route)} />
        ))}
      </Tabs>
      <Outlet />
      {match(snackbarMessage)
        .with({ type: 'normal', message: P.select() }, (message) => <GenericNotify open={true} message={message} onClose={handleCloseSnackbar} />)
        .with({ type: 'error', message: P.select() }, (message) => (
          <ErrorNotify open={true} onClose={handleCloseSnackbar}>
            {message}
          </ErrorNotify>
        ))
        .otherwise(() => null)}
    </div>
  );
};

export default SecurityRiskAssessment;
