import { useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { useParams } from 'react-router-dom';
import { LinearProgress } from '@mui/material';

import ChecklistPDF from './components/ChecklistPDF';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import {
  getData,
  selectChecklistByYearAndComponentTypeIdAndType,
  selectChecklistCertificationByChecklistId,
  selectCustomerChecklistTaskMap,
  selectLoadingState,
  selectPracticeInfo
} from './slice';
import { checklistTypeFromSlug, componentTypeNameToId } from './utils';

/**
 * This page exists to more easily develop the pdfs. A end user should be clicking a button that downloads
 * the pdf directly, instead of going to a route with a pdf
 */
const ChecklistPDFPage = () => {
  const { componentTypeName, year, checklistTypeSlug, customerNumber } = useParams();

  const practiceInfo = useAppSelector(selectPracticeInfo);
  const loadingState = useAppSelector(selectLoadingState);
  const dispatch = useAppDispatch();

  const componentTypeId = componentTypeNameToId(componentTypeName);

  const checklist = useAppSelector((state) =>
    selectChecklistByYearAndComponentTypeIdAndType(state, year, componentTypeId, checklistTypeFromSlug(checklistTypeSlug))
  );

  const certification = useAppSelector((state) => selectChecklistCertificationByChecklistId(state, checklist?.id));

  const taskMetaMap = useAppSelector(selectCustomerChecklistTaskMap);

  useEffect(() => {
    if (loadingState != 'done' && customerNumber) {
      dispatch(getData(customerNumber));
    }
  }, [loadingState, customerNumber]);

  const loaded = loadingState === 'done' && checklist && practiceInfo;

  return loaded ? (
    <PDFViewer width={'100%'} height={'100%'}>
      <ChecklistPDF
        checklist={checklist}
        componentTypeName={componentTypeName}
        year={year}
        practiceInfo={practiceInfo}
        taskMetaMap={taskMetaMap}
        certification={certification}
      />
    </PDFViewer>
  ) : (
    <LinearProgress />
  );
};

export default ChecklistPDFPage;
