import { MouseEvent } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import CompliancePlip from '../../../components/CompliancePlip';
import { ComplianceStatus } from '../../../types';
import { getComplianceStatusText } from './utils';

type Props = {
  onChange: (status: ComplianceStatus) => void;
  value: number | undefined;
};

const StatusDropdown = ({ onChange, value }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onItemClick = (status: ComplianceStatus) => {
    handleClose();
    onChange(status);
  };

  return (
    <>
      <Button sx={{ width: '100%', height: '100%' }} onClick={handleClick}>
        <CompliancePlip status={value || 4} />
      </Button>
      <Menu
        id="compliance-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        onClick={handleClose}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'compliance-button'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        data-testid="compliance-status-dropdown-menu"
      >
        <MenuItem
          onKeyDown={(e) => (e.key === 'Enter' ? onItemClick(ComplianceStatus.Complete) : null)}
          onClick={() => onChange(ComplianceStatus.Complete)}
        >
          <CompliancePlip className="m-r-8" status={ComplianceStatus.Complete} />
          {getComplianceStatusText(ComplianceStatus.Complete)}
        </MenuItem>
        <MenuItem
          onKeyDown={(e) => (e.key === 'Enter' ? onItemClick(ComplianceStatus.InProgress) : null)}
          onClick={() => onChange(ComplianceStatus.InProgress)}
        >
          <CompliancePlip className="m-r-8" status={ComplianceStatus.InProgress} />
          {getComplianceStatusText(ComplianceStatus.InProgress)}
        </MenuItem>
        <MenuItem
          onKeyDown={(e) => (e.key === 'Enter' ? onItemClick(ComplianceStatus.NotStarted) : null)}
          onClick={() => onChange(ComplianceStatus.NotStarted)}
        >
          <CompliancePlip className="m-r-8" status={ComplianceStatus.NotStarted} />
          {getComplianceStatusText(ComplianceStatus.NotStarted)}
        </MenuItem>
        <MenuItem
          onKeyDown={(e) => (e.key === 'Enter' ? onItemClick(ComplianceStatus.NotApplicable) : null)}
          onClick={() => onChange(ComplianceStatus.NotApplicable)}
        >
          <CompliancePlip className="m-r-8" status={ComplianceStatus.NotApplicable} />
          {getComplianceStatusText(ComplianceStatus.NotApplicable)}
        </MenuItem>
      </Menu>
    </>
  );
};

export default StatusDropdown;
