import { Stack } from '@mui/material'
import GenericSelect from '../../components/GenericSelect'
import GenericSearch from '../../components/GenericSearch'
import { useState } from 'react'
import { ProgramComponentSection, ProgramFormsFilters, TrainingProgramForm } from '../../types'
import { getFullNameOrEmail } from '../../../../../redux/user'
import { TeamMember } from '../../../../../types'
import { keys } from 'remeda'

const ProgramFormFilters = (props: {
  searchValue: string;
  searchForms: (value: string) => void;
  filterForms: (filters: any) => void;
  programComponentForms: ProgramComponentSection[];
  uploadedFormIndex: {[Identifier: number]: TrainingProgramForm[]};
  teamMembers:  {[Identifier: string]: TeamMember};
}) => {

  const { searchValue, searchForms, filterForms, programComponentForms, uploadedFormIndex, teamMembers } = props

  const formNames: string[] = []
  const uploadedBy: string[] = []
  const formTeamMembers: string[] = []

  programComponentForms.forEach(({ section_form_relations }) => {
    section_form_relations.forEach(section => {
      formNames.push(section.form.name)
      if(uploadedFormIndex[section.form_id]) {
        uploadedFormIndex[section.form_id].forEach(form => {
          if(form.uploaded_by && !uploadedBy.includes(getFullNameOrEmail(teamMembers[form.uploaded_by]))) {
            uploadedBy.push(getFullNameOrEmail(teamMembers[form.uploaded_by]))
          }
          if(form.user_id && !formTeamMembers.includes(getFullNameOrEmail(teamMembers[form.user_id]))) {
            formTeamMembers.push(getFullNameOrEmail(teamMembers[form.user_id]))
          }
        })
      }
    })
  })

  const [filters, setFilters] = useState<ProgramFormsFilters>(
    {
      'Form Name': {
        options: ['None', ...formNames.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)],
        value: 'None'
      },
      'Uploaded By': {
        options: ['None', ...uploadedBy.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)],
        value: 'None'
      },
      'Team Member': {
        options: ['None', ...formTeamMembers.sort((a, b) => a.toLowerCase() < b.toLowerCase() ? -1 : 1)],
        value: 'None'
      },
      'Status': {
        options: ['None', 'Complete', 'Missing'],
        value: 'None'
      }
    }
  )

  const onChangeFilter = (value: string | number | null, filter: string) => {
    const updatedFilters = {
      ...filters,
      [filter]: {
        ...filters[filter as keyof ProgramFormsFilters],
        value
      }
    }
    filterForms(updatedFilters)
    setFilters(updatedFilters)
  }
  
  return (
    <Stack direction='row' spacing={2}>
      {keys(filters).map(filterName => {
        const filter = filters[filterName as keyof ProgramFormsFilters]
        return (
          <GenericSelect
            style={{ width: '150px' }}
            key={filterName}
            fullWidth={true}
            label={filterName}
            value={filter.value}
            onChange={(event) => onChangeFilter(event.target.value, filterName)}
            options={filter.options}
          />
        )
      })}
      <GenericSearch
        style={{ width: '150px', height: '35.69px', fontSize: '13px' }}
        value={searchValue}
        onChange={searchForms}
      />
    </Stack>
  )
}

export default ProgramFormFilters