import React from 'react';
import PracticeOshaHipaa from '../../../views/practice/dentalenhancements/OshaHipaa';
import ComponentTypeDetails, { IFrameWrapper } from '../../../views/practice/dentalenhancements/ComponentTypeDetails';
import Programs from '../../../views/practice/dentalenhancements/Programs';
import ProgramChecklist from '../../../views/practice/dentalenhancements/ProgramChecklist';
import ProgramForms from '../../../views/practice/dentalenhancements/programForms/ProgramForms';

import SRARoutes from '../../../views/practice/dentalenhancements/securityRiskAssessment/routes';

// FIXME: There's probably a cleaner way to combine the corp nested views, but exporting this is fine for now.
export const childrenDERoutes = [
  {
    index: true,
    element: <Programs />
  },
  {
    path: 'component/:componentTypeName',
    element: <ComponentTypeDetails />,
    children: [
      {
        path: 'checklists/:checklistTypeSlug',
        element: <ProgramChecklist />
      },
      {
        path: 'section/:sectionId',
        element: <IFrameWrapper />
      },
      {
        path: 'forms',
        element: <ProgramForms />
      },
      SRARoutes
    ]
  }
];

const DERoutes = [
  {
    path: '/practice/osha-hipaa/:year?',
    element: <PracticeOshaHipaa />,
    children: childrenDERoutes
  }
];

export default DERoutes;
