import React from 'react';
import OshaHipaaDashboard from '../../../views/corp/dentalenhancements/OshaHippa';

const DERoutes = [
  {
    path: '/corp/osha-hipaa',
    element: <OshaHipaaDashboard />
  }
];

export default DERoutes;
