import { DialogContent, Dialog, DialogActions, Button, DialogContentText, Typography, styled, Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import { CloudUploadOutlined, CheckCircle, Error, Close } from '@mui/icons-material';
import { useMemo, useState } from 'react';
import { green, red } from '@mui/material/colors';
import Dropzone from 'react-dropzone'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const validFileTypes = [
  'bmp',
  'csv',
  'jpeg',
  'jpg',
  'pdf',
  'png',
  'xls',
  'xlsx',
];

const Errors = {
  UploadSizeExceeded: 'The uploaded file exceeded the maxium file size. [Maximum file size is 50 MB]',
  MulitpleFileError: 'Please select only 1 file',
  InvalidFileType: `Invalid file type. Valid file types: ${validFileTypes.join(', ')}`
}

interface FileSize {
  size: string;
  unit: string;
}

const UploadFileModal = ({ open, onClose, onUpload, buttonText, uploadSuccess, multiple = false }: {
  open: boolean;
  onClose: () => void;
  onUpload: (file: File[]) => void;
  buttonText?: string;
  multiple?: boolean;
  uploadSuccess: boolean | null
}) => {
  const maxFileBytes = (50 * 1064 * 1064);
  const [files, setFiles] = useState<File[] | null>(null);
  const [uploadSize, setUploadSize] = useState<FileSize | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useMemo(() => {
    if(isUploading) {
      setIsUploading(false);
    }
  }, [uploadSuccess])

  const validateFileTypes = (files: File[]) => {
    for(let i = 0; i < files.length; i++) {
      if(!validFileTypes.includes(files[i].name.split('.')[1])) {
        return false;
      }
    }
    return true;
  }

  const handleOnChange = (files: File[] | null) => {
    setErrorMessage(null);
    if(!multiple && files && files.length > 1) {
      setErrorMessage(Errors.MulitpleFileError);
    } else {
      let validFiles = true;
      if(files) validFiles = validateFileTypes(files);
      if(validFiles) {
        setFiles(files ?? null);
        if(files) setUploadSize(getUploadSize(files));
      } else {
        setErrorMessage(Errors.InvalidFileType);
      }
    }
  }

  const getUploadSize = (files: File[]) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    let totalBytes = 0;
    files.forEach(file => { 
      totalBytes += file.size;
    });
    let size = totalBytes.toString();
    if (totalBytes === 0) return null;
    const i = Math.floor(Math.log(totalBytes) / Math.log(1024));
    if(i !== 0) size = (totalBytes / (1024 ** i)).toFixed(1);
    if(totalBytes > maxFileBytes) setErrorMessage(Errors.UploadSizeExceeded);
    return {
      size,
      unit: sizes[i]
    };
  }

  const handleUploadClick = () => {
    if(files) {
      onUpload(files);
      setIsUploading(true);
    }
  }

  const reset = () => {
    setFiles(null);
    setUploadSize(null);
    setErrorMessage(null);
  }

  const handleClose = () => {
    reset();
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogContent>
        <Dropzone
          disabled={errorMessage !== null}
          onDrop={(acceptedFiles: File[]) => {
            handleOnChange(acceptedFiles);
          }}
          multiple
        >
          {({getRootProps, getInputProps}) => (
            <Box
              {...getRootProps()}
              sx={{
                p: 2,
                border:
                '1px dashed grey',
                textAlign: 'center',
                marginBottom: '5px',
                paddingTop: '50px',
                backgroundColor: '#fafafa'
              }}>
              <Stack alignItems="center">
                {uploadSuccess === null ? <CloudUploadOutlined/> : null}
                {uploadSuccess ? <CheckCircle sx={{ color: green[500] }} /> : null}
                {uploadSuccess === false ? <Error sx={{ color: red[900] }}/> : null}
              </Stack>
              {uploadSuccess === null && !errorMessage ? <Box>
                <Box>
                  <VisuallyHiddenInput
                    {...getInputProps()}
                    type="file"
                    style={{ cursor: 'pointer' }}
                  />
                  <DialogContentText style={{ cursor: 'pointer' }}>
                    <span
                      style={{
                        textDecoration: 'underline',
                        color: 'rgba(0, 0, 0, 0.6)',
                      }}
                    >Click to upload</span> or drag and drop
                  </DialogContentText>
                </Box>
              </Box> :
                <Box
                  sx={{
                    backgroundColor: '#0086b7',
                    width: '200px',
                    margin: 'auto',
                    padding: '5px'
                  }}
                >
                  {uploadSuccess ?
                    <DialogContentText sx={{ color: 'white' }}>
                      Upload Complete
                    </DialogContentText>
                  : <DialogContentText sx={{ color: 'white' }}>
                      Upload Failed
                      <br></br>
                      Try Again
                  </DialogContentText>}
                </Box>
              }
              {errorMessage ? 
                <DialogContentText sx={{ color: red[900], fontSize: '13px' }}>{errorMessage}</DialogContentText>
                : <DialogContentText>Maximum file size 50 MB.</DialogContentText>
              }
            </Box>
          )}
        </Dropzone>  
        {files && !errorMessage ?
          <Box
            sx={{
              p: 2,
              border: '1px solid grey',
              marginTop: '10px',
              textAlign: 'left' 
            }}
          >
            <Box sx={{ position: 'relative' }}>
              {uploadSuccess === null && !isUploading ?
              <Box sx={{ position: 'absolute', right: '5px', top: '5px' }}>
                <Close onClick={reset}/>
              </Box> : null}
              <Box>
                {
                  files.map(file => (
                    <DialogContentText key={file.name}>{file.name}</DialogContentText>
                  ))
                }
                <DialogContentText>{uploadSize?.size} {uploadSize?.unit}</DialogContentText>
              </Box>
            </Box>
          </Box>
        : null}
      </DialogContent>
      <DialogActions>
        {uploadSuccess === null && !errorMessage ?
        <>
          <Button 
            disabled={isUploading}
            onClick={handleClose}>Cancel</Button>
          <Button
            disabled={files === null || isUploading || errorMessage !== null}
            variant="contained"
            onClick={handleUploadClick}
          >
            {buttonText ?? 'Upload File'}
          </Button>
        </> :
          <Button onClick={handleClose}>Close</Button>
        }
      </DialogActions>
    </Dialog>
  ); 
};

export default UploadFileModal;
