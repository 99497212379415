import { DialogContent, Dialog, DialogTitle, DialogActions, Button, DialogContentText } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';


const GenericDeleteModal = ({ open, onClose, onDelete, dialogText, deleteButtonText, hideDialogTitle }: { open: boolean; onClose: () => void; onDelete: () => void, dialogText?: string, deleteButtonText?: string, hideDialogTitle?: boolean }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {!hideDialogTitle ? <DialogTitle>Are you sure you want to delete this entry?</DialogTitle> : null}
      <DialogContent>
        <Stack alignItems='center'>
          <DeleteIcon fontSize='large' />
          <DialogContentText>{dialogText ?? 'This action cannot be undone.'}</DialogContentText>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        <Button onClick={() => onDelete()}>{deleteButtonText ?? 'Confirm'}</Button>
      </DialogActions>
    </Dialog>
  ); 
};

export default GenericDeleteModal;
