import { AccordionSummary } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { ProgramComponentSection } from '../../types'

interface AccordionSummaryWrapperPropTypes {
    section: ProgramComponentSection;
}

const AccordionSummaryWrapper = (props: AccordionSummaryWrapperPropTypes) => {
  const { section } = props
  const sectionName = section?.name ?? 'Unnamed Form'

  return (
    <>
      <AccordionSummary sx={{ fontWeight: 'bold' }} expandIcon={<ExpandMoreIcon />}>
        {sectionName}
      </AccordionSummary>
    </>
  )
}

export default AccordionSummaryWrapper
