import React from "react";
import { Button, ListItemButton, styled } from "@mui/material";


export const HFSubMenu = styled(ListItemButton)(({ theme }) => ({
    backgroundColor: '#e4eaec',
    paddingLeft: '59px',
    textTransform: 'none',
    '&:hover': {
        backgroundColor: '#F6F8F8'
    },
})) as typeof ListItemButton; // https://mui.com/material-ui/guides/typescript/#complications-with-the-component-prop

