import React, { useState } from 'react'
import { isFulfilled } from '@reduxjs/toolkit'
import { uploadForm } from '../../slice'
import { useAppDispatch } from '../../../../../redux/store'
import UploadFileModal from '../../components/UploadFileModal'

interface UploadFormModalPropTypes {
  fileUploadModalIsOpen: boolean; 
  setFileUploadModalIsOpen: (isOpen: boolean) => void;
  selectedTrainingForm: number | null;
  setSelectedTrainingForm: (id: number | null) => void;
  selectedForm: number | undefined;
  customerNumber: string;
  sectionId: number;
  programId?: number;
}

const UploadFormModal = (props: UploadFormModalPropTypes) => {
  const {
    fileUploadModalIsOpen,
    setFileUploadModalIsOpen,
    selectedTrainingForm,
    setSelectedTrainingForm,
    selectedForm,
    customerNumber,
    sectionId,
    programId
  } = props

  const dispatch = useAppDispatch()
  const [uploadSuccess, setUploadSuccess] = useState<boolean | null>(null)

  const onUpload = async (files: File[]) => {
    const file = files[0]
    if (selectedForm) {
      const payload = {
        file,
        customerNumber,
        formId: selectedForm,
        trainingFormId: selectedTrainingForm,
        sectionId,
        programId
      }
      const action = uploadForm(payload)
      const res = await dispatch(action)
      const hasFulfilled = isFulfilled(res)
      if (hasFulfilled) setUploadSuccess(res.payload.success)
    }
  }

  const onFileUploadModalClose = () => {
    setFileUploadModalIsOpen(false)
    setUploadSuccess(null)
    setSelectedTrainingForm(null)
  }

  return (
    <UploadFileModal
      open={fileUploadModalIsOpen}
      onUpload={onUpload}
      onClose={onFileUploadModalClose}
      uploadSuccess={uploadSuccess}
    />
  )    
}

export default UploadFormModal
