import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  Typography
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import cx from 'clsx';
import { match, P } from 'ts-pattern';
import { ChangeEvent, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import CompliancePlip from '../../../../components/CompliancePlip';
import { standardDateFormat } from '../../../../helpers/date';
import { selectCurrentCustomerNumber } from '../../../../redux/customer';
import { useAppDispatch, type RootState } from '../../../../redux/store';
import { ComplianceStatus } from '../../../../types';

import StatusDropdown from '../StatusDropdown';
import { ProgramComponentTypeName, TrainingChecklistTask, TrainingChecklistType } from '../types';
import { TrainingChecklistTypeSlug, checklistTypeFromSlug } from '../utils';

import {
  selectChecklistSectionComplianceById,
  selectCustomerChecklistTaskById,
  selectCustomerChecklistTaskComplianceById,
  setCustomerNotesForTask,
  updateTaskMetadata
} from '../slice';

import styles from './Checklist.module.css';

const ReferenceModal = ({ references }: { references: string }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);
  return (
    <>
      <IconButton aria-label="open reference modal" onClick={() => setIsOpen(true)}>
        <FontAwesomeIcon icon="circle-info" />
      </IconButton>
      <Dialog open={isOpen} onClose={onClose}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            padding: 0
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <DialogTitle sx={{ marginTop: 1 }}>REFERENCE</DialogTitle>
        <DialogContent className={styles.whitespace}>{references}</DialogContent>
      </Dialog>
    </>
  );
};

const NotesModal = ({ notes, setNotes }: { notes?: string; setNotes: (newNotes: string) => void; }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newNotes, setNewNotes] = useState(notes ?? '');

  const onClose = () => setIsOpen(false);

  const onChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => setNewNotes(target.value);

  const onSave = async () => {
    if (newNotes !== notes) {
      setIsSaving(true);

      try {
        await setNotes(newNotes);
      } catch (error) {
        console.error(error);
      }

      setIsSaving(false);
    }

    onClose();
  };

  return (
    <>
      <IconButton aria-label="edit notes" onClick={() => setIsOpen(true)}>
        <EditIcon fontSize="small" sx={{ cursor: 'pointer '}} />
      </IconButton>
      <Dialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            padding: 0
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <DialogTitle sx={{ marginTop: 1 }}>EDIT NOTES</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="stretch" gap={1}>
            <TextareaAutosize defaultValue={notes} minRows={3} maxRows={20} onChange={onChange} />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize={12} color="GrayText">{isSaving && 'Saving...'}</Typography>
              <Box display="flex" gap={1} justifySelf="end">
                <Button variant="outlined" size="small" onClick={onClose} disabled={isSaving}>Cancel</Button>
                <Button variant="contained" size="small" aria-label="save notes" onClick={onSave} disabled={isSaving}>Save</Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
};

const TaskRow = ({
  name,
  description,
  id,
  references,
  products,
  showNotes,
  notes,
  customer_notes,
  showProducts,
  setCustomerNotes,
  checklistType
}: TrainingChecklistTask & {
  showNotes: boolean;
  showProducts: boolean;
  setCustomerNotes?: (newNotes: string) => void;
  checklistType: TrainingChecklistType;
}) => {
  const dispatch = useAppDispatch();
  const customerNumber = useSelector(selectCurrentCustomerNumber);

  const {
    componentTypeName,
    year
  }: {
    componentTypeName?: ProgramComponentTypeName;
    year?: string;
  } = useParams();

  const metadata = useSelector((state: RootState) => selectCustomerChecklistTaskById(state, id));
  let user;
  let completedDate;

  if (metadata) {
    completedDate = metadata?.completed_at;
    if (metadata?.user?.firstname) {
      user = `${metadata.user.firstname} ${metadata.user.lastname}`;
    } else if (metadata?.user?.email) {
      user = metadata.user.email;
    }
  }

  const complianceStatus = useSelector((state: RootState) => selectCustomerChecklistTaskComplianceById(state, id));

  const saveStatus = async (status: number) => {
    if (status === complianceStatus) {
      return;
    }

    return dispatch(
      updateTaskMetadata({
        customerNumber,
        status,
        id,
        meta: {
          year,
          componentTypeName,
          checklistType
        }
      })
    );
  };

  const displayNotes = customer_notes?.trim() || notes?.trim();

  return (
    <TableRow>
      <TableCell sx={{ padding: 0 }}>
        <StatusDropdown onChange={saveStatus} value={complianceStatus} />
      </TableCell>
      <TableCell className={styles.cellWrap}>
        <div className={cx(styles.taskName, styles.whitespace)}>{name}</div>
        <p className={styles.whitespace}>{description}</p>
        <div>
          {completedDate && complianceStatus === ComplianceStatus.Complete && (
            <Box sx={{ fontStyle: 'italic' }}>
              Completed by {user} {standardDateFormat(completedDate)}
            </Box>
          )}
        </div>
      </TableCell>
      <TableCell align="center">{references && <ReferenceModal references={references} />}</TableCell>
      {showNotes && <TableCell className={styles.cellWrap}>
        <Box display="flex" justifyContent="space-between" alignItems="center" gap="0.5em">
          <p className={styles.whitespace}>{displayNotes}</p>
          {setCustomerNotes && <NotesModal notes={customer_notes || notes} setNotes={setCustomerNotes} />}
        </Box>
      </TableCell>}
      {showProducts && (
        <TableCell className={styles.cellWrap}>
          {products?.map((p) => (
            <div key={p.id}>
              {p.link ? (
                <a href={p.link} className={styles.link} target="_blank">
                  {p.name}
                </a>
              ) : (
                p.name
              )}
            </div>
          ))}
        </TableCell>
      )}
    </TableRow>
  );
};

export const Section = ({
  id,
  name,
  tasks,
  checklistType
}: {
  id: number;
  name: string;
  tasks: TrainingChecklistTask[];
  checklistType: TrainingChecklistType;
}) => {
  const status = useSelector((state: RootState) => selectChecklistSectionComplianceById(state, id));
  const customerNumber = useSelector(selectCurrentCustomerNumber);

  const dispatch = useAppDispatch();
  const setCustomerNotes = (taskId: number, newNotes: string) => dispatch(setCustomerNotesForTask({ customerNumber, taskId, newNotes }));

  const showNotes = checklistType === 'security risk assessment';
  const showProducts = checklistType !== 'security risk assessment';

  return (
    tasks.length > 0 && (
      <Accordion disableGutters data-testid="checklist-section">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <div className="flex flex-align-center">
            <CompliancePlip className="m-r-8" status={status} />
            <Typography>{name}</Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0 }}>
          <Table className={styles.table} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell width={'5%'}>Status</TableCell>
                <TableCell width={'50%'}>
                  {match(checklistType)
                    .with('checklist', () => 'Description')
                    .with(P.union('facility report', 'security risk assessment'), () => 'Question/Details')
                    .exhaustive()}
                </TableCell>
                <TableCell align="center">Reference</TableCell>
                {showNotes && <TableCell>Notes</TableCell>}
                {showProducts && <TableCell>Products</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.map((t) => (
                <TaskRow
                  key={t.id}
                  {...t}
                  showNotes={showNotes}
                  showProducts={showProducts}
                  setCustomerNotes={(newNotes) => setCustomerNotes(t.id, newNotes)}
                  checklistType={checklistType}
                />
              ))}
            </TableBody>
          </Table>
        </AccordionDetails>
      </Accordion>
    )
  );
};
