import * as R from 'remeda';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  ListItemText,
  Checkbox,
  Box,
  Chip,
  ListSubheader,
  SelectChangeEvent,
  FormHelperText
} from '@mui/material';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { User } from '../../../../../redux/user';
import { getFullNameOrEmailOrDefault } from '../../utils';

const usersToUserIdKeyedNames = (users: User[] = []): Record<string, string> =>
  users?.reduce((usersMap, u) => ({ ...usersMap, [u.user_id + '']: getFullNameOrEmailOrDefault(u) }), {} as Record<string, string>);

const userIdsToUserIdKeyedNames = (userNameMap: Record<string, string>) => (userIds: string[]) =>
  userIds.reduce(
    (userMap, id) => ({
      ...userMap,
      [id]: userNameMap[id]
    }),
    {} as Record<string, string>
  );

type OutputValue = string[];

const ACTIVE_MEMBERS_GROUP = 'Active Team Members';
const INACTIVE_MEMBERS_GROUP = 'Inactive Team Members';
const SELECT_ALL = 'select-all';

type UserGroups = typeof ACTIVE_MEMBERS_GROUP | typeof INACTIVE_MEMBERS_GROUP;

export const UserSelect = <T,>({
  label,
  value,
  onChange,
  users,
  renderValue,
  renderGroups,
  error,
  touched,
  required,
  disabled
}: {
  label: string;
  value: T;
  users: User[];
  onChange: (event: SelectChangeEvent<T>, child: ReactNode) => void;
  renderValue?: (value: T) => ReactNode;
  renderGroups?: (groups: Record<UserGroups, User[]>) => ReactNode;
  error?: string;
  touched?: boolean;
  required?: boolean;
  disabled?: boolean;
}) => {
  const userNameMap = useMemo(() => usersToUserIdKeyedNames(users), [users]);
  // FIXME: There was a requirement to show only inactive team members from the last year
  // but the table for users in ontraq DOESN'T TRACK WHEN THEY WERE MARKED INACTIVE so
  // this isn't currently possible.
  const groupedAndSortedUsers: Record<UserGroups, User[]> = useMemo(
    () =>
      R.pipe(
        users,
        R.sortBy((u) => getFullNameOrEmailOrDefault(u)),
        R.groupBy((u) => (u.account_active ? ACTIVE_MEMBERS_GROUP : INACTIVE_MEMBERS_GROUP))
      ),
    [users]
  );

  const defaultRenderValue = (value: T) => {
    if (typeof value == 'string') {
      const userName = userNameMap[value];

      return userName;
    }
    return value + '';
  };

  const defaultRenderGroups: typeof renderGroups = (groups) => {
    return [
      <ListSubheader key={ACTIVE_MEMBERS_GROUP}>{ACTIVE_MEMBERS_GROUP}</ListSubheader>,
      groups[ACTIVE_MEMBERS_GROUP]?.map((user) => (
        <MenuItem key={user.user_id} value={user.user_id + ''}>
          <ListItemText primary={userNameMap[user.user_id]} />
        </MenuItem>
      )),
      <ListSubheader key={INACTIVE_MEMBERS_GROUP}>{INACTIVE_MEMBERS_GROUP}</ListSubheader>,
      groups[INACTIVE_MEMBERS_GROUP]?.map((user) => (
        <MenuItem key={user.user_id} value={user.user_id + ''}>
          <ListItemText primary={userNameMap[user.user_id]} />
        </MenuItem>
      ))
    ];
  };

  return (
    <FormControl error={touched && !!error} required={required} disabled={disabled}>
      <InputLabel id="user-select-label">{label}</InputLabel>
      <Select
        labelId="user-select-label"
        name="users"
        value={value}
        renderValue={renderValue || defaultRenderValue}
        onChange={onChange}
        label={label}
      >
        {R.pipe(groupedAndSortedUsers, renderGroups || defaultRenderGroups)}
      </Select>
      <FormHelperText>{touched && error}</FormHelperText>
    </FormControl>
  );
};

const UserMultiSelect = ({
  users,
  value,
  onChange,
  label
}: {
  users: User[];
  value: OutputValue;
  onChange: (value: OutputValue) => void;
  label: string;
}) => {
  const [selectedUsers, setSelectedUsers] = useState<Record<string, string>>({});

  const userNameMap = useMemo(() => usersToUserIdKeyedNames(users), [users]);

  useEffect(() => {
    setSelectedUsers(userIdsToUserIdKeyedNames(userNameMap)(value));
  }, [value]);

  const onSelectedUsers = (value: typeof selectedUsers) => {
    setSelectedUsers(value);
    onChange(Object.keys(value));
  };

  const allActiveTeamMemberIds = useMemo(() => {
    return R.pipe(
      users,
      R.filter((u) => !!u.account_active),
      R.map((u) => u.user_id + '')
    );
  }, [users]);

  const allActiveTeamMembersAreSelected = useMemo(
    () => allActiveTeamMemberIds.every((id) => selectedUsers[id]),
    [allActiveTeamMemberIds, selectedUsers]
  );

  const allActiveTeamMembersSelection = useMemo(
    (): typeof selectedUsers => userIdsToUserIdKeyedNames(userNameMap)(allActiveTeamMemberIds),
    [allActiveTeamMemberIds]
  );

  const handleChange = ({ target: { value } }: SelectChangeEvent<typeof selectedUsers>) => {
    if (typeof value !== 'string') {
      // This branch should never be selected, but has to be handled
      onSelectedUsers({ ...selectedUsers, ...value });
    } else if (value === SELECT_ALL) {
      if (allActiveTeamMembersAreSelected) {
        // Deselect all active users
        onSelectedUsers(R.omit(selectedUsers, allActiveTeamMemberIds));
      } else {
        // Select all active users
        onSelectedUsers({ ...selectedUsers, ...allActiveTeamMembersSelection });
      }
    } else if (!!selectedUsers[value]) {
      // If the user is already in the selection, remove them from the selection
      const clone = { ...selectedUsers };
      delete clone[value];
      onSelectedUsers(clone);
    } else {
      // Add the user to the selection
      onSelectedUsers({
        ...selectedUsers,
        [value]: userNameMap[value]
      });
    }
  };

  return (
    <UserSelect
      label={label}
      value={selectedUsers}
      onChange={handleChange}
      renderValue={(selected) => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {Object.values(R.mapValues(selected, (label, id) => <Chip key={id} label={label} />))}
        </Box>
      )}
      users={users}
      renderGroups={(groups) => {
        return [
          <ListSubheader key={ACTIVE_MEMBERS_GROUP}>{ACTIVE_MEMBERS_GROUP}</ListSubheader>,
          <MenuItem value={SELECT_ALL} key={SELECT_ALL}>
            <Checkbox checked={allActiveTeamMembersAreSelected} />
            <ListItemText>Select All Active</ListItemText>
          </MenuItem>,
          groups[ACTIVE_MEMBERS_GROUP]?.map((user) => (
            <MenuItem key={user.user_id} value={user.user_id + ''}>
              <Checkbox checked={!!selectedUsers[user.user_id + '']} />
              <ListItemText primary={userNameMap[user.user_id]} />
            </MenuItem>
          )),
          <ListSubheader key={INACTIVE_MEMBERS_GROUP}>{INACTIVE_MEMBERS_GROUP}</ListSubheader>,
          groups[INACTIVE_MEMBERS_GROUP]?.map((user) => (
            <MenuItem key={user.user_id} value={user.user_id + ''}>
              <Checkbox checked={!!selectedUsers[user.user_id + '']} />
              <ListItemText primary={userNameMap[user.user_id]} />
            </MenuItem>
          ))
        ];
      }}
    />
  );
};

export default UserMultiSelect;
