import { FormEvent, useState } from 'react';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControlLabel, Button, DialogActions, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { RootState, useAppDispatch } from '../../../redux/store';

import { getCertificationMessage } from './utils';
import { certifyChecklistComplete, selectChecklistCertificationByChecklistId, selectChecklistIsInCompliance } from './slice';
import CloseModalButton from '../../../components/CloseModalButton';

const CertifyChecklistCompleteModal = ({ checklistId }: { checklistId: number }) => {
  const dispatch = useAppDispatch();
  const { componentTypeName, year } = useParams();
  const inCompliance = useSelector((state: RootState) => selectChecklistIsInCompliance(state, checklistId));

  const certification = useSelector((state: RootState) => selectChecklistCertificationByChecklistId(state, checklistId));

  const [isOpen, setIsOpen] = useState(false);
  const [isCertified, setIsCertified] = useState(false);

  const onClose = () => {
    setIsOpen(false);
    setIsCertified(false);
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (componentTypeName && isCertified && year) {
      dispatch(certifyChecklistComplete(checklistId));
    }

    onClose();
  };

  return (
    <>
      {certification ? (
        getCertificationMessage(certification)
      ) : (
        <Button disabled={!inCompliance} variant="contained" onClick={() => setIsOpen(true)} data-testid="certify-complete-button">
          Certify Complete
        </Button>
      )}
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle>Final Review and Sign off</DialogTitle>
        <CloseModalButton handleClose={onClose} />
        <form onSubmit={onSubmit}>
          <DialogContent>
            <p>All of the items have now been marked as "Complete" or "Not Applicable".</p>
            <p>
              To certify that you are done with this portion of your practice's annual requirements, please check the box below, and then press
              "Submit".
            </p>

            <FormControlLabel
              label="I certify our practice has completed these requirements."
              control={<Checkbox value={isCertified} required onChange={(e) => setIsCertified(e.target.checked)} />}
            />
          </DialogContent>
          <DialogActions>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default CertifyChecklistCompleteModal;
