import React, { useState } from 'react'
import { isFulfilled } from '@reduxjs/toolkit'
import { deleteUploadedForm } from '../../slice'
import { useAppDispatch } from '../../../../../redux/store'
import GenericDeleteModal from '../../securityRiskAssessment/components/GenericDeleteModal'

interface FormDeleteModalPropTypes {
  customerNumber: string;
  selectedForm: number | undefined;
  deleteModalIsOpen: boolean;
  setDeleteModalIsOpen: (isOpen: boolean) => void;
}

const FormDeleteModal = (props: FormDeleteModalPropTypes) => {
  const {
    selectedForm,
    customerNumber,
    deleteModalIsOpen,
    setDeleteModalIsOpen,
  } = props

  const dispatch = useAppDispatch()

  const onDeleteModalClose = () => setDeleteModalIsOpen(false)

  const onDeleteUploadedForm = async () => {
    const payload = { formId: selectedForm, customerNumber }
    const action = deleteUploadedForm(payload)
    const res = await dispatch(action)
    const hasFulfilled = isFulfilled(res)
    if (hasFulfilled) setDeleteModalIsOpen(false)
  }

  const buttonText = "Delete"
  const dialogText = "Are you sure you want to permanently delete the form?"

  return (
    <GenericDeleteModal
      open={deleteModalIsOpen}
      onClose={onDeleteModalClose}
      onDelete={onDeleteUploadedForm}
      dialogText={dialogText}
      deleteButtonText={buttonText}
      hideDialogTitle={true}
    />    
  )
}

export default FormDeleteModal
