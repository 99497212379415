import { ComplianceStatus } from '../types';

/**
 *
 * @param status
 * @returns a css class name reflecting the color
 */
export const complianceColor = (status: ComplianceStatus) => {
  let color = '';
  switch (status) {
    case 1:
      color = 'background-success';
      break;
    case 2:
      color = 'background-warning';
      break;
    case 3:
      color = 'background-danger';
      break;
    default:
      color = 'background-inactive';
  }
  return color;
};
