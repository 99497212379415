import { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../redux/store'
import { selectCustomerNumber } from '../../../../../redux/customer'
import { addTrainingProgramForm } from '../../slice'
import {
  ProgramForm,
  ProgramFormsFilters,
  TrainingProgramForm,
  ProgramComponentSection,
} from '../../types'
import { TeamMember } from '../../../../../types'
import * as R from 'remeda'
import CollapsibleTable from './CollapsibleTable'
import UploadFormModal from './UploadFormModal'
import TeamMemberFormModal from './TeamMemberFormModal'
import FormDeleteModal from './FormDeleteModal'
import DownloadFormsButton from './DownloadFormsButton'

interface CollapsibleTableWrapperPropTypes {
  section: ProgramComponentSection;
  formsIndex: {[Identfier: number]: TrainingProgramForm[]};
  teamMemberIndex: {[Identifier: number]: TeamMember};
  teamMembers: TeamMember[];
  filters: ProgramFormsFilters | null;
  searchValue: string;
  filterValuesSet: boolean;
  fileIdsBySection: Map<number, number[]>;
  programId?: number;
}

const CollapsibleTableWrapper = (props: CollapsibleTableWrapperPropTypes) => {
  const {
    section,
    formsIndex,
    teamMemberIndex,
    teamMembers,
    filters,
    searchValue,
    filterValuesSet,
    fileIdsBySection,
    programId
  } = props
  const rows = section?.section_form_relations
  const dispatch = useAppDispatch()
  const customerNumber = useAppSelector(selectCustomerNumber)
  const [selectedForm, setSelectedForm] = useState<number>()
  const [selectedTrainingForm, setSelectedTrainingForm] = useState<number|null>(null)
  const [selectedFormTeamMembers, setSelectedFormTeamMembers] = useState<string[]>([])
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState<boolean>(false)
  const [fileUploadModalIsOpen, setFileUploadModalIsOpen] = useState<boolean>(false)
  const [teamMemberModalIsOpen, setTeamMemberModalIsOpen] = useState<boolean>(false)

  const handleDeleteClick = (trainingProgramFormId: number) => {
    setSelectedForm(trainingProgramFormId)
    setDeleteModalIsOpen(true)
  }

  const handleAddForm = async (formId: number) => {
    const payload = { customerNumber, formId, programId, sectionId: section.section_id }
    const action = addTrainingProgramForm(payload)
    await dispatch(action)
  }

  const handleEditTeamMember = (form: ProgramForm, formTeamMembers: TeamMember[]) => {
    setSelectedForm(form.id)
    setSelectedFormTeamMembers(
      R.pipe(
        formTeamMembers,
        R.map(f => f?.login_user_id ?? ''),
      )
    )
    setTeamMemberModalIsOpen(true)
  }
  
  return (
    <>
      <DownloadFormsButton
        customerNumber={customerNumber}
        fileIdsBySection={fileIdsBySection}
        section={section}
      />
      <CollapsibleTable
        rows={rows}
        teamMemberIndex={teamMemberIndex}
        formsIndex={formsIndex}
        setFileUploadModalIsOpen={setFileUploadModalIsOpen}
        setSelectedTrainingForm={setSelectedTrainingForm}
        setSelectedForm={setSelectedForm}
        handleDeleteClick={handleDeleteClick}
        handleAddForm={handleAddForm}
        handleEditTeamMember={handleEditTeamMember}
        filters={filters}
        filterValuesSet={filterValuesSet}
        searchValue={searchValue}
        programId={programId}
        sectionId={section.section_id}
      />
      <FormDeleteModal
        selectedForm={selectedForm}
        customerNumber={customerNumber}
        deleteModalIsOpen={deleteModalIsOpen}
        setDeleteModalIsOpen={setDeleteModalIsOpen}
      />
      <UploadFormModal
        fileUploadModalIsOpen={fileUploadModalIsOpen}
        setFileUploadModalIsOpen={setFileUploadModalIsOpen}
        selectedTrainingForm={selectedTrainingForm}
        setSelectedTrainingForm={setSelectedTrainingForm}
        selectedForm={selectedForm}
        customerNumber={customerNumber}
        sectionId={section.section_id}
        programId={programId}
      />
      <TeamMemberFormModal
        teamMemberModalIsOpen={teamMemberModalIsOpen}
        setTeamMemberModalIsOpen={setTeamMemberModalIsOpen}
        selectedForm={selectedForm}
        customerNumber={customerNumber}
        teamMembers={teamMembers}
        selectedFormTeamMembers={selectedFormTeamMembers}
        programId={programId}
        sectionId={section.section_id}
      />
    </>
  )
}

export default CollapsibleTableWrapper
