import axios from 'axios';

import constants from '../../../../constants';
import { EphiTeamMembers, SRABusinessAssociate, SRAElectronicMediaInventory } from './types';
import { User } from '../../../../redux/user';

const ontraqBaseAPIURL = `${constants.ONTRAQ_API_URL}/api`;

export const getElectronicMediaInventory = (customerNumber: string, year: number) =>
  axios
    .get<SRAElectronicMediaInventory[]>(`${ontraqBaseAPIURL}/Customers/${customerNumber}/SRAElectronicMediaInventory?year=${year}`)
    .then((res) => res.data);

// FIXME: I added a year here as a future requirement has users selected by year but I didn't want to do a deep dive in this ticket
export const getUsers = (customerNumber: string, year: number) =>
  axios
    .get<User[]>(
      `${ontraqBaseAPIURL}/UsersPractices?filter=${JSON.stringify({
        include: {
          relation: 'groups',
          scope: {
            include: 'name'
          }
        },
        where: {
          customer_number: customerNumber
        }
      })}`
    )
    .then((res) => res.data);

export const updateElectronicMediaInventoryItem = (customerNumber: string, item: Partial<SRAElectronicMediaInventory>) =>
  axios.put(`${ontraqBaseAPIURL}/Customers/${customerNumber}/SRAElectronicMediaInventory/${item.id}`, item).then((res) => res.data);

export const createElectronicMediaInventoryItem = (customerNumber: string, item: Partial<SRAElectronicMediaInventory>) =>
  axios
    .post<SRAElectronicMediaInventory>(`${ontraqBaseAPIURL}/Customers/${customerNumber}/SRAElectronicMediaInventory`, item)
    .then((res) => res.data);

export const deleteElectronicMediaInventoryItem = (customerNumber: string, id: string) =>
  axios.delete(`${ontraqBaseAPIURL}/Customers/${customerNumber}/SRAElectronicMediaInventory/${id}`).then((res) => res.data);

export const getSRABusinessAssociates = async (customerNumber: string, year?: number) => {
  const { data } = await axios.get<SRABusinessAssociate[]>(`${ontraqBaseAPIURL}/Customers/${customerNumber}/SRABusinessAssociates`, {
    params: { year }
  });

  return data;
};

export const createSRABusinessAssociate = async ({ customer_number, ...businessAssociate }: Partial<SRABusinessAssociate>) => {
  const { data } = await axios.post<SRABusinessAssociate>(
    `${ontraqBaseAPIURL}/Customers/${customer_number}/SRABusinessAssociates`,
    businessAssociate
  );

  return data;
};

export const updateSRABusinessAssociate = async ({ id, customer_number, ...businessAssociate }: SRABusinessAssociate) => {
  const { data } = await axios.post<SRABusinessAssociate>(
    `${ontraqBaseAPIURL}/Customers/${customer_number}/SRABusinessAssociates/${id}`,
    businessAssociate
  );

  return data;
};

export const deleteSRABusinessAssociate = async ({ id, customer_number }: SRABusinessAssociate) => {
  await axios.delete(`${ontraqBaseAPIURL}/Customers/${customer_number}/SRABusinessAssociates/${id}`);

  return { id };
};

export const getEphiTeamMembers = (customerNumber: string, year: number) =>
  axios.get<EphiTeamMembers[]>(`${ontraqBaseAPIURL}/Customers/${customerNumber}/EphiTeamMembers?year=${year}`).then((res) => res.data);

export const deleteEphiTeamMember = (customerNumber: string, id: string) =>
  axios.delete(`${ontraqBaseAPIURL}/Customers/${customerNumber}/EphiTeamMembers/${id}`).then((res) => res.data);

export const updateEphiTeamMember = (customerNumber: string, member: Partial<EphiTeamMembers>) =>
  axios.put<EphiTeamMembers>(`${ontraqBaseAPIURL}/Customers/${customerNumber}/EphiTeamMembers/${member.id}`, member).then((res) => res.data);

export const createEphiTeamMember = (customerNumber: string, member: Partial<EphiTeamMembers>) =>
  axios.post<EphiTeamMembers>(`${ontraqBaseAPIURL}/Customers/${customerNumber}/EphiTeamMembers`, member).then((res) => res.data);
