import React from 'react';
import TrainingDashboard from '../../../views/corp/training/Training';

const trainingRoutes = [
  {
    path: '/corp/training',
    element: <TrainingDashboard />
  }
];

export default trainingRoutes;
