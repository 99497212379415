import axios from 'axios';

import constants from '../../constants';
import { SimpleFile } from '../../helpers/downloadFile';

const ontraqBaseAPIURL = `${constants.ONTRAQ_API_URL}/api`;

export const getOshaManual = (customerNumber: string, year: string): Promise<SimpleFile> =>
  axios
    .get(`${ontraqBaseAPIURL}/Customers/${customerNumber}/emanuals/${year}_OSHA_Manual`, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = URL.createObjectURL(res.data);

      return {
        url,
        fileName: `${year}_OSHA_Manual.pdf`,
        isBlobURL: true
      };
    });

export const getHipaaManual = (customerNumber: string, year: string): Promise<SimpleFile> =>
  axios
    .get(`${ontraqBaseAPIURL}/Customers/${customerNumber}/emanuals/${year}_HIPAA_Manual`, {
      responseType: 'blob'
    })
    .then((res) => {
      const url = URL.createObjectURL(res.data);

      return {
        url,
        fileName: `${year}_HIPAA_Manual.pdf`,
        isBlobURL: true
      };
    });
