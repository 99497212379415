import * as R from 'remeda';

export type RemoteData<T, E> =
  | {
      type: 'not-asked';
    }
  | {
      type: 'loading';
    }
  | {
      type: 'success';
      data: T;
    }
  | {
      type: 'failure';
      error: E;
    };

export const notAsked = <T, E>(): RemoteData<T, E> => ({ type: 'not-asked' });

export const loading = <T, E>(): RemoteData<T, E> => ({ type: 'loading' });

export const success = <T, E>(data: T): RemoteData<T, E> => ({ type: 'success', data });

export const failure = <T, E>(error: E): RemoteData<T, E> => ({ type: 'failure', error });

const _mapSuccess = <A, B, E>(remoteData: RemoteData<A, E>, predicate: (d: A) => B): RemoteData<B, E> => {
  if (remoteData.type === 'success') {
    return success(predicate(remoteData.data));
  }
  return remoteData;
};

export function mapSuccess<A, B, E>(remoteData: RemoteData<A, E>, predicate: (d: A) => B): RemoteData<B, E>;
export function mapSuccess<A, B, E>(predicate: (d: A) => B): (remoteData: RemoteData<A, E>) => RemoteData<B, E>;
export function mapSuccess() {
  return R.purry(_mapSuccess, arguments);
}

const _flatMapSuccess = <A, B, E>(remoteData: RemoteData<A, E>, predicate: (d: A) => RemoteData<B, E>): RemoteData<B, E> => {
  if (remoteData.type === 'success') {
    return predicate(remoteData.data);
  }
  return remoteData;
};

export function flatMapSuccess<A, B, E>(remoteData: RemoteData<A, E>, predicate: (d: A) => RemoteData<B, E>): RemoteData<B, E>;
export function flatMapSuccess<A, B, E>(predicate: (d: A) => RemoteData<B, E>): (remoteData: RemoteData<A, E>) => RemoteData<B, E>;
export function flatMapSuccess() {
  return R.purry(_flatMapSuccess, arguments);
}

const _unwrapOr = <T, E>(remoteData: RemoteData<T, E>, defaultValue: T): T => {
  if (remoteData.type === 'success') {
    return remoteData.data;
  }
  return defaultValue;
};

export function unwrapOr<T, E>(remoteData: RemoteData<T, E>, defaultValue: T): T;
export function unwrapOr<T, E>(defaultValue: T): (remoteData: RemoteData<T, E>) => T;
export function unwrapOr() {
  return R.purry(_unwrapOr, arguments);
}
