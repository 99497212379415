import { ComplianceStatus } from '../types';
import { AlertBox } from './AlertBoxes.styled';
import { Stack, Typography, styled, useTheme } from '@mui/material';
import { match } from 'ts-pattern';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const BorderAlertBox = styled(AlertBox)(({ theme }) => ({
  borderColor: theme.palette.colordee5e7.main,
  borderStyle: 'solid',
  borderWidth: '1px'
}));

const ComplianceAlertMessage: React.FC<{ compliance_status: ComplianceStatus; message: string }> = ({ compliance_status, message }) => {
  const theme = useTheme();
  const [status_icon, color] = match({ compliance_status })
    .with({ compliance_status: ComplianceStatus.Complete }, () => ['check', theme.palette.success.main])
    .with({ compliance_status: ComplianceStatus.InProgress }, () => ['triangle-exclamation', theme.palette.warning.main])
    .with({ compliance_status: ComplianceStatus.NotStarted }, () => ['triangle-exclamation', theme.palette.danger.main])
    .with({ compliance_status: ComplianceStatus.NotApplicable }, () => ['eye-slash', theme.palette.inactive.main])
    .otherwise(() => ['eye-slash', theme.palette.inactive.main]);

  return (
    <BorderAlertBox>
      <Stack direction="row" spacing={1} alignItems="center">
        <FontAwesomeIcon icon={status_icon as IconName} color={color}></FontAwesomeIcon>
        <Typography sx={{ color: theme.palette.color50555b.main, fontSize: '13px' }}>{message}</Typography>
      </Stack>
    </BorderAlertBox>
  );
};

export default ComplianceAlertMessage;
