import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { downloadFile } from '../../../../../helpers/downloadFile'
import DownloadFormsModal from './DownloadFormsModal'
import styles from './DownloadFormsButton.module.css'
import { ProgramComponentSection } from '../../types'
import { getTrainingForms } from '../../services'

type State = 'not-started' | 'preparing' | 'done'

interface DownloadFormButtonPropTypes {
  section: ProgramComponentSection;
  customerNumber: string;
  fileIdsBySection: Map<number, number[]>;
}

const DownloadFormsButton = (props: DownloadFormButtonPropTypes) => {
  const {
    section,
    customerNumber,
    fileIdsBySection,
  } = props

  const { year } = useParams()
  const [state, setState] = useState<State>('not-started')
  const [fileDownloadModalIsOpen, setFileDownloadModalIsOpen] = useState<boolean>(false)

  const sectionName = section?.name ?? 'Unnamed Form'
  const fileName = `${year} ${sectionName}.zip`
  const fileIds:number[] = fileIdsBySection.get(section.section_id) ?? []
  const disabled = !(Boolean(fileIds?.length) && customerNumber)

  const handleDownload = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      event.stopPropagation()
      setState('preparing')
      setFileDownloadModalIsOpen(true)
      await getTrainingForms(customerNumber, fileIds, fileName).then(downloadFile)
      setState('done')
    } catch (err) {
      setFileDownloadModalIsOpen(false)
      setState('done')
    }
  }

  const onFileDownloadModalClose = () => {
    setFileDownloadModalIsOpen(false)
  }

  const isPreparing = state === 'preparing'
  const disableButton = disabled || isPreparing
  const style = { display: 'flex', justifyContent: 'flex-end', paddingBottom: '1rem' }
  
  return (
    <div style={style}>
      <button
        disabled={disableButton}
        onClick={handleDownload}
        className={styles['download-forms-button']}
      >
        <span>Download All Uploaded Forms</span>
      </button>
      {isPreparing && (
        <DownloadFormsModal
            open={fileDownloadModalIsOpen}
            onClose={onFileDownloadModalClose}
        />
      )}
    </div>
  )
}

export default DownloadFormsButton
