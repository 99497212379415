import axios from 'axios';

import constants from '../constants';

const ontraqBaseAPIURL = `${constants.ONTRAQ_API_URL}/api`;

/**
 *
 * @returns {string} - a timezone in a short code format E.G. 'PST'
 */
export const getTimezone = () => axios.get<{ timezone: string }>(`${ontraqBaseAPIURL}/Users/timezone`).then(({ data: { timezone } }) => timezone);
