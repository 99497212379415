import { DialogContent, Dialog, DialogActions, Button, TableContainer, Table, TableHead, TableBody, Checkbox, Typography, Box, TextField, InputAdornment, IconButton, Grid, TableCell, TableRow, TableSortLabel } from '@mui/material';
import { useMemo, useState } from 'react';
import { TeamMember } from '../../../../types';
import { getFullNameOrEmail } from '../../../../redux/user';
import { Search as SearchIcon } from '@mui/icons-material';
import { StyledTableCell, StyledTableRow } from '../../../../components/Table.styled';

type Order = 'asc' | 'desc';

const TeamMemberModal = ({ open, onClose, onSave, teamMembers, savedTeamMembers }: {
  open: boolean;
  onClose: () => void;
  onSave: (teamMembers: {[Identifier: number]: boolean}) => void;
  teamMembers: TeamMember[];
  savedTeamMembers: string[];
}) => {
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<{[Identifier: string]: boolean}>({});
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<Order>('desc');
  const activeTeamMembers = teamMembers.filter(member => member.account_active);
  const inactiveTeamMembers = teamMembers.filter(member => !member.account_active);

  const sortTableData = () => {
    setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
  }

  useMemo(() => {
    setSelectedTeamMembers(savedTeamMembers.reduce((a, userId) => ({ ...a, [userId]: true }), {}))
  }, [savedTeamMembers])

  const handleChange = (checked: boolean, userId: string) => {
    setSelectedTeamMembers({...selectedTeamMembers, [userId]: checked });
  };

  const searchTeamMembers = (searchInput: string) => {
    setSearchValue(searchInput);
  }

  const handleClose = () => {
    onClose();
    setSelectedTeamMembers({});
    setSearchValue('');
  }

  const handleSave = () => {
    const members = selectedTeamMembers;
    Object.keys(selectedTeamMembers).forEach((userId: string) => {
      if(!selectedTeamMembers[userId] && !savedTeamMembers.includes(userId)) {
        delete members[userId];
      }
    })
    onSave(members);
    handleClose();
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: '25px', paddingBottom: 0 }}
        >
          <Grid item xs={6}>
            <Typography>Select Team Member</Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                searchTeamMembers(event.target.value);
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <DialogContent>
        <TableContainer sx={{ maxWidth: '550px', height: '450px' }}>
        {[activeTeamMembers, inactiveTeamMembers].map((memberList, index) => (
          <Table size="small" key={index}>
            <TableHead>
              {index === 0 ? <StyledTableRow>
                <StyledTableCell align="center">Checkbox</StyledTableCell>
                <StyledTableCell align="left">
                  <TableSortLabel
                    active
                    direction={sortDirection}
                    onClick={sortTableData}
                  >
                    Name
                  </TableSortLabel>
                </StyledTableCell>
              </StyledTableRow> : null}
              <TableRow>
                <TableCell style={{ border: 0 }}>{index === 0 ? 'Active' : 'Inactive'} Team Members</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
                {memberList
                .filter(member => {
                  return searchValue === '' ? member : getFullNameOrEmail(member).toLocaleLowerCase().includes(searchValue.toLowerCase());
                })
                .sort((a, b) => {
                  const nameA = getFullNameOrEmail(a).toLowerCase();
                  const nameB = getFullNameOrEmail(b).toLowerCase();
                  if(sortDirection === 'asc') {
                    return nameA < nameB ? -1 : 1;
                  } else {
                    return nameA < nameB ? 1 : -1;
                  }
                })
                .map((member) => 
                  {
                    return (
                      <StyledTableRow
                        key={member.login_user_id}
                      >
                        <StyledTableCell align="center" style={{ width: index === 1 ? '230px' : 'auto'}}>
                          <Checkbox
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.checked, member.login_user_id)}
                            checked={selectedTeamMembers[member.login_user_id] ?? false}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="left">{getFullNameOrEmail(member)}</StyledTableCell>
                      </StyledTableRow>
                    )
                  }
                )}
              </TableBody>
            </Table>
            ))}
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TeamMemberModal;
