import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Collapse, CssBaseline, ListSubheader } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CorporateSideBar.css';
import { infectionControlIcon, medicationIcon, oshaHipaaIcon } from '../icons';
import constants from '../constants';
import { useNavigate } from 'react-router-dom';
import { HFSubMenu } from '../themes/components/ListItemButton';
import { useAppSelector } from '../redux/store';
import { UserAccessLevels, selectCurrentUser } from '../redux/user';
import { getEPayLink } from '../services/epayService';
import { selectCustomerNumber } from '../redux/customer';
import { ErrorNotify } from './Notify.styled';

export default function CorporateSidebar() {
  const [drawerName, setOpen] = React.useState('');
  const [alertShow, setAlertShow] = React.useState(false);
  const navigate = useNavigate();
  const drawerWidth = 220;

  const currentUser = useAppSelector(selectCurrentUser);
  const customerNumber = useAppSelector(selectCustomerNumber);

  const handleDropdown = (name?: string) => {
    if (name === drawerName) {
      setOpen('');
    } else {
      setOpen(name || '');
    }
  };

  const hasPermission = (permissions: UserAccessLevels[]): boolean => permissions.some((perm) => perm === currentUser?.access_level);
  const openLinkToEPayPayments = async (): Promise<void> => {
    try {
      const link = await getEPayLink(customerNumber, 'payments');
      window.open(link, '_blank');
    } catch {
      setAlertShow(true);
    }
  };
  const handleAlertDismiss = () => setAlertShow(false);

  return (
    <Box sx={{ display: 'flex' }}>
      <ErrorNotify open={alertShow} onClose={() => handleAlertDismiss()} />
      <CssBaseline />
      <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' }
          }}
          open
        >
          <List subheader={<ListSubheader>Organization</ListSubheader>}>
            <ListItem key="Dashboard" disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/dashboard`}>
                <FontAwesomeIcon icon="gauge-high" className="sidebar-icon" />
                <ListItemText primary="Dashboard" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            {hasPermission([UserAccessLevels.Admin, UserAccessLevels.Owner]) && (
              <ListItem key="Pay Your Balance" disablePadding>
                <ListItemButton onClick={() => openLinkToEPayPayments()}>
                  <FontAwesomeIcon icon={['far', 'credit-card']} className="sidebar-icon" />
                  <ListItemText primary="Pay Your Balance" disableTypography className="sidebar-text" />
                  <FontAwesomeIcon icon="circle-info" className="gray-icon" />
                </ListItemButton>
              </ListItem>
            )}
            <ListItem key="Practices" disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practices`}>
                <FontAwesomeIcon icon="building" className="sidebar-icon" />
                <ListItemText primary="Practices" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Groups" disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/groups`}>
                <FontAwesomeIcon icon="users" className="sidebar-icon" />
                <ListItemText primary="Groups" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Team Members" disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/team-members`}>
                <FontAwesomeIcon icon="user" className="sidebar-icon" />
                <ListItemText primary="Team Members" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List subheader={<ListSubheader>Compliance Reports</ListSubheader>}>
            <ListItem key="OSHA/HIPAA" disablePadding>
              <ListItemButton
                component="a"
                onClick={() => {
                  navigate('/corp/osha-hipaa');
                }}
              >
                <div className="alt-icon">
                  <img src={oshaHipaaIcon} alt="" />
                </div>
                <ListItemText primary="OSHA/HIPAA" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Training" disablePadding>
              <ListItemButton
                component="a"
                onClick={() => {
                  navigate('/corp/training');
                }}
              >
                <FontAwesomeIcon icon="graduation-cap" className="sidebar-icon" />
                <ListItemText primary="Training" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
            <ListItem key="Meds & Devices" disablePadding>
              <ListItemButton onClick={() => handleDropdown('meds')}>
                <div className="alt-icon">
                  <img src={medicationIcon} />
                </div>
                <ListItemText primary="Meds & Devices" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'meds' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'meds'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/medications`}>
                  Medications
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/devices`}>
                  Devices
                </HFSubMenu>
              </List>
            </Collapse>
            <ListItem key="Infection Control" disablePadding>
              <ListItemButton onClick={() => handleDropdown('infection')}>
                <div className="alt-icon">
                  <img src={infectionControlIcon} />
                </div>
                <ListItemText primary="Infection Control" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'infection' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'infection'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/biological/calendar`}>
                  Biological Monitoring
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/waterline-management/calendar`}>
                  Waterline Management
                </HFSubMenu>
              </List>
            </Collapse>
            <ListItem key="Waste Management" disablePadding>
              <ListItemButton onClick={() => handleDropdown('waste')}>
                <FontAwesomeIcon icon="earth-americas" size="lg" className="sidebar-icon" />
                <ListItemText primary="Waste Management" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'waste' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'waste'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/amalgam-containers`}>
                  Amalgam Waste
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/pharma-recovery`}>
                  Pharmaceutical Waste
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/medicalwaste`}>
                  Sharps & Medical Waste
                </HFSubMenu>
              </List>
            </Collapse>
          </List>
          <Divider />
          <List>
            <ListItem key="Store" disablePadding>
              <ListItemButton component="a" target="_blank" href={constants.HEALTHFIRST_WEB_URL}>
                <FontAwesomeIcon icon="cart-shopping" className="sidebar-icon" />
                <ListItemText primary="Store" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List subheader={<ListSubheader>Documents</ListSubheader>}>
            <ListItem key="SDS" disablePadding>
              <ListItemButton component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/sds`}>
                <FontAwesomeIcon icon={['far', 'folder-open']} className="sidebar-icon" />
                <ListItemText primary="SDS" disableTypography className="sidebar-text" />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem key="Help Center" disablePadding>
              <ListItemButton onClick={() => handleDropdown('help')}>
                <FontAwesomeIcon icon="circle-question" className="sidebar-icon" />
                <ListItemText primary="Help Center" disableTypography className="sidebar-text" />
                <FontAwesomeIcon icon={drawerName === 'help' ? 'chevron-down' : 'chevron-right'} size="xs" className="arrow-icon" />
              </ListItemButton>
            </ListItem>
            <Collapse in={drawerName === 'help'}>
              <List component="div" disablePadding>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/contact`}>
                  Contact Us
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/faq`}>
                  FAQ
                </HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/terms`}>
                  Terms & Condition
                </HFSubMenu>
                <HFSubMenu>Take the Tour</HFSubMenu>
                <HFSubMenu component="a" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corp/guides`}>
                  Video Guides
                </HFSubMenu>
              </List>
            </Collapse>
          </List>
        </Drawer>
      </Box>
    </Box>
  );
}
