import axios from 'axios';
import constants from '../../../constants';
import { ChildCustomerDetails } from '../../../types';

export const getCorpChildren = async (customerNumber: string): Promise<ChildCustomerDetails[]> => {
  const query = {
    include: {
      relation: 'children',
      scope: {
        order: 'name',
        where: {
          inactive: false
        },
        include: {
          relation: 'customer_osha_hipaa_metadata'
        }
      }
    }
  };

  return await axios
    .get<{ children: ChildCustomerDetails[] }>(`${constants.ONTRAQ_API_URL}/api/Customers/${customerNumber}?filter=${JSON.stringify(query)}`)
    .then((res) => res.data.children)
    .catch((error) => {
      console.error(error);
      return [];
    });
};
