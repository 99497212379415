import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { format } from 'date-fns';
import LogoPDFSVG from './LogoPDFSVG';
import LogoMarkPDFSVG from './LogoMarkPDFSVG';

import {
  PracticeInfo,
} from '../types';

const COMPLIANCE_BLUE = '#0084b7';
const HEALTHFIRST_PHONE_NUMBER = '941-587-2864';
const DATE_FORMAT = 'MM/dd/yyyy';

const styles = StyleSheet.create({
  centeredMiddle: {
    flex: 2,
    textAlign: 'center'
  },
  flexItem: {
    flex: 1
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '0.25in'
  },
  healthFirstContact: {
    fontWeight: 'bold',
    fontSize: '14pt',
    color: COMPLIANCE_BLUE
  },
  page: {
    fontFamily: 'Open Sans',
    fontSize: '10pt'
  },
  pageHeading: {
    fontWeight: 'bold',
    fontSize: '20pt'
  },
  pageNumber: {
    fontWeight: 'bold'
  },
  practiceInfo: {
    fontWeight: 'bold'
  }
});

interface HeaderProps {
  practiceInfo: PracticeInfo;
  title: string;
  year: string;
}

export const Header = ({ practiceInfo, title, year }: HeaderProps) => (
  <View fixed style={[styles.flexRow, styles.page]}>
    <View style={[styles.flexItem, styles.practiceInfo]}>
      <Text>{practiceInfo.name?.trim()}</Text>
      <Text>{practiceInfo.doctor_name?.trim()}</Text>
      <Text>{practiceInfo.street?.trim()}</Text>
      <Text>{practiceInfo.street2?.trim()}</Text>
      <Text>
        {practiceInfo.city?.trim()}, {practiceInfo.state?.trim()} {practiceInfo.zip?.trim()}
      </Text>
    </View>
    <View style={styles.centeredMiddle}>
      <Text style={styles.pageHeading}>
        {year} {title}
      </Text>
      <Text>Report generated: {format(new Date(), DATE_FORMAT)}</Text>
    </View>
    <View style={styles.flexItem}>
      <LogoPDFSVG />
    </View>
  </View>
);

export const Footer = () => (
  <View fixed>
    <View style={styles.flexRow}>
      <View style={[styles.flexItem, { display: 'flex', flexDirection: 'row', alignItems: 'center' }]}>
        <LogoMarkPDFSVG width={30} style={{ margin: '10pt 10pt 10pt 0' }} />
        <View style={{ fontWeight: 'bold' }}>
          <Text>Generated By OnTraq</Text>
          <Text>HealthFirst.com/OnTraq</Text>
        </View>
      </View>
      <View style={styles.centeredMiddle}>
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`} />
      </View>
      <View style={[styles.flexItem, styles.healthFirstContact, { textAlign: 'right' }]}>
        <Text style={{ letterSpacing: 2 }}>{HEALTHFIRST_PHONE_NUMBER}</Text>
        <Text>Healthfirst.com</Text>
      </View>
    </View>
  </View>
);