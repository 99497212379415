import React, { useState } from 'react'
import { downloadFile, SimpleFile } from '../../../../../helpers/downloadFile'
import { TrainingProgramForm } from '../../types'
import HighlightedSearchText from './HighlightedSearchText'
import { TeamMember } from '../../../../../types'
import { getFileName } from '../../utils'

type State = 'not-started' | 'preparing' | 'done'

interface DownloadFormLinkPropTypes {
  customerNumber: string;
  getFileHandler: (customerNumber: string, fileId: number | null, fileName: string) => Promise<SimpleFile>;
  item: TrainingProgramForm;
  searchValue: string;
  teamMember: TeamMember | null;
}

const DownloadFormLink = (props: DownloadFormLinkPropTypes) => {
  const {
    item,
    teamMember,
    searchValue,
    customerNumber,
    getFileHandler,
  } = props

  const [state, setState] = useState<State>('not-started')
  const fileId = item?.file_id
  const fileName = getFileName(teamMember, item)

  const handleDownload = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      event.stopPropagation()
      setState('preparing')
      await getFileHandler(customerNumber, fileId, fileName).then(downloadFile)
      setState('done')
    } catch (err) {
      setState('done')
    }
  }
  
  return (
    <span onClick={handleDownload}>
      <HighlightedSearchText
        searchValue={searchValue}
        text={item?.file_api_uploads?.display_name}
        style={{
          fontSize: '13px',
          cursor: 'pointer',
          color:'#0086b7',
          textDecoration:'underline',
          display: 'inline-block',
          width: '100px',
          verticalAlign: 'middle'
        }}
      />
    </span>
  )
}

export default DownloadFormLink
