import { useCallback, useEffect, useMemo, useState } from 'react';
import * as R from 'remeda';
import { isFulfilled } from '@reduxjs/toolkit';
import { Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { pdf } from '@react-pdf/renderer';

import { deleteEphiTeamMember, fetchEphiTeamMembers, selectEphiTeamMembersWithUsers, selectUnassignedEphiUsers } from './slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import DataTable, { DataColumn } from '../../../../components/DataTable';
import { getFullNameOrEmailOrDefault } from '../utils';
import { mapSuccess, unwrapOr } from '../../../../helpers/RemoteData';
import GenericDeleteModal from './components/GenericDeleteModal';
import constants from '../../../../constants';
import AddEditPHIHandlersModal from './components/AddEditPHIHandlersModal';
import { EphiTeamMembers } from './types';
import { downloadFile } from '../../../../helpers/downloadFile';
import { selectCorporateView, selectCurrentCustomerNumber } from '../../../../redux/customer';
import EphiTeamMembersPDF from '../components/EphiTeamMembersPDF';
import { selectPracticeInfo } from '../slice';
import { useParams } from 'react-router-dom';

export const PHIHandlers = () => {
  const { componentTypeName, year } = useParams();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const ephiTeamMembersState = useAppSelector(selectEphiTeamMembersWithUsers);
  const unassignedUsers = useAppSelector(selectUnassignedEphiUsers);
  const corporateView = useAppSelector(selectCorporateView);
  const customerNumber = useAppSelector(selectCurrentCustomerNumber);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState<string>();
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [itemBeingEdited, setItemBeingEdited] = useState<EphiTeamMembers>();

  const [exportButtonState, setExportButtonState] = useState<'loading' | 'done' | 'not-started'>('not-started');

  const practiceInfo = useAppSelector(selectPracticeInfo);

  useEffect(() => {
    if (ephiTeamMembersState.type === 'not-asked') {
      dispatch(fetchEphiTeamMembers());
    }
  }, []);

  type EPHIColumnKeys = 'id' | 'teamMemberName' | 'hasPhi';

  const EPHITableColumns: DataColumn<EPHIColumnKeys>[] = [
    {
      key: 'teamMemberName',
      isSortable: true,
      label: 'Team Member Name'
    },
    {
      key: 'hasPhi',
      isSortable: true,
      label: 'Handles ePHI'
    }
  ];

  const tableItems: Record<EPHIColumnKeys, string>[] = useMemo(
    () =>
      R.pipe(
        ephiTeamMembersState,
        mapSuccess(R.values),
        mapSuccess(
          R.map((member) => ({
            id: member.id + '',
            teamMemberName: getFullNameOrEmailOrDefault(member.user),
            hasPhi: member.has_phi ? 'Yes' : 'No'
          }))
        ),
        unwrapOr([] as Record<EPHIColumnKeys, string>[])
      ),
    [ephiTeamMembersState]
  );

  const openEditModal = (id?: string) => {
    setEditModalIsOpen(true);
    if (id) {
      setItemBeingEdited(
        unwrapOr(
          mapSuccess(ephiTeamMembersState, (members) => members[id]),
          undefined
        )
      );
    }
  };

  const onEditModalClose = () => {
    setEditModalIsOpen(false);
    setItemBeingEdited(undefined);
  };

  const openDeleteModal = (id: string) => {
    setDeleteModalIsOpen(true);
    setIdToDelete(id);
  };

  const onDeleteModalClose = () => {
    setDeleteModalIsOpen(false);
    setIdToDelete(undefined);
  };

  const onDelete = async () => {
    if (!idToDelete) {
      onDeleteModalClose();
      return;
    }
    const res = await dispatch(deleteEphiTeamMember(idToDelete));

    if (isFulfilled(res)) {
      onDeleteModalClose();
    }
  };

  const downloadPDF = useCallback(async () => {
    const title = `${componentTypeName} Risk Assessment Report`;

    setExportButtonState('loading');

    const renderedPdf = await pdf(
      <EphiTeamMembersPDF
        ephiTeamMembers={tableItems}
        practiceInfo={practiceInfo}
        title={title}
        year={year}
      />
    ).toBlob();

    const url = URL.createObjectURL(renderedPdf);

    await downloadFile({
      url,
      isBlobURL: true,
      fileName: `${year} ${practiceInfo?.name} ${title}.pdf`
    });

    setExportButtonState('done');
  }, [tableItems, practiceInfo, year]);

  return (
    <div style={{ color: theme.palette.color50555b.main }}>
      <h2>Team Members Who Handle ePHI</h2>
      <Typography sx={{ mb: 1 }}>
        Please list all of your Team Members who handle patient protected health information during the course of their job. All team members who had
        access in the current year must be included even if they no longer work at the practice.
      </Typography>
      <Typography sx={{ mb: 1 }}>
        If your team member is not showing up in the tool when you try to add a team member, you will need to add them in the OnTraq "Team Members"
        module.
        <br />
        <Link color="inherit" href={corporateView ? `${constants.ONTRAQ_WEB_URL}/webapp/#/corp/practice/${customerNumber}/team-members` : `${constants.ONTRAQ_WEB_URL}/webapp/#/practice/team-members`}>
          Click Here
        </Link>
        {' '}to visit the Team Members module.
      </Typography>
      <Stack direction="row" sx={{ padding: '1em 0' }} justifyContent="end">
        <Button variant="contained" onClick={() => openEditModal()} disabled={unassignedUsers.length == 0}>
          Add Team Member
        </Button>
        <Button variant="contained" className="m-l-16" onClick={() => downloadPDF()} disabled={exportButtonState == 'loading'}>
            {exportButtonState === 'loading' ? 'Exporting...' : 'Export'}
        </Button>
      </Stack>
      <DataTable
        columns={EPHITableColumns}
        items={tableItems}
        isLoading={ephiTeamMembersState.type === 'loading' || ephiTeamMembersState.type === 'not-asked'}
        onEdit={openEditModal}
        onDelete={openDeleteModal}
      />
      <GenericDeleteModal open={deleteModalIsOpen} onClose={onDeleteModalClose} onDelete={onDelete} />
      <AddEditPHIHandlersModal isOpen={editModalIsOpen} onClose={onEditModalClose} item={itemBeingEdited} />
    </div>
  );
};
export default PHIHandlers;
