import { useSelector } from 'react-redux';
import { selectCustomerNumber } from '../../redux/customer';
import { useParams } from 'react-router-dom';

export const useYearAndCustomerNumber = (): { year: string; customerNumber?: string } => {
  const selectedCustomerNumber = useSelector(selectCustomerNumber);

  const { year: routeYear, customerNumber: routeCustomerNumber } = useParams();

  const year = routeYear ?? '2024';

  const customerNumber = routeCustomerNumber ?? selectedCustomerNumber;

  return { year, customerNumber };
};
