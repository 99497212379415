import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material"
import { omit, uniq } from "remeda"

const GenericSelect = (props: {
  label: string | number | null,
  value: string | number | null,
  onChange: (event: SelectChangeEvent<string | number | null>) => void,
  options: any[],
  optionProp?: string | number,
  fullWidth?: boolean,
  style: any
}) => {
  
  const { label, options, optionProp, fullWidth = false, style = {} } = props

  return (
    <FormControl fullWidth={fullWidth} size='small'>
      <InputLabel
        style={{
          fontSize: '13px',
          top: '-4px'
        }}
      >
        {label}
      </InputLabel>
      <Select
        {...omit(props, ['optionProp'])}
        sx={{ ...style, fontSize: '13px' }}
        input={<OutlinedInput sx={{ fontSize: '13px' }}/>}
      >
        {uniq(options).map((o: any) => {
          const option = optionProp ? o[optionProp] : o;
          return (
            <MenuItem key={option} value={option}>{option}</MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default GenericSelect