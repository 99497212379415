import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Link, Stack, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { pdf } from '@react-pdf/renderer';
import { useAppSelector } from "../../../../redux/store";
import { selectPracticeInfo } from "../slice";
import theme from "../../../../themes/theme";
import { downloadFile } from "../../../../helpers/downloadFile";
import PracticeInformationPDF from "../components/PracticeInformationPDF";

const EmailLink = ({ children: email }: { children: string; }) => <Link href={`mailto:${email}`}>{email}</Link>;
const PhoneLink = ({ children: phone }: { children: string; }) => <Link href={`tel:${phone}`}>{phone}</Link>;

export default () => {
  const practiceInfo = useAppSelector(selectPracticeInfo);
  const { componentTypeName, year } = useParams();
  const [exportButtonState, setExportButtonState] = useState<'loading' | 'done' | 'not-started'>('not-started');

  if (!practiceInfo) {
    return null;
  }

  const downloadPDF = useCallback(async () => {
    const title = `${componentTypeName} Risk Assessment Report`;

    setExportButtonState('loading');

    const renderedPdf = await pdf(
      <PracticeInformationPDF
        practiceInfo={practiceInfo}
        title={title}
        year={year}
      />
    ).toBlob();

    const url = URL.createObjectURL(renderedPdf);

    await downloadFile({
      url,
      isBlobURL: true,
      fileName: `${year} ${practiceInfo?.name} ${title}.pdf`
    });

    setExportButtonState('done');
  }, [practiceInfo, year]);

  return (
    <div style={{ color: theme.palette.color50555b.main }}>
      <h2>Practice Information</h2>
      <Typography>
        Please confirm that the information listed below is correct. If you need to make changes to your address or contact information, please email us at <EmailLink>customerservice@healthfirst.com</EmailLink> or call us at <PhoneLink>800-331-1984</PhoneLink>.
      </Typography>
      <Stack direction="row" sx={{ padding: '1em 0' }} justifyContent="end">
        <Button variant="contained" className="m-l-16" onClick={() => downloadPDF()} disabled={exportButtonState == 'loading'}>
            {exportButtonState === 'loading' ? 'Exporting...' : 'Export'}
        </Button>
      </Stack>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>Practice Name</TableCell>
            <TableCell>{practiceInfo.name.trim()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Doctor Name</TableCell>
            <TableCell>{practiceInfo.doctor_name.trim()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address 1</TableCell>
            <TableCell>{practiceInfo.street.trim()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address 2</TableCell>
            <TableCell>{practiceInfo.street2.trim()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>City, State, ZIP</TableCell>
            <TableCell>{practiceInfo.city.trim()}, {practiceInfo.state.trim()}, {practiceInfo.zip.trim()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Office Phone</TableCell>
            <TableCell>{practiceInfo.phone.trim()}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
