import { TableRow } from "@mui/material"
import { ProgramForm, ProgramFormsFilters, TrainingProgramForm } from "../../types"
import { TeamMember } from "../../../../../types"
import { useAppSelector } from "../../../../../redux/store"
import { selectCustomerNumber } from "../../../../../redux/customer"
import FormNameCell from "./FormNameCell"
import UploadButtonCell from "./UploadButtonCell"
import StatusCell from "./StatusCell"
import UploadedFormCell from "./UploadedFormCell"
import CollapseButtonCell from "./CollapseButtonCell"
import TeamMemberCell from "./TeamMemberCell"
import UploadedByCell from "./UploadedByCell"
import ExemptCell from "./ExemptCell"

const tableCellStyles = {
  borderBottom: 'unset',
  wordBreak: 'break-word'
}

const TrainingProgramFormRow = (props: {
  trainingProgramForm: TrainingProgramForm,
  colWidths: number[],
  form: ProgramForm,
  setFileUploadModalIsOpen: (open: boolean) => void,
  setSelectedForm: (id: number) => void,
  setSelectedTrainingForm: (id: number) => void,
  handleDeleteClick: (id: number) => void,
  teamMemberIndex: {[Identifier: string]: TeamMember},
  isAdminOrOwner: boolean,
  isCollapsedRow: boolean,
  handleEditTeamMember: (form: ProgramForm, teamMembers: TeamMember[]) => void,
  formTeamMembers: TeamMember[]
  uploadedCount: number,
  setOpen: (open: boolean) => void,
  open: boolean,
  filters: ProgramFormsFilters | null,
  searchValue: string,
  isMultipleUploads: boolean,
  filterValuesSet: boolean,
  index: number,
  programId?: number,
  sectionId: number
}) => {

  const {
    isCollapsedRow,
    filters,
    searchValue,
    isMultipleUploads,
    index,
    programId,
    sectionId
  } = props
  const customerNumber = useAppSelector(selectCustomerNumber)
  
  return (
    <TableRow style={{ borderTop: '1px solid rgba(224, 224, 224, 1)' }}>
      <FormNameCell
        {...props}
        isCollapsedCell={isCollapsedRow}
        colIndex={0}
        customerNumber={customerNumber}
        tableCellStyles={tableCellStyles}
        filter={filters ? filters['Form Name'] : null}
        searchValue={searchValue}
        isMultipleUploads={isMultipleUploads}
        index={index}
        programId={programId}
        sectionId={sectionId}
      />
      <UploadButtonCell
        {...props}
        isCollapsedCell={isCollapsedRow}
        colIndex={1}
        tableCellStyles={tableCellStyles}
      />
      <UploadedByCell
        {...props}
        isCollapsedCell={isCollapsedRow}
        colIndex={2}
        tableCellStyles={tableCellStyles}
        filter={filters ? filters['Uploaded By'] : null}
        searchValue={searchValue}
      />
      <TeamMemberCell
        {...props}
        isCollapsedCell={isCollapsedRow}
        colIndex={3}
        tableCellStyles={tableCellStyles}
        filter={filters ? filters['Team Member'] : null}
        searchValue={searchValue}
        sectionId={sectionId}
      /> 
      <StatusCell
        {...props}
        isCollapsedCell={isCollapsedRow}
        colIndex={4}
        tableCellStyles={tableCellStyles}
        filter={filters ? filters['Status'] : null}
        searchValue={searchValue}
      />
      <UploadedFormCell
        {...props}
        colIndex={5}
        tableCellStyles={tableCellStyles}
        isCollapsedCell={isCollapsedRow}
        searchValue={searchValue}
      />
      <ExemptCell
        {...props}
        colIndex={6}
        tableCellStyles={tableCellStyles}
        isCollapsedCell={isCollapsedRow}
        programId={programId}
        sectionId={sectionId}
      />
      <CollapseButtonCell
        {...props}
        colIndex={7}
        tableCellStyles={tableCellStyles}
        isCollapsedCell={isCollapsedRow}
      />
    </TableRow>
  )
}

export default TrainingProgramFormRow