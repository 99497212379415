const constants = {
  DE_API_URL: window.ONTRAQ_CONFIG?.DE_API_URL || process.env.REACT_APP_DE_API_URL,
  ESTORE_LINK: window.ONTRAQ_CONFIG?.ESTORE_LINK || process.env.REACT_APP_ESTORE_LINK,
  ENV: window.ONTRAQ_CONFIG?.ENV || process.env.REACT_APP_ENV,
  HEALTHFIRST_WEB_URL: window.ONTRAQ_CONFIG?.HEALTHFIRST_WEB_URL || process.env.REACT_APP_HEALTHFIRST_WEB_URL,
  HEALTHFIRST_PHONE_NUMBER: window.ONTRAQ_CONFIG?.HEALTHFIRST_PHONE_NUMBER || process.env.REACT_APP_HEALTHFIRST_PHONE_NUMBER,
  HEALTHFIRST_EMAIL: window.ONTRAQ_CONFIG?.HEALTHFIRST_EMAIL || process.env.REACT_APP_HEALTHFIRST_EMAIL,
  LIVE_CHAT_LICENSE: window.ONTRAQ_CONFIG?.LIVE_CHAT_LICENSE || process.env.REACT_APP_LIVE_CHAT_LICENSE,
  LIVE_CHAT_GROUP: window.ONTRAQ_CONFIG?.LIVE_CHAT_GROUP || process.env.REACT_APP_LIVE_CHAT_GROUP,
  LOGIN_URL: window.ONTRAQ_CONFIG?.LOGIN_URL || process.env.REACT_APP_LOGIN_URL,
  ONTRAQ_API_URL: window.ONTRAQ_CONFIG?.ONTRAQ_API_URL || process.env.REACT_APP_ONTRAQ_API_URL,
  ONTRAQ_WEB_URL: window.ONTRAQ_CONFIG?.ONTRAQ_WEB_URL || process.env.REACT_APP_ONTRAQ_WEB_URL,
  DE_WEB_URL: window.ONTRAQ_CONFIG?.DE_WEB_URL || process.env.REACT_APP_DE_WEB_URL,
  OSHA_HIPAA_TRAINING_SALES_PAGE_URL:
    window.ONTRAQ_CONFIG?.OSHA_HIPAA_TRAINING_SALES_PAGE_URL || process.env.REACT_APP_OSHA_HIPAA_TRAINING_SALES_PAGE_URL,
  OSHA_HIPAA_TRAINING_SALES_PAGE_IFRAME_URL:
    window.ONTRAQ_CONFIG?.OSHA_HIPAA_TRAINING_SALES_PAGE_IFRAME_URL || process.env.REACT_APP_OSHA_HIPAA_TRAINING_SALES_PAGE_IFRAME_URL
};

export default constants;
