import React from 'react'
import * as R from 'remeda'
import { TrainingProgramFormUserPayload } from '../../types'
import { updateTrainingProgramFormUsers } from '../../slice'
import { useAppDispatch } from '../../../../../redux/store'
import { TeamMember } from '../../../../../types'
import TeamMemberModal from '../../components/TeamMemberModal'

interface TeamMemberFormModalPropTypes {
  teamMemberModalIsOpen: boolean;
  setTeamMemberModalIsOpen: (isOpen: boolean) => void;
  selectedForm: number | undefined;
  customerNumber: string;
  teamMembers: TeamMember[];
  selectedFormTeamMembers: string[];
  programId?: number;
  sectionId: number;
}

const TeamMemberFormModal = (props: TeamMemberFormModalPropTypes) => {
  const {
    teamMemberModalIsOpen,
    setTeamMemberModalIsOpen,
    selectedForm,
    customerNumber,
    teamMembers,
    selectedFormTeamMembers,
    programId,
    sectionId
  } = props

  const dispatch = useAppDispatch()

  const onTeamMemberModalClose = () => setTeamMemberModalIsOpen(false)

  const handleSaveTeamMember = async (checkedTeamMembers: {[Identifier: string]: boolean}) => {
    const trainingProgramForms: TrainingProgramFormUserPayload[] = R.keys(checkedTeamMembers).map((userId: string) => {
      const deletedDate = checkedTeamMembers[userId] ? null : new Date()
      return { user_id: Number(userId), deleted_date: deletedDate, program_id: programId, section_id: sectionId }
    })

    if(selectedForm) {
      const payload = {formId: selectedForm, customerNumber, trainingProgramForms}
      const action = updateTrainingProgramFormUsers(payload)
      const res = await dispatch(action)
    }
  }

  return (
    <TeamMemberModal
      open={teamMemberModalIsOpen}
      onClose={onTeamMemberModalClose}
      onSave={handleSaveTeamMember}
      teamMembers={teamMembers}
      savedTeamMembers={selectedFormTeamMembers}
    />
  )
}

export default TeamMemberFormModal
