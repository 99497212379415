import { Svg, Polygon, SVGProps } from '@react-pdf/renderer';

/**
 * This is a by hand conversion of the logo mark svg in the images folder, as react-pdf can't
 * use external svgs AFAIK.
 */
const LogoMarkPDFSVG = (props: SVGProps) => (
  <Svg viewBox="0 0 97.37 101.23" {...props}>
    <Polygon fill="#00a0aa" strokeWidth="0" points="87.64 16.88 97.37 33.75 68.16 84.36 48.69 84.36 87.64 16.88" />
    <Polygon fill="#0042b0" strokeWidth="0" points="29.22 50.62 38.96 67.49 77.92 0 58.45 0 29.22 50.62" />
    <Polygon
      fill="#0084b7"
      strokeWidth="0"
      points="29.21 84.36 19.48 67.49 48.7 16.88 29.23 16.88 0 67.49 19.48 101.23 38.95 101.23 48.69 84.36 29.21 84.36"
    />
  </Svg>
);

export default LogoMarkPDFSVG;
