import {
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@mui/material';
import { useMemo, useState, useCallback, ChangeEventHandler } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as R from 'remeda';

export interface DataColumn<T extends string> {
  label: string;
  key: T;
  isSortable?: boolean;
}

interface ItemWithId {
  id: string;
}

type Item<T extends string> = Record<T, string> & ItemWithId;

interface DataTableProps<T extends string> {
  columns: DataColumn<T>[];
  items: Item<T>[];
  isLoading: boolean;
  onEdit?: (id: string) => void;
  onDelete?: (id: string) => void;
}

const NO_SORT = 'no-sort-none';

const DEFAULT_ROWS_PER_PAGE = 10;

const DataTable = <T extends string>({ columns, items = [], isLoading, onEdit, onDelete }: DataTableProps<T>) => {
  const [columnSort, setColumnSort] = useState<T | typeof NO_SORT>(NO_SORT);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);

  const [columnSortDirection, setColumnSortDirection] = useState<'asc' | 'desc'>('asc');

  const onSortLabelClick = useCallback(
    (header: T) => {
      if (columnSort === header) {
        if (columnSortDirection === 'asc') {
          setColumnSortDirection('desc');
        } else if (columnSortDirection === 'desc') {
          setColumnSortDirection('asc');
          setColumnSort(NO_SORT);
        }
      } else {
        setColumnSort(header);
        setColumnSortDirection('asc');
      }
    },
    [columnSort, columnSortDirection]
  );

  const hasActionButtons = onEdit || onDelete;

  const onRowsPerChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setRowsPerPage(parseInt(e.target.value));
  };

  const onPageChange = (_: unknown, page: number) => {
    setPage(page);
  };

  const sortedItems = useMemo(() => {
    if (columnSort == NO_SORT) {
      return items;
    }
    return R.sortBy(items, [(x) => x[columnSort], columnSortDirection]);
  }, [items, columnSort, columnSortDirection]);

  const pageItems = useMemo(() => {
    const start = page * rowsPerPage;
    return sortedItems.slice(start, start + rowsPerPage);
  }, [sortedItems, page, rowsPerPage]);

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(({ key, label, isSortable }) =>
              isSortable ? (
                <TableCell key={key}>
                  <TableSortLabel
                    onClick={() => onSortLabelClick(key)}
                    active={columnSort === key}
                    direction={columnSort === key ? columnSortDirection : 'asc'}
                  >
                    {label}
                  </TableSortLabel>
                </TableCell>
              ) : (
                <TableCell key={key}>{label}</TableCell>
              )
            )}
            {hasActionButtons && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {pageItems.map((item) => (
            <TableRow key={item.id}>
              {columns.map(({ key }) => (
                <TableCell key={key}>{item[key]}</TableCell>
              ))}
              <TableCell>
                {onEdit && (
                  <IconButton aria-label="edit" onClick={() => onEdit(item.id)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
                {onDelete && (
                  <IconButton aria-label="delete" onClick={() => onDelete(item.id)}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={columns.length + (hasActionButtons ? 1 : 0)}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TablePagination
                count={items.length}
                onPageChange={onPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={onRowsPerChange}
              />
            </TableRow>
          )}
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
