import { TableCell } from "@mui/material"
import { getFullNameOrEmail } from "../../../../../redux/user"
import { TeamMember } from "../../../../../types"
import { ProgramForm, ProgramFormFilter, TrainingProgramForm } from "../../types"
import HighlightedSearchText from "./HighlightedSearchText"
import { useMemo } from "react"

const TeamMemberCell = (props: {
  form: ProgramForm,
  isCollapsedCell: boolean,
  trainingProgramForm: TrainingProgramForm,
  tableCellStyles: any,
  colIndex: number,
  colWidths: number[],
  teamMemberIndex: {[Identifier: string]: TeamMember},
  formTeamMembers: TeamMember[],
  filter: ProgramFormFilter | null,
  searchValue: string,
  sectionId: number
}) => {

  const {
    form,
    isCollapsedCell,
    tableCellStyles,
    colIndex,
    colWidths,
    trainingProgramForm, 
    teamMemberIndex,
    formTeamMembers,
    filter,
    searchValue,
    sectionId
  } = props

  const filterValue = useMemo(() => filter && filter.value !== 'None' ? filter.value : '', [filter]);
  
  return (
    <>
      {
        isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads) ?
          <TableCell
            sx={{
              ...tableCellStyles,
              width: colWidths[colIndex]
            }}
            align="center"
          >
            <HighlightedSearchText
              searchValue={searchValue}
              filterValue={filterValue}
              text={trainingProgramForm?.user_id ? getFullNameOrEmail(teamMemberIndex[trainingProgramForm.user_id]) : ''}
					  />
          </TableCell>
        :
          <TableCell
            sx={{
              ...tableCellStyles,
            }}
            align="center"
          >
            {formTeamMembers.length > 0 ? `${formTeamMembers.length} Team Members` : ''}
          </TableCell>
        }
    </>
  )
}

export default TeamMemberCell