import { TableCell } from "@mui/material"
import { getFullNameOrEmail } from "../../../../../redux/user"
import { TeamMember } from "../../../../../types"
import { ProgramForm, ProgramFormFilter, TrainingProgramForm } from "../../types"
import HighlightedSearchText from "./HighlightedSearchText"
import { useMemo } from "react"

const UploadedByCell = (props: {
  form: ProgramForm,
  isCollapsedCell: boolean,
  trainingProgramForm: TrainingProgramForm,
  tableCellStyles: any,
  colIndex: number,
  colWidths: number[],
  teamMemberIndex: {[Identifier: string]: TeamMember},
  formTeamMembers: TeamMember[],
  filter: ProgramFormFilter | null
  searchValue: string;
  sectionId: number
}) => {

  const {
    form,
    isCollapsedCell,
    tableCellStyles,
    colIndex,
    colWidths,
    trainingProgramForm, 
    teamMemberIndex,
    filter,
    searchValue,
    sectionId
  } = props

  const sx = isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads ) ? {...tableCellStyles, width: colWidths[colIndex]} : tableCellStyles
  const cellValue = trainingProgramForm?.uploaded_by ? getFullNameOrEmail(teamMemberIndex[trainingProgramForm.uploaded_by]) : ''
  const filterValue = useMemo(() => filter && filter.value !== 'None' ? filter.value : '', [filter]);

  return (
    <TableCell
      sx={sx}
      align="center"
    >
      {
        isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads && trainingProgramForm?.section_id === sectionId)  ?
          <HighlightedSearchText
            text={cellValue}
            searchValue={searchValue}
            filterValue={filterValue}
          />
        : null
      }
    </TableCell>
  )
}

export default UploadedByCell