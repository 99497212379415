import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Stack
} from '@mui/material';
import { Field, Form } from 'react-final-form';
import { useMemo } from 'react';
import { isFulfilled } from '@reduxjs/toolkit';
import * as R from 'remeda';

import { EphiTeamMembers } from '../types';
import CloseModalButton from '../../../../../components/CloseModalButton';
import { UserSelect } from './UserMultiSelect';
import { useAppDispatch, useAppSelector } from '../../../../../redux/store';
import { selectUsers, selectUsersMap, updateEphiTeamMember, createEphiTeamMember, selectEphiTeamMembers, selectUnassignedEphiUsers } from '../slice';
import { required } from '../../../../../helpers/formValidation';
import { mapSuccess, unwrapOr } from '../../../../../helpers/RemoteData';

interface FormState {
  teamMember: string;
  handlesPhi: string;
}

const AddEditPHIHandlersModal = ({ onClose, isOpen, item }: { onClose: () => void; isOpen: boolean; item?: EphiTeamMembers }) => {
  const usersMap = useAppSelector(selectUsersMap);
  const users = useAppSelector(selectUsers);

  const dispatch = useAppDispatch();

  const unassignedUsers = useAppSelector(selectUnassignedEphiUsers);

  // item.user_id is actually a login_user_id but everywhere else uses user_id
  const loginIdUserIdMap = useMemo(() => {
    return users.reduce(
      (userMap, user) => ({
        ...userMap,
        [user.login_user_id + '']: user.user_id + ''
      }),
      {} as Record<string, string>
    );
  }, [users]);

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (values: FormState) => {
    const loginUserId = usersMap[values.teamMember]?.login_user_id;

    if (!loginUserId) {
      console.error(`user ${values.teamMember} didn't have a login_user_id`);
      return;
    }

    const dto: Partial<EphiTeamMembers> = {
      user_id: loginUserId,
      has_phi: values.handlesPhi === 'true' ? true : false
    };

    if (item?.id) {
      dto.id = item.id;
    }

    const action = item?.id ? updateEphiTeamMember : createEphiTeamMember;

    const res = await dispatch(action(dto));

    if (isFulfilled(res)) {
      handleClose();
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth>
      <CloseModalButton handleClose={handleClose} />
      <DialogTitle>Team Members Who Handle ePHI</DialogTitle>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, invalid }) => (
          <>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Stack spacing={'1em'} sx={{ marginTop: 1 }}>
                  <Field
                    name={'teamMember' satisfies keyof FormState}
                    validate={required('teamMember' satisfies keyof FormState)}
                    initialValue={item?.user_id ? loginIdUserIdMap[item?.user_id] + '' : undefined}
                  >
                    {({ input, meta }) => (
                      <UserSelect
                        {...input}
                        required
                        users={item?.id ? [item.user] : unassignedUsers}
                        label="Team Member"
                        error={meta.error}
                        touched={meta.touched}
                        disabled={!!item?.id}
                      />
                    )}
                  </Field>
                  <Field
                    name={'handlesPhi' satisfies keyof FormState}
                    validate={required('handlesPhi' satisfies keyof FormState)}
                    initialValue={item?.id ? item?.has_phi + '' : 'true'}
                    // Disregard console errors about type=radio, MUI radio group behaves as expected here
                  >
                    {({ input, meta }) => (
                      <FormControl required error={meta.touched && !!meta.error}>
                        <FormLabel>Handles PHI</FormLabel>
                        <RadioGroup {...input}>
                          <FormControlLabel value="true" control={<Radio />} label="Yes" />
                          <FormControlLabel value="false" control={<Radio />} label="No" />
                        </RadioGroup>
                        <FormHelperText>{meta.touched && meta.error}</FormHelperText>
                      </FormControl>
                    )}
                  </Field>
                </Stack>
              </form>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button variant="contained" disabled={submitting || invalid} onClick={handleSubmit}>
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default AddEditPHIHandlersModal;
