
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer';
import { PracticeInfo } from "../types";
import { Header, Footer } from "./PDFcomponents";

import openSans from '../../../../fonts/OpenSans-Regular.ttf';
import openSansBold from '../../../../fonts/OpenSans-Bold.ttf';
import openSansItalic from '../../../../fonts/OpenSans-Italic.ttf';

Font.register({ family: 'Open Sans', fonts: [{ src: openSans }, { src: openSansBold, fontWeight: 'bold' }, { src: openSansItalic, fontStyle: 'italic' }] });

const COMPLIANCE_BLUE = '#0084b7';
const COMPLIANCE_CRANBERRY = '#9f000f';
const COLORED_ROW = '#e9ecef';

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Open Sans',
    fontSize: '10pt'
  },
  checklistSectionHeading: {
    backgroundColor: COMPLIANCE_BLUE,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '20pt',
    padding: '5pt'
  },
  content: {
    margin: '0 0.25in',
    flex: 1
  },
  table: {
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableCol: {
    flex: 1,
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    margin: 5
  },
  statusCol: {
    flex: 1
  },
});

const handleRowColor = (index: number): object => {
  if (index % 2 === 1) {
    return {
      display: 'flex',
      flexDirection: 'row',
      backgroundColor: COLORED_ROW
    }
  }
  return {
    display: 'flex',
    flexDirection: 'row'
  }
}

interface PracticeInformationPDFProps {
  practiceInfo?: PracticeInfo;
  title: string;
  year?: string;
}

const PracticeInformationPDF = ({ practiceInfo, title, year }: PracticeInformationPDFProps) => {
  if (!title || !year || !practiceInfo) {
    return;
  }

  const { name, doctor_name, street, street2, city, state, zip, phone } = practiceInfo;

  const tableValues = [
    ['Practice Name', name],
    ['Doctor Name', doctor_name],
    ['Address 1', street],
    ['Address 2', street2],
    ['City, State, Zip', `${city.trim()} ${state.trim()} ${zip.trim()}`],
    ['Office Phone', phone]
  ];

  return (
    <Document>
      <Page size="A4" orientation="landscape" wrap style={styles.page}>
        <Header practiceInfo={practiceInfo} title={title} year={year}></Header>
        <View style={styles.content}>
          <View style={styles.checklistSectionHeading}>
            <Text>Practice Information</Text>
          </View>
          <View style={styles.table}>
            {tableValues.map((v, i) => (
              <View key={i} style={handleRowColor(i)}>
                <View style={[styles.tableCol, styles.statusCol]}>
                  <View style={styles.tableCell}>
                    <Text>{ v[0] }</Text>
                  </View>
                </View>
                <View style={[styles.tableCol, styles.statusCol]}>
                  <View style={styles.tableCell}>
                    <Text>{ [v[1]] }</Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </View>
        <Footer />
      </Page>
    </Document>
  );
};

export default PracticeInformationPDF;