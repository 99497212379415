import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormControl, InputLabel, LinearProgress, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { match, P } from 'ts-pattern';
import * as R from 'remeda';

import { oshaHipaaIcon } from '../../../icons';

import './OshaHipaa.css';

import { selectCurrentCustomerNumber, selectCustomerNumber, selectHasDE } from '../../../redux/customer';
import { selectPrograms, getData, selectLoadingState, selectSnackbarMessage, hideSnackBar } from './slice';
import ProgramDirections from './ProgramDirections';
import { oldDeCutoffYear } from './constants';
import { selectChildHasDE } from '../../../redux/childCustomer';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import constants from '../../../constants';
import { ErrorNotify, GenericNotify } from '../../../components/Notify.styled';

const useHasDEAndCustomerNumberAndIsChildCustomer = () => {
  let { customerNumber: routeCustomerNumber } = useParams();

  const stateCustomerNumber = useSelector(selectCustomerNumber);

  let customerNumber = stateCustomerNumber;
  let hasDESelector = selectHasDE;
  let isChildCustomer = !!routeCustomerNumber;

  if (routeCustomerNumber) {
    hasDESelector = selectChildHasDE;
    customerNumber = routeCustomerNumber;
  }

  const hasDE = useSelector(hasDESelector);

  return { hasDE, customerNumber, isChildCustomer };
};

export default function PracticeOshaHipaa() {
  const snackbarMessage = useAppSelector(selectSnackbarMessage);
  const handleCloseSnackbar = () => {
    dispatch(hideSnackBar());
  };
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  let { year } = useParams();

  const { hasDE, customerNumber, isChildCustomer } = useHasDEAndCustomerNumberAndIsChildCustomer();

  const loadingState = useSelector(selectLoadingState);
  const isLoading = loadingState === 'loading' || loadingState === 'not-asked';
  const hasError = loadingState === 'error';

  const setProgramYear = (year?: string): void => {
    if (!year && hasDE) {
      // Program year wasn't in list, but they have de so use old cut off year as the value
      return setProgramYear(oldDeCutoffYear);
    } else if (!year) {
      // Nothing was provided and they don't have de, so do nothing
      return;
    }

    if (isChildCustomer) {
      navigate(`/corp/practice/${customerNumber}/osha-hipaa/${year}`);
    } else {
      navigate(`/practice/osha-hipaa/${year}`);
    }
  };

  const handleSelectProgramYear = (e: SelectChangeEvent) => {
    setProgramYear(e.target.value);
  };

  const programs = useSelector(selectPrograms);

  const programYears = useMemo(() => {
    return [...new Set(programs?.map((program) => String(program.year)))].sort().reverse();
  }, [programs]);

  useEffect(() => {
    // Year wasn't part of route
    if (!year) {
      const currentYear = String(new Date().getFullYear());

      setProgramYear(programYears.includes(currentYear) ? currentYear : programYears[0]);
    }
  }, [year, programYears]);

  // track which customer is loaded in the practice store
  const loadedCustomerPractice = useAppSelector(selectCurrentCustomerNumber);

  useEffect(() => {
    if (!customerNumber) return;
    if (loadingState === 'loading') return; // data has not loaded yet
    if (customerNumber === loadedCustomerPractice && loadingState === 'done') return; // state data is current and loaded

    // if we've reached here load the customer
    dispatch(getData(customerNumber));

  }, [customerNumber, loadingState]);

  const errorSnippet = <p>An error has occured, please try again.</p>;

  return (
    <>
      <div className="page-header-container">
        <img src={oshaHipaaIcon} alt="" className="header-image" />
        <h1 className="page-header-name">OSHA/HIPAA</h1>
      </div>
      <ErrorBoundary fallback={errorSnippet}>
        {match({ hasError, isLoading, hasDE, programs, year })
          .with({ hasError: true }, () => errorSnippet)
          .with({ isLoading: true }, () => <LinearProgress data-testid="osha-hipaa-loading-bar" />)
          .with({ isLoading: false, programs: P.when(R.isEmpty), hasDE: false }, () => (
            <iframe className="iframe-sales-page" data-testid="sales-iframe" src={constants.OSHA_HIPAA_TRAINING_SALES_PAGE_IFRAME_URL} />
          ))
          .with(
            P.intersection({ year: P.select(P.not(P.nullish)), isLoading: false }, P.union({ programs: P.not(P.when(R.isEmpty)) }, { hasDE: true })),
            (programYear) => (
              <div className="m-16">
                <div className="flex flex-end">
                  <div className="flex flex-column">
                    <FormControl sx={{ minWidth: 150 }} size="small">
                      <InputLabel>Program Year</InputLabel>
                      {/** For the matter of speed, i'm just putting a testid here, but ideally we should use MUI in a way were we can select this select by its label, which isn't possible with this current markup */}
                      <Select autoWidth value={programYear} label="Program Year" onChange={handleSelectProgramYear} data-testid="program-year-select">
                        {programYears.map((y) =>
                          y != oldDeCutoffYear ? ( // Don't double include de cut-off year
                            <MenuItem value={y} key={y}>
                              {y}
                            </MenuItem>
                          ) : null
                        )}
                        {hasDE && <MenuItem value={oldDeCutoffYear}>{oldDeCutoffYear} and Prior</MenuItem>}
                      </Select>
                    </FormControl>
                    <ProgramDirections />
                  </div>
                </div>
                <Outlet />
              </div>
            )
          )
          .otherwise(() => errorSnippet)}
      </ErrorBoundary>
      {match(snackbarMessage)
        .with({ type: 'normal', message: P.select() }, (message) => <GenericNotify open={true} message={message} onClose={handleCloseSnackbar} />)
        .with({ type: 'error', message: P.select() }, (message) => (
          <ErrorNotify open={true} onClose={handleCloseSnackbar}>
            {message}
          </ErrorNotify>
        ))
        .otherwise(() => null)}
    </>
  );
}
