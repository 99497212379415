import { IconButton } from "@mui/material";
import { ProgramForm, TrainingProgramForm } from "../../types";
import { FileUpload as FileUploadIcon } from "@mui/icons-material";
import { blue } from "@mui/material/colors";

const UploadButton = (props: {
  form: ProgramForm;
  setFileUploadModalIsOpen: (open: boolean) => void
  setSelectedForm: (formId: number) => void
  setSelectedTrainingForm: (id: number) => void
  trainingForm?: TrainingProgramForm
  uploaded: boolean
}) => {
  const { form, setFileUploadModalIsOpen, setSelectedForm, trainingForm, setSelectedTrainingForm, uploaded } = props;
  return (
    <IconButton
      disabled={uploaded}
      sx={{ color: blue[500], padding: '0px' }}
      aria-label="upload"
      onClick={() => {
        setFileUploadModalIsOpen(true);
        setSelectedForm(form.id);
        if(trainingForm) setSelectedTrainingForm(trainingForm.id);
      }
    }>
      <FileUploadIcon/>
    </IconButton>
  )
}

export default UploadButton