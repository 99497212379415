import { Typography } from "@mui/material"
import React from "react"

const HighlightedSearchText = (props: {
  text: string,
  searchValue?: string,
  filterValue?: string,
  style?: any
}) => {

  const { text, searchValue = '', filterValue = '', style = { fontSize: '13px'} } = props
  const lowerCasedText = text.toLowerCase()
  const lowerCasedSearch = searchValue.toLowerCase()
  const lowerCasedFilter = filterValue.toLowerCase()

  if(lowerCasedText === lowerCasedFilter) {
    return (
      <mark style={style}>{text}</mark>
    )
  } else if(lowerCasedSearch !== '' && lowerCasedText.includes(lowerCasedSearch)) {
    return (
      <>
        {
          text.split(new RegExp(`(${searchValue})`, 'gi')).map((part, i) => (
            <React.Fragment key={i}>
              {
                part.toLowerCase() === lowerCasedSearch ?
                  <mark style={{ ...style, display: 'inline' }}>{part}</mark>
                : <Typography style={{ ...style, display: 'inline' }}>{part}</Typography>
              }
            </React.Fragment>
          ))
        }
      </>
    )
  } else {
    return (
      <Typography style={style}>{text}</Typography>
    )
  }
  
}

export default HighlightedSearchText