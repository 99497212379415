import { TableCell } from "@mui/material"
import { TeamMember } from "../../../../../types"
import { ProgramForm, ProgramFormFilter, TrainingProgramForm } from "../../types"
import HighlightedSearchText from "./HighlightedSearchText"
import { useMemo } from "react"

const StatusCell = (props: {
  form: ProgramForm,
  isCollapsedCell: boolean,
  trainingProgramForm: TrainingProgramForm,
  tableCellStyles: any,
  colIndex: number,
  colWidths: number[],
  formTeamMembers: TeamMember[],
  uploadedCount: number,
  filter: ProgramFormFilter | null,
  searchValue: string
}) => {

  const {
    form,
    isCollapsedCell,
    tableCellStyles,
    colIndex,
    colWidths,
    trainingProgramForm, 
    formTeamMembers,
    uploadedCount,
    filter,
    searchValue
  } = props

  const filterValue = useMemo(() => filter && filter.value !== 'None' ? filter.value : '', [filter]);
  
  return (
    <>
      {
        isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads) ?
          <TableCell
            sx={{
              ...tableCellStyles,
              width: colWidths[colIndex]
            }}
            align="center"
          >
            <HighlightedSearchText
              searchValue={searchValue}
              filterValue={filterValue}
              text={trainingProgramForm?.file_id ? 'Complete' : 'Missing'}
            />
          </TableCell>
        :
        <TableCell
          sx={{
            ...tableCellStyles,
          }}
          align="center"
        >
          {formTeamMembers.length && !form.is_multiple_uploads ? `${uploadedCount}/${formTeamMembers.length} Complete` : ''}
        </TableCell>
      }
    </>
  )
}

export default StatusCell