import { Button, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import * as R from 'remeda';
import { P, match } from 'ts-pattern';
import { pdf } from '@react-pdf/renderer';

import DataTable, { DataColumn } from '../../../../components/DataTable';
import { mapSuccess, unwrapOr } from '../../../../helpers/RemoteData';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';

import { deleteBusinessAssociate, fetchBusinessAssociates, selectBusinessAssociates } from './slice';
import { SRABusinessAssociate } from './types';
import AddEditBusinessAssociateModal from './components/AddEditBusinessAssociateModal';
import GenericDeleteModal from './components/GenericDeleteModal';
import theme from '../../../../themes/theme';
import { useParams } from 'react-router-dom';
import { downloadFile } from '../../../../helpers/downloadFile';
import { selectPracticeInfo } from '../slice';
import BusinessAssociatesPDF from '../components/BusinessAssociatesPDF';


type BAColumnKeys = 'id' | 'name' | 'type' | 'address';

const BATableColumns: DataColumn<BAColumnKeys>[] = [
  {
    key: 'name',
    isSortable: true,
    label: 'Business Associate Name'
  },
  {
    key: 'type',
    isSortable: true,
    label: 'Business Associate Type'
  },
  {
    key: 'address',
    isSortable: true,
    label: 'Address'
  }
];

const MAX_BUSINESS_ASSOCIATES = 40;

const BusinessAssociatesAgreement = () => {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Typography paragraph>
        In this section, you must list your current Business Vendors that may &ldquo;see or use&rdquo; your PHI / ePHI:
      </Typography>
      <ol>
        <li>Enter your Business Associate Name into Column A.</li>
        <li>Enter the Type of Associate they are.</li>
        <li>Ensure the Correct Address is input into Column C.</li>
      </ol>
      <Typography paragraph>
        **Please make sure this list matches your Business Associates Log located in your HIPAA made Easy™ Manual. See page 176**
      </Typography>
      <Typography paragraph>
        Find the Business Associate Agreement on pages 154-159 of your HIPAA manual. Please have applicable vendors sign page 159 and retain this on
        file for future reference in case you ever have a HIPAA audit.
      </Typography>
      <Typography paragraph fontStyle="italic">
        EXAMPLES OF COMPANIES TO SIGN BAA (pg 154-159): IT Tech Company, Data-Back-Up / Software Company, Cleaning Crew. Collection Agency, Shredding
        Company, Email Encryption Company, Fax Encryption Company, Confirmation Service, Temp Employee Service, Water Delivery, Dental Suppliers,
        (Lawyer / CPA = Collection or Legal issues)
      </Typography>
      <Typography paragraph fontStyle="italic">
        EXAMPLES OF INDEPENDENT CONTRACTORS TO SIGN NDA (pg 78-79): Temp Employee, Student / Volunteer, Fix-It Man, Cleaning Person, Consultants, IT
        Tech
      </Typography>
      {expanded && (
        <Typography paragraph>
          <Typography component="span" fontWeight="bold">
            HIPAA &ldquo;Business Associate Agreements&rdquo; (BAA)
          </Typography>
          &nbsp;are actually&nbsp;
          <Typography component="span" fontWeight="bold">
            &ldquo;Business Vendor Confidentiality Agreements,&rdquo;
          </Typography>
          &nbsp;designed by the government to protect healthcare practice owners in situations where vendors may have mishaps when utilizing the
          practice's &ldquo;Protected Health Information&rdquo; (either in print or electronic). To safeguard your practice from third parties
          mishandling your PHI / ePHI, it is required that a HIPAA BAA be signed by all Companies/Vendors that &ldquo;see or use&rdquo; your Patient
          Protected Health Information (PHI). Independent Contractors will sign a similar protective document called a &ldquo;Non-Disclosure
          Agreement.&rdquo; Practice owners should keep these important contracts on file as they will protect your facility legally, should any
          breach related incidence occur involving your practice's PHI / ePHI.
        </Typography>
      )}
      <Button variant="text" onClick={() => setExpanded(!expanded)}>
        show {expanded ? 'less' : 'more'}
      </Button>
    </>
  );
};

const BusinessAssociatesList = () => {
  const { componentTypeName, year } = useParams();
  const dispatch = useAppDispatch();
  const practiceInfo = useAppSelector(selectPracticeInfo);
  const businessAssociatesState = useAppSelector(selectBusinessAssociates);
  const [exportButtonState, setExportButtonState] = useState<'loading' | 'done' | 'not-started'>('not-started');

  useEffect(() => {
    if (businessAssociatesState.type === 'not-asked') {
      dispatch(fetchBusinessAssociates());
    }
  }, []);

  const businessAssociates = unwrapOr(businessAssociatesState, {});

  const [addEditModalIsOpen, setAddEditModalIsOpen] = useState(false);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [activeBusinessAssociate, setActiveBusinessAssociate] = useState<SRABusinessAssociate>();

  const onModalClose = () => {
    setAddEditModalIsOpen(false);
    setDeleteModalIsOpen(false);
    setActiveBusinessAssociate(undefined);
  };

  const openAddEditModal = (id?: string) => {
    setAddEditModalIsOpen(true);

    if (!id) {
      setActiveBusinessAssociate(undefined);
      return;
    }

    const newActiveBusinessAssociate = R.pipe(businessAssociatesState, mapSuccess(R.prop(id)), unwrapOr(undefined as typeof activeBusinessAssociate));

    setActiveBusinessAssociate(newActiveBusinessAssociate);
  };

  const openDeleteModal = (id: string) => {
    setDeleteModalIsOpen(true);
    setActiveBusinessAssociate(businessAssociates[id]);
  };

  const onDelete = async () => {
    if (activeBusinessAssociate) {
      await dispatch(deleteBusinessAssociate(activeBusinessAssociate));
    }

    onModalClose();
  };

  const tableItems = useMemo(
    () =>
      R.pipe(
        businessAssociatesState,
        mapSuccess(R.values),
        mapSuccess(R.map(({ id, name, type, address }) => ({ id: '' + id, name, type, address }))),
        unwrapOr([] as Record<BAColumnKeys, string>[])
      ),
    [businessAssociatesState]
  );

  const downloadPDF = useCallback(async () => {
    const title = `${componentTypeName} Risk Assessment Report`;

    setExportButtonState('loading');

    const renderedPdf = await pdf(
      <BusinessAssociatesPDF
        businessAssociates={tableItems}
        practiceInfo={practiceInfo}
        title={title}
        year={year}
      />
    ).toBlob();

    const url = URL.createObjectURL(renderedPdf);

    await downloadFile({
      url,
      isBlobURL: true,
      fileName: `${year} ${practiceInfo?.name} ${title}.pdf`
    });

    setExportButtonState('done');
  }, [tableItems, practiceInfo, year]);

  const addButtonDisabled = useMemo(
    () => businessAssociatesState.type !== 'success' || tableItems.length >= MAX_BUSINESS_ASSOCIATES,
    [businessAssociatesState]
  );

  return (
    <div style={{ color: theme.palette.color50555b.main }}>
      <h2>Business Associates Agreement List</h2>
      <BusinessAssociatesAgreement />
      <Stack direction="row" justifyContent="space-between" alignItems="center" paddingBottom="1em">
        <Typography color="red" fontStyle="italic">
          {match(businessAssociatesState)
            .with(
              { type: 'success', data: P.when(R.isEmpty) },
              () => 'No business associates have been added. Please use the "Add Associate" button to add your business associates.'
            )
            .with({ type: 'failure' }, () => 'An error has occured. Please refresh the page.')
            .otherwise(() => null)}
        </Typography>
        <div>
          <Button variant="contained" disabled={addButtonDisabled} onClick={() => openAddEditModal()}>
            Add Associate
          </Button>
          <Button variant="contained" className="m-l-16" onClick={() => downloadPDF()} disabled={exportButtonState == 'loading'}>
            {exportButtonState === 'loading' ? 'Exporting...' : 'Export'}
          </Button>
        </div>
      </Stack>
      <DataTable
        columns={BATableColumns}
        items={tableItems}
        isLoading={businessAssociatesState.type === 'loading' || businessAssociatesState.type === 'not-asked'}
        onEdit={(id) => openAddEditModal(id)}
        onDelete={(id) => openDeleteModal(id)}
      />
      <AddEditBusinessAssociateModal isOpen={addEditModalIsOpen} businessAssociate={activeBusinessAssociate} onClose={onModalClose} />
      <GenericDeleteModal open={deleteModalIsOpen} onDelete={onDelete} onClose={onModalClose} />
    </div>
  );
};

export default BusinessAssociatesList;
