import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Box, IconButton, Link, Slide, SlideProps, Snackbar, SnackbarContent, SnackbarProps, styled } from '@mui/material';
import React, { PropsWithChildren } from 'react';

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  backgroundColor: theme.palette.color0085b7.main,
  color: theme.palette.common.white,
  borderColor: 'lightgrey'
}));

const DefaultTransition: React.FC<SlideProps> = ({ ...props }) => <Slide {...props} direction="down" />;

export const GenericNotify: React.FC<SnackbarProps> = ({
  autoHideDuration = 30000,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  TransitionComponent = DefaultTransition,
  ...props
}) => {
  const defaultAction = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={(event) => props.onClose!(event, 'clickaway')}>
      <FontAwesomeIcon size="sm" icon="xmark" />
    </IconButton>
  );
  return (
    <Snackbar autoHideDuration={autoHideDuration} anchorOrigin={anchorOrigin} TransitionComponent={TransitionComponent} {...props}>
      <StyledSnackbarContent message={props.message} action={props.action ?? defaultAction}></StyledSnackbarContent>
    </Snackbar>
  );
};

const AlertNotify: React.FC<PropsWithChildren<SnackbarProps>> = ({
  children,
  autoHideDuration = 30000,
  anchorOrigin = { vertical: 'top', horizontal: 'center' },
  TransitionComponent = DefaultTransition,
  message,
  ...props
}) => {
  return (
    <Snackbar autoHideDuration={autoHideDuration} anchorOrigin={anchorOrigin} TransitionComponent={TransitionComponent} {...props}>
      {children}
    </Snackbar>
  );
};

export type AlertNotifyProps = Pick<SnackbarProps, 'action' | 'onClose' | 'open'>;

export const DefaultErrorMessage = () => (<Box>
    Your action could not be completed at this time. Please try again. If the issue persists, please contact us via live chat, email us at <FontAwesomeIcon icon="envelope" /> <Link href='mailto:ontraq@healthfirst.com'>ontraq@healthfirst.com</Link> or call us at <FontAwesomeIcon icon="phone" /> <Link href='tel:800-331-1984'>800-331-1984</Link>.
</Box>);

export const ErrorNotify: React.FC<PropsWithChildren<AlertNotifyProps>> = ({ children, action, ...props }) => {
  const defaultAction = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={(event) => props.onClose!(event, 'clickaway')}>
      <FontAwesomeIcon size="sm" icon="xmark" />
    </IconButton>
  );
  return (
    <AlertNotify anchorOrigin={{ vertical: 'top', horizontal: 'right' }} {...props}>
      <Alert severity="error" action={action ?? defaultAction}>
        {children ?? (<DefaultErrorMessage />)}
      </Alert>
    </AlertNotify>
  );
};

export const WarningNotify: React.FC<PropsWithChildren<AlertNotifyProps>> = ({ children, action, ...props }) => {
  const defaultAction = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={(event) => props.onClose!(event, 'clickaway')}>
      <FontAwesomeIcon size="sm" icon="xmark" />
    </IconButton>
  );
  const defaultMessage = 'Your action could not be completed at this time. Please try again.'; // placeholder; warnings are likely specific and this might not ever be used
  return (
    <AlertNotify anchorOrigin={{ vertical: 'top', horizontal: 'right' }} {...props}>
      <Alert severity="warning" action={action ?? defaultAction}>
        {children ?? defaultMessage}
      </Alert>
    </AlertNotify>
  );
};
