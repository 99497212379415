export interface SimpleFile {
  url: string;
  fileName: string;
  isBlobURL?: boolean;
}

/**
 * A utility to found online to download a file instead of opening it in a new tab
 */
export const downloadFile = async ({ url, fileName, isBlobURL }: SimpleFile) => {
  let href = url;
  if (!isBlobURL) {
    href = await fetch(url, { method: 'get', referrerPolicy: 'no-referrer' })
      .then((res) => res.blob())
      .then((res) => URL.createObjectURL(res));
  }

  const aElement = document.createElement('a');
  aElement.setAttribute('download', fileName);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');
  aElement.click();

  URL.revokeObjectURL(href);
};
