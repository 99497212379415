import { DialogContent, Dialog, Box } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const DownloadFormsModal = ({ open, onClose }: {
  open: boolean;
  onClose: () => void;
}) => {
  const handleClose = () => onClose()
  return (
    <Dialog open={open} onClose={handleClose} maxWidth='xs'>
      <DialogContent>
        <Box sx={{ p: 2, border: '1px grey', textAlign: 'center', marginBottom: '5px' }}>
          <Box
            sx={{
                padding: '0.75rem',
                borderRadius: '0.2em',
                bgcolor: theme => theme.palette.standardsGray.main,
                marginBottom: '0.75em',
            }}
          >
            <FontAwesomeIcon style={{color: 'rgba(256, 256, 256, 1)'}} icon={faSpinner} spin />
            <span style={{fontSize: '0.875rem', marginLeft: '15px', color: 'rgba(256, 256, 256, 1)', textTransform: 'uppercase'}}>
                downloading forms
            </span>
          </Box>
          <Box
            sx={{
                fontWeight: 'bold',
                color: 'rgba(0, 0, 0, 1)',
                fontSize: '0.875rem',
                textAlign: 'left',
            }}
          >
            Please wait. Your copy will download shortly...
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  ); 
};

export default DownloadFormsModal
