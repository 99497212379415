import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout, selectCurrentUser } from '../redux/user';
import { clearCustomer, setCustomer } from '../redux/customer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Link from '@mui/material/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Navbar.css';
import logo from '../images/ontraq_healthfirst.png';
import constants from '../constants';
import AuthService from '../services/authorization';
import { useLocation } from 'react-router-dom';

export default function Navbar() {
  const Auth = new AuthService();
  const dispatch = useDispatch();
  const [practiceAnchorEl, setPracticeAnchorEl] = useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const practiceOpen = Boolean(practiceAnchorEl);
  const accountOpen = Boolean(accountAnchorEl);
  const currentUser = useSelector(selectCurrentUser);
  const location = useLocation();

  const isCorporate = location.pathname.includes('/corp');
  const isPractice = location.pathname.includes('/practice') && !location.pathname.includes('/corp');

  const handleChangeCustomer = async (customerNumber) => {
    if (Auth.getCurrentCustomerNumber() === customerNumber) {
      return false;
    }

    Auth.setCurrentCustomerNumber(customerNumber);
    window.location.href = `${constants.ONTRAQ_WEB_URL}/webapp/#/corporate/dashboard`;
  };

  const handleClick = (event) => {
    if (event.target?.name === 'selectPractice') {
      setPracticeAnchorEl(event.currentTarget);
    } else if (event.target?.name === 'myAccount') {
      setAccountAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setPracticeAnchorEl(null);
    setAccountAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearCustomer());
    Auth.logout();
  };

  return (
    <div className="navbar-container">
      <a href={constants.HEALTHFIRST_WEB_URL}>
        <img src={logo} className="company-logo" alt="company logo" />
      </a>
      <div className="navbar-right-content">
        <span className="m-r-8">
          {currentUser?.name ? currentUser?.name.toUpperCase() : ''} {currentUser?.customer_number ? `(#${currentUser?.customer_number})` : ''}
        </span>
        <div className="dropdown">
          <Button
            id="practice-button"
            aria-controls={practiceOpen ? 'practice-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={practiceOpen ? 'true' : undefined}
            variant="nav"
            name="selectPractice"
            onClick={handleClick}
          >
            Select a Practice
            <FontAwesomeIcon icon="caret-down" size="sm" className="m-l-8" />
          </Button>
          <Menu
            id="practice-menu"
            anchorEl={practiceAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            open={practiceOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'practice-button'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {currentUser?.available_customers.length &&
              [...currentUser.available_customers]
                .sort((a, b) => b.name - a.name)
                .map((customer, i) => {
                  return (
                    <MenuItem variant="nav" key={i} onClick={() => handleChangeCustomer(customer.customerNumber)}>
                      <span className="nav-button" onClick={handleClose}>
                        {currentUser.name ? customer.name.toUpperCase() : ''} ({customer.customerNumber})
                      </span>
                    </MenuItem>
                  );
                })}
            <MenuItem onClick={handleClose}>
              <span className="nav-button" onClick={handleClose}>
                <FontAwesomeIcon icon="plus" size="xs" /> Link Additional Practices
              </span>
            </MenuItem>
          </Menu>
        </div>
        <div className="dropdown">
          <Button
            id="account-button"
            aria-controls={accountOpen ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={accountOpen ? 'true' : undefined}
            variant="nav"
            name="myAccount"
            onClick={handleClick}
          >
            My Account
            <FontAwesomeIcon icon="caret-down" size="sm" className="m-l-8" />
          </Button>
          <Menu
            id="account-menu"
            anchorEl={accountAnchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            open={accountOpen}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'account-button'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            {currentUser?.corporate_parent && isPractice && (
              <MenuItem>
                <Link variant="nav" className="nav-button" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/corporate/dashboard`}>
                  Switch to OnTraq Corporate
                </Link>
              </MenuItem>
            )}
            {currentUser?.corporate_parent && isCorporate && (
              <MenuItem>
                <Link variant="nav" className="nav-button" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/dashboard`}>
                  Switch to OnTraq Practice
                </Link>
              </MenuItem>
            )}
            {currentUser?.corporate_parent && (
              <MenuItem onClick={handleClose}>
                <Link variant="nav" className="nav-button" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/team-members`}>
                  Team Members
                </Link>
              </MenuItem>
            )}
            {currentUser?.corporate_parent && <Divider />}
            <MenuItem onClick={handleClose}>
              <Link variant="nav" className="nav-button" href={`${constants.ONTRAQ_WEB_URL}/webapp/#/practice/settings`}>
                Settings
              </Link>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <span className="nav-button" onClick={handleLogout}>
                Sign Out
              </span>
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
}
