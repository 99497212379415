import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { Form, Field } from 'react-final-form';

import { composeValidators, maxLength, required } from '../../../../../helpers/formValidation';
import { useAppDispatch } from '../../../../../redux/store';

import { createBusinessAssociate, updateBusinessAssociate } from '../slice';
import { SRABusinessAssociate } from '../types';
import CloseModalButton from '../../../../../components/CloseModalButton';

const BUSINESS_ASSOCIATE_TYPES = [
  'BMW Pick Up Company',
  'Cleaning Crew',
  'Collection Agency',
  'Confirmation Service',
  'Data Back-up Company',
  'Dental Repair Company',
  'Dental Suppliers',
  'Email Encryption Company',
  'Fax Encryption Company',
  'Finance Company',
  'IT Company',
  'Landlord',
  'Live Telephone Answering Service',
  'Nitrous & Oxygen Delivery Service',
  'Radiograph Software',
  'Shredding Company',
  'Software Company',
  'Temp Employee Service',
  'Water Delivery'
];

interface BusinessAssociateFormState {
  name?: string;
  type?: string;
  address?: string;
}

const AddEditBusinessAssociateModal = ({
  businessAssociate,
  onClose,
  isOpen
}: {
  businessAssociate?: SRABusinessAssociate;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const dispatch = useAppDispatch();

  const onSubmit = async (values: BusinessAssociateFormState) => {
    if (businessAssociate) {
      await dispatch(
        updateBusinessAssociate({
          ...businessAssociate,
          ...values
        })
      );
    } else {
      await dispatch(createBusinessAssociate(values));
    }

    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth>
      <CloseModalButton handleClose={onClose} />
      <DialogTitle>{businessAssociate ? 'Edit' : 'Add'} Business Associate</DialogTitle>
      <Form onSubmit={onSubmit} initialValues={businessAssociate}>
        {({ handleSubmit, submitting, invalid }) => (
          <>
            <DialogContent>
              <form onSubmit={handleSubmit}>
                <Stack spacing="1em" marginTop={1}>
                  <Field
                    name={'name' satisfies keyof BusinessAssociateFormState}
                    validate={composeValidators('Business Name', required, maxLength(200))}
                  >
                    {({ input, meta: { error, touched } }) => (
                      <TextField {...input} label="Business Name" required error={!!touched && !!error} helperText={touched ? error : null} />
                    )}
                  </Field>
                  <Field name={'type' satisfies keyof BusinessAssociateFormState} validate={composeValidators('Type', required)}>
                    {({ input, meta: { error, touched } }) => (
                      <Autocomplete
                        {...input}
                        options={BUSINESS_ASSOCIATE_TYPES}
                        renderInput={(params) => (
                          <TextField {...params} label="Type" required error={!!touched && !!error} helperText={touched ? error : null} />
                        )}
                        onChange={(_, option) => {
                          input.onChange(option ?? undefined);
                        }}
                      />
                    )}
                  </Field>
                  <Field
                    name={'address' satisfies keyof BusinessAssociateFormState}
                    validate={composeValidators('Address', required, maxLength(200))}
                  >
                    {({ input, meta: { error, touched } }) => (
                      <TextField {...input} label="Address" required error={!!touched && !!error} helperText={touched ? error : null} />
                    )}
                  </Field>
                </Stack>
              </form>
            </DialogContent>
            <DialogActions>
              <Button disabled={submitting} onClick={onClose}>
                Cancel
              </Button>
              <Button variant="contained" disabled={submitting || invalid} onClick={handleSubmit}>
                {submitting ? 'Saving...' : 'Save'}
              </Button>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default AddEditBusinessAssociateModal;
