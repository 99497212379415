import { Svg, Polygon, SVGProps, G, Path, Text, Tspan } from '@react-pdf/renderer';

/**
 * This is a by hand conversion of the logo svg in the images folder, as react-pdf can't
 * use external svgs AFAIK.
 */
const LogoPDFSVG = (props: SVGProps) => (
  <Svg viewBox="0 0 576 127.45" {...props}>
    <G>
      <G>
        <Path fill="#50545a" strokeWidth="0" d="M127.28,8.65v30.33h32.01V8.65h13.82v75.71h-13.82v-33.36h-32.01v33.36h-13.82V8.65h13.82Z" />
        <Path
          fill="#50545a"
          strokeWidth="0"
          d="M190.63,60.88c.34,9.88,7.98,14.15,16.85,14.15,6.4,0,10.9-1.01,15.16-2.47l2.02,9.44c-4.72,2.02-11.23,3.48-19.1,3.48-17.75,0-28.19-10.9-28.19-27.63,0-15.16,9.21-29.43,26.73-29.43s23.59,14.6,23.59,26.62c0,2.58-.22,4.61-.45,5.84h-36.62ZM214.67,51.22c.11-5.05-2.13-13.37-11.35-13.37-8.54,0-12.13,7.75-12.69,13.37h24.04Z"
        />
        <Path
          fill="#50545a"
          strokeWidth="0"
          d="M273.25,71.22c0,4.94.22,9.77.9,13.14h-12.47l-1.01-6.07h-.34c-3.26,4.27-8.99,7.3-16.17,7.3-11.01,0-17.19-7.97-17.19-16.29,0-13.7,12.24-20.78,32.46-20.67v-.9c0-3.59-1.46-9.55-11.12-9.55-5.39,0-11.01,1.68-14.71,4.04l-2.7-8.99c4.04-2.47,11.12-4.83,19.77-4.83,17.52,0,22.58,11.12,22.58,23.14v19.66ZM259.77,57.63c-9.77-.11-19.1,1.91-19.1,10.22,0,5.39,3.48,7.86,7.86,7.86,5.62,0,9.55-3.59,10.9-7.53.34-1.01.34-2.02.34-3.03v-7.53Z"
        />
        <Path fill="#50545a" strokeWidth="0" d="M279.53,4.61h13.82v79.75h-13.82V4.61Z" />
        <Path
          fill="#50545a"
          strokeWidth="0"
          d="M318.06,15.62v14.04h13.14v10.33h-13.14v24.15c0,6.74,1.8,10.11,7.08,10.11,2.47,0,3.82-.11,5.39-.56l.22,10.45c-2.02.79-5.73,1.46-10,1.46-5.17,0-9.32-1.68-11.91-4.49-3.03-3.15-4.38-8.2-4.38-15.39v-25.72h-7.75v-10.33h7.75v-10.22l13.59-3.82Z"
        />
        <Path
          fill="#50545a"
          strokeWidth="0"
          d="M334.91,4.61h13.82v32.46h.22c1.68-2.58,4.04-4.72,6.85-6.18,2.81-1.57,6.07-2.47,9.55-2.47,9.32,0,19.1,6.18,19.1,23.7v32.24h-13.82v-30.78c0-7.86-2.92-13.93-10.56-13.93-5.39,0-9.21,3.59-10.78,7.75-.45,1.24-.56,2.7-.56,4.16v32.8h-13.82V4.61Z"
        />
        <Path fill="#0084b7" strokeWidth="0" d="M391.7,8.65h43.81v11.35h-29.99v20.89h27.86v11.23h-27.86v32.24h-13.82V8.65Z" />
        <Path
          fill="#0084b7"
          strokeWidth="0"
          d="M453.32,14.38c0,4.04-3.03,7.41-7.86,7.41s-7.64-3.37-7.64-7.41c0-4.27,3.15-7.53,7.75-7.53s7.64,3.26,7.75,7.53ZM438.61,84.36V29.66h13.82v54.7h-13.82Z"
        />
        <Path
          fill="#0084b7"
          strokeWidth="0"
          d="M459.04,47.29c0-7.41-.11-12.81-.45-17.64h11.91l.56,10.33h.34c2.7-7.64,9.1-11.57,15.05-11.57,1.35,0,2.13.11,3.26.34v12.92c-1.24-.22-2.47-.34-4.16-.34-6.51,0-11.12,4.16-12.36,10.45-.22,1.24-.34,2.7-.34,4.16v28.42h-13.82v-37.07Z"
        />
        <Path
          fill="#0084b7"
          strokeWidth="0"
          d="M492.85,71.56c3.03,1.91,8.99,4.04,13.93,4.04,6.07,0,8.76-2.58,8.76-6.18s-2.25-5.5-8.99-7.97c-10.67-3.59-15.05-9.44-15.05-15.95,0-9.66,7.98-17.07,20.67-17.07,6.07,0,11.34,1.46,14.6,3.26l-2.81,9.66c-2.25-1.35-6.74-3.14-11.57-3.14s-7.64,2.47-7.64,5.84,2.58,5.05,9.55,7.64c10,3.48,14.49,8.54,14.6,16.62,0,10-7.75,17.19-22.24,17.19-6.63,0-12.58-1.57-16.62-3.82l2.81-10.11Z"
        />
        <Path
          fill="#0084b7"
          strokeWidth="0"
          d="M551.6,15.62v14.04h13.14v10.33h-13.14v24.15c0,6.74,1.8,10.11,7.08,10.11,2.47,0,3.82-.11,5.39-.56l.22,10.45c-2.02.79-5.73,1.46-10,1.46-5.17,0-9.32-1.68-11.91-4.49-3.03-3.15-4.38-8.2-4.38-15.39v-25.72h-7.75v-10.33h7.75v-10.22l13.59-3.82Z"
        />
        <Path
          fill="#50545a"
          strokeWidth="0"
          d="M576,81.63c0,2.21-1.78,3.97-4.04,3.97s-4.06-1.75-4.06-3.97,1.8-3.94,4.09-3.94,4.01,1.73,4.01,3.94ZM568.91,81.63c0,1.75,1.32,3.15,3.08,3.15,1.71.02,3-1.39,3-3.12s-1.3-3.17-3.05-3.17-3.03,1.42-3.03,3.15ZM571.34,83.67h-.91v-3.94c.36-.05.87-.12,1.51-.12.75,0,1.08.12,1.35.31.24.17.41.46.41.84,0,.48-.36.79-.82.94v.05c.38.12.58.43.7.96.12.6.22.82.29.96h-.96c-.12-.14-.19-.48-.31-.96-.07-.41-.31-.6-.82-.6h-.43v1.56ZM571.36,81.46h.43c.5,0,.91-.17.91-.58,0-.36-.26-.6-.84-.6-.24,0-.41.02-.5.05v1.13Z"
        />
      </G>
      <G>
        <Polygon fill="#00a0aa" strokeWidth="0" points="87.64 16.88 97.37 33.75 68.16 84.36 48.69 84.36 87.64 16.88" />
        <Polygon fill="#0042b0" strokeWidth="0" points="29.22 50.62 38.96 67.49 77.92 0 58.45 0 29.22 50.62" />
        <Polygon
          fill="#0084b7"
          strokeWidth="0"
          points="29.21 84.36 19.48 67.49 48.7 16.88 29.23 16.88 0 67.49 19.48 101.23 38.95 101.23 48.69 84.36 29.21 84.36"
        />
      </G>
    </G>
    {/* I used open sans here and couldn't get the letter spacing working since we don't have frutiger lt std */}
    <Text fill="#50545a" transform="translate(110.67 118.95)" style={{ fontFamily: 'Open Sans', fontSize: '34pt', fontWeight: 'bold' }}>
      <Tspan x="0" y="0">
        COMPLIANCE SOLUTIONS
      </Tspan>
    </Text>
  </Svg>
);

export default LogoPDFSVG;
