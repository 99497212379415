import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { match, P } from 'ts-pattern';
import { pdf } from '@react-pdf/renderer';
import { Button } from '@mui/material';
import { useCallback, useState } from 'react';

import { RootState, useAppSelector } from '../../../redux/store';
import CompliancePlip from '../../../components/CompliancePlip';
import { ComplianceStatus } from '../../../types';
import CertifyChecklistCompleteModal from './CertifyChecklistCompleteModal';
import { Section } from './components/Checklist';
import {
  selectChecklistByYearAndComponentTypeIdAndType,
  selectChecklistCertificationByChecklistId,
  selectCustomerChecklistTaskMap,
  selectPracticeInfo
} from './slice';
import { checklistTypeFromSlug, componentTypeNameToId, getChecklistTypeDisplayName } from './utils';
import { TrainingChecklistType } from './types';
import ChecklistPDF from './components/ChecklistPDF';

import styles from './ProgramChecklist.module.css';
import { downloadFile } from '../../../helpers/downloadFile';
import theme from '../../../themes/theme';

const ProgramChecklist = ({ checklistType }: { checklistType?: TrainingChecklistType }) => {
  const { componentTypeName, year, checklistTypeSlug } = useParams();

  const componentTypeId = componentTypeNameToId(componentTypeName);

  const practiceInfo = useAppSelector(selectPracticeInfo);

  const taskMetaMap = useAppSelector(selectCustomerChecklistTaskMap);

  const checklist = useSelector((state: RootState) =>
    selectChecklistByYearAndComponentTypeIdAndType(state, year, componentTypeId, checklistType ?? checklistTypeFromSlug(checklistTypeSlug))
  );

  const certification = useAppSelector((state) => selectChecklistCertificationByChecklistId(state, checklist?.id));

  const [exportButtonState, setExportButtonState] = useState<'loading' | 'done' | 'not-started'>('not-started');

  /**
   * We are having pdf generation happening on button click, because it blocks the main thread for a few seconds.
   * When we have vite, this could be a spot to use a web worker.
   */
  const downloadPDF = useCallback(async () => {
    if (!checklist) {
      return;
    }

    setExportButtonState('loading');

    const renderedPdf = await pdf(
      <ChecklistPDF
        year={year}
        checklist={checklist}
        practiceInfo={practiceInfo}
        taskMetaMap={taskMetaMap}
        certification={certification}
        componentTypeName={componentTypeName}
      />
    ).toBlob();

    const url = URL.createObjectURL(renderedPdf);

    await downloadFile({
      url,
      isBlobURL: true,
      fileName: `${year} ${practiceInfo?.name} ${componentTypeName} ${getChecklistTypeDisplayName(checklist.type)}.pdf`
    });

    setExportButtonState('done');
  }, [year, checklist, practiceInfo, taskMetaMap, certification, componentTypeName]);

  return (
    checklist && (
      <div style={{ color: theme.palette.color50555b.main }}>
        {match(checklist.type)
          .with('checklist', () => (
            <p>
              The items listed below are required actions to complete your {componentTypeName} program. Click on the colored dot to change the status
              of each item. Within each section, your practice will need to mark all items as "Complete" (
              <CompliancePlip status={ComplianceStatus.Complete} />
              ) or "Not Applicable" (<CompliancePlip status={ComplianceStatus.NotApplicable} />
              ). Upcoming completing all items, the status for the section will update to "Complete" (
              <CompliancePlip status={ComplianceStatus.Complete} />
              ).
            </p>
          ))
          .with('facility report', () => (
            <>
              <p>
                A HealthFirst Compliance Trainer will create this report and work through it with you in your training session. This report will be
                available at all times in OnTraq. You can also download or print and save in your preferred method when completed.
              </p>
              <p>As OSHA laws are subject to change, please visit our website often to make sure your status is still up to date.</p>
            </>
          ))
          .with('security risk assessment', () => (
            <p>
              A HealthFirst Compliance Trainer will create this report and work through it with you in your training session. This report will be
              available at all times in OnTraq. You can also download or print and save in your preferred method when completed. As HIPAA laws are
              subject to change, please visit our website often to make sure your status is still up to date.
            </p>
          ))
          .exhaustive()}
        <div className={styles['button-row']}>
          <CertifyChecklistCompleteModal checklistId={checklist.id} />
          <Button variant="contained" onClick={() => downloadPDF()} disabled={exportButtonState == 'loading'}>
            {exportButtonState === 'loading' ? 'Exporting...' : 'Export'}
          </Button>
        </div>
        <div className={styles.tableContainer}>
          {checklist.sections?.map((s) => (
            <Section key={s.id} checklistType={checklist.type} {...s} />
          ))}
        </div>
      </div>
    )
  );
};

export default ProgramChecklist;
