import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null
};

export const deUserSlice = createSlice({
  name: 'deUser',
  initialState: { value: initialState },
  reducers: {
    setDEUser: (state, action) => {
      state.value = action.payload;
    }
  }
});

export const { setDEUser } = deUserSlice.actions;

export default deUserSlice.reducer;
