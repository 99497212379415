import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/store';
import { selectProgramByComponentNameAndYear, selectProgramComponentForms, selectTrainingProgramForms } from '../slice';
import { Accordion, AccordionDetails, Box, Button, TableContainer, TextField, useTheme } from '@mui/material';
import { ProgramFormsFilters, TrainingProgramForm } from '../types';
import { selectTeamMembers } from '../../../../redux/customer';
import { getFileIdsBySection } from '../utils';
import CollapsibleTableWrapper from './components/CollapsibleTableWrapper';
import AccordionSummaryWrapper from './components/AccordionSummaryWrapper';
import NoDocumentsPurchased from './components/NoDocumentsPurchased';
import ProgramFormFilters from './components/ProgramFormFilters';
import { TeamMember } from '../../../../types';
import { indexBy, prop } from 'remeda';
import { getFullNameOrEmail } from '../../../../redux/user';
import { getFilterTrainingProgramForms, getSearchTrainingProgramForms, getSectionFormRelationMatchesFilter } from '../../../../helpers/osha-hipaa-forms/helpers'

interface AccordionState {
  [key: string]: number;
}

const ProgramForms = () => {
  const theme = useTheme();
  const { componentTypeName, year } = useParams();
  const [searchValue, setSearchValue] = useState<string>('');
  const [filters, setFilters] = useState<ProgramFormsFilters | null>(null);
  const programComponentForms = useAppSelector((state) => selectProgramComponentForms(state, year, componentTypeName));
  const uploadedForms = useAppSelector(selectTrainingProgramForms);
  const teamMembers = useAppSelector(selectTeamMembers);
  const program = useAppSelector((state => selectProgramByComponentNameAndYear(state, year, componentTypeName)));

  if (programComponentForms.length === 0) {
    return <NoDocumentsPurchased componentTypeName={componentTypeName} />
  }

  const teamMemberIndex: {[Identifier: string]: TeamMember} = useMemo(() => {
    return indexBy(teamMembers, prop('login_user_id'));
  }, [teamMembers]);

  const uploadedFormIndex = useMemo(() => {
    const index: { [Identifier: number]: TrainingProgramForm[] } = {}
    uploadedForms.forEach((form: TrainingProgramForm) => {
      index[form.form_id] = index[form.form_id] ? [...index[form.form_id], form] : [form];
    });
    return index;
  }, [uploadedForms]);

  const initialAccordionState = useMemo(() => {
    const state: AccordionState = {};
    programComponentForms.forEach((section) => {
      state[section.section_id] = 0;
    });
    return state;
  }, [programComponentForms]);

  const programComponentFormsUploads = useMemo(() => {
    return programComponentForms.map(programComponentForm => {
      return {
        ...programComponentForm,
        section_form_relations: programComponentForm.section_form_relations.map(formRelation => {
          return {
            ...formRelation,
            training_program_forms: uploadedFormIndex[formRelation.form_id] ?? []
          }
        })
      }
    });
  }, [programComponentForms, uploadedFormIndex]);

  const [accordionOpen, setAccordionOpen] = useState(initialAccordionState);

  const setAllAccordions = (value: number) => {
    setAccordionOpen((prevState) => {
      const newState: AccordionState = {};
      Object.keys(prevState).forEach((key) => {
        newState[key] = value;
      });
      return newState;
    });
  };

  const isAllAccordionsOpen = () => {
    const hasClosedAccordion = Object.values(accordionOpen).includes(0);
    if (hasClosedAccordion) return false;
    else return true;
  }

  const filterValuesSet = useMemo(() => {
    const filtersSet = filters &&
      Object.values(filters)
      .find(filter => filter.value !== 'None') ? true : false;
    if(!isAllAccordionsOpen() && filtersSet || searchValue !== '') setAllAccordions(1);
    return filtersSet;
  }, [filters, searchValue])

  const searchTrainingProgramForms = getSearchTrainingProgramForms(searchValue)
  const filterTrainingProgramForms = getFilterTrainingProgramForms(filters, filterValuesSet)
  const sectionFormRelationMatchesFilters = getSectionFormRelationMatchesFilter(searchValue, filters)

  const filteredForms = useMemo(() => {
    if((!filters || !filterValuesSet) && searchValue === '') return programComponentFormsUploads;
    return programComponentFormsUploads.map(programComponentForm => {
      return {
        ...programComponentForm,
        section_form_relations: programComponentForm.section_form_relations
          .map(s => {
            return {
              ...s,
              training_program_forms: s.training_program_forms
                .filter(trainingProgramForm => {
                  const uploadedBy = trainingProgramForm.uploaded_by ? getFullNameOrEmail(teamMemberIndex[trainingProgramForm.uploaded_by]) : '';
                  const teamMember = trainingProgramForm.user_id ? getFullNameOrEmail(teamMemberIndex[trainingProgramForm.user_id]) : '';
                  const filtersMatched = filterTrainingProgramForms(s, uploadedBy, teamMember, trainingProgramForm);
                  const searchMatched = searchTrainingProgramForms(s, uploadedBy, teamMember, trainingProgramForm);
                  return filtersMatched && searchMatched;
                })
            }
          }).filter(s => s.training_program_forms.length || sectionFormRelationMatchesFilters(s))
      }
    }).filter(p => p.section_form_relations.length);
  }, [filters, searchValue, programComponentFormsUploads]);

  const toggleAccordion = (formId: number) => {
    setAccordionOpen((prevState) => ({
      ...prevState,
      [formId]: prevState[formId] === 0 ? 1 : 0
    }));
  };

  const searchForms = (value: string) => {
    setSearchValue(value);
    if(!isAllAccordionsOpen()) setAllAccordions(1);
  };

  const fileIdsBySection = getFileIdsBySection(programComponentForms, uploadedForms)

  return (
    <Box sx={{
      color: theme.palette.color50555b.main,
      border: '1px solid #ccc',
      padding: '30px',
      marginTop: '30px'
    }}>
      <div className="flex flex-space-between flex-align-left">
        <h3>{componentTypeName} Forms</h3>
        <div className="flex flex-align-left">
          <ProgramFormFilters
            searchValue={searchValue}
            searchForms={searchForms}
            filterForms={setFilters}
            programComponentForms={programComponentForms}
            uploadedFormIndex={uploadedFormIndex}
            teamMembers={teamMemberIndex}
          />
          <div className="m-l-8">
            <Button variant="text" size="small" onClick={() => setAllAccordions(1)}>
              Expand All
            </Button>
            |
            <Button size="small" onClick={() => setAllAccordions(0)}>
              Collapse All
            </Button>
          </div>
        </div>
      </div>
      <p className="left bold f-s-13">This section lets you upload and save all the different downloaded forms for {componentTypeName}.</p>
      {filteredForms
        .map((section) => (
          <Accordion key={section.section_id} disableGutters expanded={accordionOpen[section.section_id] === 1} onChange={() => toggleAccordion(section.section_id)}>
            <AccordionSummaryWrapper section={section} />
            <AccordionDetails>
              <TableContainer style={{ overflowX: 'hidden' }}>
                <CollapsibleTableWrapper
                    section={section}
                    formsIndex={uploadedFormIndex}
                    teamMemberIndex={teamMemberIndex}
                    teamMembers={teamMembers}
                    filters={filters}
                    filterValuesSet={filterValuesSet}
                    searchValue={searchValue}
                    fileIdsBySection={fileIdsBySection}
                    programId={program?.program_id}
                  />
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        ))}
      {programComponentForms.length === 0 && <div className="left bold">There are no matching forms.</div>}
    </Box>
  );
};

export default ProgramForms;
