import { Search as SearchIcon } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';

const GenericSearch = (props: {
  value: string,
  onChange: (value: string) => void,
  style: any
}) => {

  const { value, onChange, style } = props

  return (
    <TextField
      fullWidth
      label='Search'
      variant='outlined'
      size='small'
      InputProps={{
        style,
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        )
      }}
      InputLabelProps={{
        style: {
          fontSize: '13px'
        }
      }}
      onChange={(event) => onChange(event.target.value)}
      value={value}
    />
  )
}

export default GenericSearch