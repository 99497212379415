import { TableCell } from "@mui/material"
import { ProgramForm, TrainingProgramForm } from "../../types"
import UploadedForm from "./UploadedForm"
import { TeamMember } from "../../../../../types"

interface UploadedFormCellPropTypes {
  form: ProgramForm;
  isCollapsedCell: boolean;
  trainingProgramForm: TrainingProgramForm;
  tableCellStyles: any;
  colIndex: number;
  colWidths: number[];
  handleDeleteClick: (id: number) => void;
  isAdminOrOwner: boolean;
  searchValue: string;
  teamMemberIndex: {[Identifier: string]: TeamMember};
  sectionId: number;
}

const UploadedFormCell = (props: UploadedFormCellPropTypes) => {
  const {
    isCollapsedCell,
    tableCellStyles,
    colIndex,
    colWidths,
    trainingProgramForm,
    form,
    sectionId
  } = props

  const sx = isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads ) ? {...tableCellStyles, width: colWidths[colIndex]} : tableCellStyles

  return (
    <TableCell
      sx={sx}
      align="center"
    >
      {
        (isCollapsedCell || (form.is_practice_only && !form.is_multiple_uploads)) && trainingProgramForm?.file_id && trainingProgramForm?.section_id === sectionId ?
          <UploadedForm {...props}/>
        : ''
      }
    </TableCell>
  )
}

export default UploadedFormCell