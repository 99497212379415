import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { selectCustomerNumber, selectHasDE } from '../../../../redux/customer';
import { getDELink, getUpcomingAppointments } from '../services';
import { longDateFormat } from '../../../../helpers/date';
import { useAppSelector } from '../../../../redux/store';
import { selectUserTimezone } from '../../../../redux/user';

export default () => {
  const hasDE = useAppSelector(selectHasDE);

  // get customer number from URL if present (corporate view)
  // otherwise use customer number from state (this is the parent customer in corporate view)
  const customerNumber = useParams().customerNumber ?? useAppSelector(selectCustomerNumber);

  const [deLink, setDELink] = useState('');
  const [nextAppointment, setNextAppointment] = useState<string>('');

  const timezone = useAppSelector(selectUserTimezone);

  useEffect(() => {
    if (!(customerNumber && hasDE) || !timezone) {
      return;
    }

    getDELink(customerNumber).then(setDELink, (error) => console.error('unable to get user DE link:', error));

    getUpcomingAppointments(customerNumber).then(
      (res) => {
        // res can come back null, thus not deconstructing and using optional chaining
        if (res?.started_at) {
          const nextAppointment = longDateFormat(timezone, res.started_at);
          setNextAppointment(nextAppointment);
        } else {
          setNextAppointment('');
        }
      },
      (error) => console.error('unable to get next DE appointment:', error)
    );
  }, [hasDE, customerNumber, timezone]);

  return [deLink, nextAppointment];
};
