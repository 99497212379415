import * as R from 'remeda';

type Validator = (value?: string) => string | undefined;

type LabledValidator = (label: string) => Validator;

export const composeValidators =
  (label: string, ...validators: LabledValidator[]) =>
  (value?: string) =>
    validators.reduce((error, validator) => error ?? validator(label)(value), undefined as string | undefined);

export const maxLength =
  (maxLength: number) =>
  (label: string) =>
  (value?: string): string | undefined =>
    typeof value == 'string' && value.length > maxLength ? `${label} can only be ${maxLength} characters` : undefined;

export const required =
  (label: string) =>
  (value?: string): string | undefined =>
    R.isEmpty(value) ? `${label} is required` : undefined;
