import { RouteObject } from 'react-router-dom';
import ChecklistPDFPage from '../views/practice/dentalenhancements/ChecklistPDFPage';

export default [
  {
    path: 'pdf',
    children: [
      {
        path: ':customerNumber/:year/:componentTypeName/:checklistTypeSlug',
        element: <ChecklistPDFPage />
      }
    ]
  }
] satisfies RouteObject[];
