import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PageHeader.css';

export default function Footer({ iconName, headerName }) {
  return (
    <div className="page-header-container">
      <FontAwesomeIcon className='page-header-icon' icon={iconName} /> <span className="page-header-name">{headerName}</span>
    </div>
  );
}
